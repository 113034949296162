const { apiService } = require("../apiService");

const BASE_ROUTE = "/events/schedule";

const eventsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getNumberOfEvents: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}/count`,
        method: "POST",
        body: args,
      })
    }),
    addEventSchedule: builder.mutation({
      query: (args) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      })
    }),
  }),
});

export const { useGetNumberOfEventsMutation, useAddEventScheduleMutation } = eventsEndpoint;
