import Button from "@mui/material/Button";
import { Card, Dimmer, Form, Grid, Icon } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import _ from "lodash";
import { useEffect, useState } from "react";
import { addOrganization, updateOrganization } from "services/admin";
import { useGetCustomersQuery } from "services/rtkApi/endpoints/customers";
import { CustomerDto, OrganizationDto } from "types";
import { validationService } from "../../services/validation";

interface Props {
  selectedOrganization: OrganizationDto;
  handleModalClose: () => void;
}

interface FormState extends OrganizationDto {
  nameError: string;
  customerIdError: string;
}
const initialForm: FormState = {
  name: "",
  nameError: "",
  customerIdError: "",
  hasPhotoAnalysis: false,
  hasPermitFeature: false,
  hasTimeSeries: false,
  hasEvents: false,
  hasMonitoring: false,
  hasIncidents: false,
  hasInspections: false,
  hasEnergyLog: false,
  customerId: 0,
  hasDateOnlyEventDate: false,
};

export function OrganizationModal(props: Props) {
  const [formState, setFormState] = useState<FormState>(initialForm);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDto>({});
  const { showAlert } = useAlert();
  useEffect(() => {
    if (!_.isEmpty(props.selectedOrganization)) {
      setFormStateFromProps(props.selectedOrganization);
    }
  }, [props.selectedOrganization]);
  const { data: customers, isFetching: isLoading } = useGetCustomersQuery({
    pageSize: 10000,
  });
  const setFormStateFromProps = (data: OrganizationDto) => {
    let newState = {
      name: data.name,
      hasPhotoAnalysis: data.hasPhotoAnalysis,
      hasPermitFeature: data.hasPermitFeature,
      hasTimeSeries: data.hasTimeSeries,
      hasEvents: data.hasEvents,
      hasMonitoring: data.hasMonitoring,
      hasIncidents: data.hasIncidents,
      hasInspections: data.hasInspections,
      hasEnergyLog: data.hasEnergyLog,
      nameError: "",
      customerId: data.customerId,
      customerIdError: "",
      hasDateOnlyEventDate: data.hasDateOnlyEventDate,
    };
    setFormState(newState);
  };

  useEffect(() => {
    if (!isLoading && formState.customerId) {
      var customer = customers.find(
        (c: CustomerDto) => c.id == formState.customerId
      );
      setSelectedCustomer(customer);
    }
  }, [formState.customerId, isLoading]);
  const handleInputChange = (e: any) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    validationService.validateRequiredField(
      newState,
      "customerId",
      "customerIdError",
      "Customer"
    );

    isFormValid = !validationService.hasError(newState, "nameError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleSaveOrg = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(formState, "nameError");

    if (_.isEmpty(props.selectedOrganization)) {
      addOrganization(formToSave)
        .then((res) => {
          showAlert("success", "Organization added successfully.");
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    } else {
      updateOrganization(props.selectedOrganization.id, formToSave)
        .then((res) => {
          showAlert("success", "Organization updated successfully.");
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };
  return (
    <Form className="card dsmodal-main w-50">
      <Card.Body>
        <Card.Title>
          {_.isEmpty(props.selectedOrganization)
            ? "Add Organization"
            : "Edit Organization"}

          <Icon
            name="x"
            onClick={() => {
              props.handleModalClose();
            }}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Dimmer active={isLoading} loader>
          <Grid.Row>
            {!_.isEmpty(customers) && (
              <Grid.Col md={12} width={12}>
                <Form.Group isRequired label="Customer">
                  <Form.Select
                    name="customerId"
                    value={formState.customerId}
                    onChange={handleInputChange}
                    error={formState.customerIdError}
                    disabled={!_.isEmpty(props.selectedOrganization)}
                  >
                    <option value={""}></option>
                    {customers.map((type: CustomerDto) => (
                      <option value={type.id} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            )}
            <Grid.Col md={12} width={12}>
              <Form.Group label="Name" isRequired>
                <Form.Input
                  name="name"
                  error={formState.nameError}
                  value={formState.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            {selectedCustomer.eventsLicenseCount &&
              selectedCustomer.eventsLicenseCount > 0 && (
                <>
                  <Grid.Col md={12} width={12}>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Enable events?"
                        name="hasEvents"
                        checked={formState.hasEvents}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {formState.hasEvents && (
                    <div className="ml-5">
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="">
                          <Form.Checkbox
                            label="Enable permits?"
                            name="hasPermitFeature"
                            checked={formState.hasPermitFeature}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="">
                          <Form.Checkbox
                            label="Use Date Only for Event dates?"
                            name="hasDateOnlyEventDate"
                            checked={formState.hasDateOnlyEventDate}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </div>
                  )}
                </>
              )}
            {selectedCustomer.monitoringLicenseCount &&
              selectedCustomer.monitoringLicenseCount > 0 && (
                <>
                  <Grid.Col md={12} width={12}>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Enable monitoring?"
                        name="hasMonitoring"
                        checked={formState.hasMonitoring}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  {formState.hasMonitoring && (
                    <>
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="" className="ml-5">
                          <Form.Checkbox
                            label="Enable time series?"
                            name="hasTimeSeries"
                            checked={formState.hasTimeSeries}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="" className="ml-5">
                          <Form.Checkbox
                            label="Enable photo analysis?"
                            name="hasPhotoAnalysis"
                            checked={formState.hasPhotoAnalysis}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </>
                  )}
                </>
              )}

            {selectedCustomer.incidentsLicenseCount &&
              selectedCustomer.incidentsLicenseCount > 0 && (
                <Grid.Col md={12} width={12}>
                  <Form.Group label="">
                    <Form.Checkbox
                      label="Enable incidents?"
                      name="hasIncidents"
                      checked={formState.hasIncidents}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Grid.Col>
              )}

            {selectedCustomer.inspectionsLicenseCount &&
              selectedCustomer.inspectionsLicenseCount > 0 && (
                <Grid.Col md={12} width={12}>
                  <Form.Group label="">
                    <Form.Checkbox
                      label="Enable inspections?"
                      name="hasInspections"
                      checked={formState.hasInspections}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Grid.Col>
              )}

            {selectedCustomer.energyLogsLicenseCount &&
              selectedCustomer.energyLogsLicenseCount > 0 && (
                <Grid.Col md={12} width={12}>
                  <Form.Group label="">
                    <Form.Checkbox
                      label="Enable energy log?"
                      name="hasEnergyLog"
                      checked={formState.hasEnergyLog}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Grid.Col>
              )}
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {_.isEmpty(props.selectedOrganization) && (
          <Button
            color="primary"
            className="float-right"
            onClick={handleSaveOrg}
          >
            Add Organization
          </Button>
        )}
        {!_.isEmpty(props.selectedOrganization) && (
          <div>
            <Button
              color="primary"
              className="float-right"
              onClick={handleSaveOrg}
            >
              Save Organization
            </Button>
          </div>
        )}
      </Card.Footer>
    </Form>
  );
}
