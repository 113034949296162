import React, { FC } from "react";
import { Box } from "@mui/material";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { MultiSelect } from "components/form-controls/multi-select";
import { useAppSelector } from "modules/hooks";
import { Form } from "components/lynx-components";
import { LookupDto } from "types";

interface EventSchedulerDetailsProps {
  values: {
    assets: string[];
    eventCategoryId: string;
    eventSubCategoryId: string;
    entityUsers: string[];
    description: string;
  };
  errors: { [key: string]: any };
  touched: { [key: string]: boolean };
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  isSubCatRequired: boolean;
  assetsLookup: LookupDto[];
  eventCategoryLookup: LookupDto[];
  eventSubCategoryLookup: LookupDto[];
}

const EventSchedulerDetails: FC<EventSchedulerDetailsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubCatRequired,
  assetsLookup,
  eventCategoryLookup,
  eventSubCategoryLookup,
}) => {
  const { users } = useAppSelector((state) => state.lookups);

  return (
    <Box minHeight={320}>
      <Form.Group isRequired label="Associated Assets">
        <MultiSelect
          name="assets"
          id="assets"
          onChange={handleChange}
          value={values.assets}
          error={!!errors.assets && touched.assets && errors.assets}
          dropdownValues={assetsLookup}
          label="name"
          key="id"
          onBlur={handleBlur}
        />
      </Form.Group>
      <Form.Group isRequired label="Category">
        <Form.Select
          id="eventCategoryId"
          name="eventCategoryId"
          onChange={handleChange}
          value={values.eventCategoryId}
          error={
            !!errors.eventCategoryId &&
            touched.eventCategoryId &&
            errors.eventCategoryId
          }
          onBlur={handleBlur}
        >
          <option value=""></option>
          {eventCategoryLookup?.map((type) => (
            <option value={type.id} key={type.id}>
              {type.code}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group label="Subcategory" isRequired={isSubCatRequired}>
        <Form.Select
          id="eventSubCategoryId"
          name="eventSubCategoryId"
          onChange={handleChange}
          value={values.eventSubCategoryId}
          error={
            !!errors.eventSubCategoryId &&
            touched.eventSubCategoryId &&
            errors.eventSubCategoryId
          }
          disabled={!Boolean(values.eventCategoryId)}
          onBlur={handleBlur}
        >
          <option value=""></option>
          {eventSubCategoryLookup
            ?.filter(
              (f) => f.parentLookupId?.toString() === values.eventCategoryId
            )
            ?.map((type) => (
              <option value={type.id} key={type.id}>
                {type.code}
              </option>
            ))}
        </Form.Select>
      </Form.Group>
      <Form.Group label="Assigned To">
        <MultiSelect
          name="entityUsers"
          id="entityUsers"
          onChange={handleChange}
          value={values.entityUsers}
          dropdownValues={users}
          key="id"
          label="fullName"
          error={
            !!errors.entityUsers && touched.entityUsers && errors.entityUsers
          }
          onBlur={handleBlur}
        />
      </Form.Group>
      <Form.Group label="Description">
        <LynxTextArea
          name="description"
          id="description"
          onChange={handleChange}
          value={values.description}
          error={
            !!errors.description && touched.description && errors.description
          }
          onBlur={handleBlur}
        />
      </Form.Group>
    </Box>
  );
};

export default EventSchedulerDetails;
