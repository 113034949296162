import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import lynxColors from "../../../modules/lynxColors";
import { EntityTypes } from "../../../types/enums";
import EventMobileCard from "../events/event-mobile-card";
import { MonitoringEventMobileCard } from "../monitoring/monitoring-event-mobile-card";
import { MonitoringLocationMobileCard } from "./../monitoring/monitoring-location-mobile-card";
import { GenericSidebarCard } from "./generic-sidebar-card";
export function MapSidebar(props) {
  const pageSize = 20;

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPageNumber(1);
  }, [props.data]);

  let dataToDisplay = props.data.slice(
    (pageNumber - 1) * pageSize,
    (pageNumber - 1) * pageSize + pageSize
  );
  return (
    <>
      {props.type != "" && (
        <div className={`map-sidebar-right`}>
          <div className="map-sidebar-header">
            <Typography
              variant={
                props.type == EntityTypes.Event ||
                props.type == EntityTypes.MonitoringEvent
                  ? "h6"
                  : "subtitle2"
              }
            >
              {props.type == EntityTypes.Event
                ? "Events"
                : props.type == EntityTypes.MonitoringEvent
                ? "Monitoring Events"
                : props.type == EntityTypes.MonitoringLocation
                ? "Monitoring Locations"
                : props.selectedLayer
                ? props.selectedLayer.name
                : ""}
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={props.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="d-flex justify-content-between ">
            <Pagination
              size="small"
              page={pageNumber}
              count={Math.ceil(props.data.length / pageSize)}
              onChange={(e, value) => {
                setPageNumber(value);
              }}
            />
          </div>

          {props.type == EntityTypes.Event &&
            _.orderBy(dataToDisplay, ["eventNumber"], ["desc"]).map((event) => (
              <EventMobileCard entity={event} />
            ))}

          {props.type == EntityTypes.MonitoringEvent &&
            _.orderBy(dataToDisplay, ["monitoringEventNumber"], ["desc"]).map(
              (event) => <MonitoringEventMobileCard entity={event} />
            )}
          {props.type == EntityTypes.MonitoringLocation &&
            _.sortBy(dataToDisplay, ["name"]).map((location) => (
              <MonitoringLocationMobileCard entity={location} isForMap />
            ))}
          {props.type == "generic" && (
            <GenericSidebarCard
              features={dataToDisplay}
              layer={props.selectedLayer}
            />
          )}
        </div>
      )}
    </>
  );
}

export const getMonitoringDialogTitle = (event) => {
  return (
    <Link
      to={`/assets/${event.assetId}/monitoring-locations/${event.monitoringLocationId}/events/${event.id}`}
    >
      {event.monitoringEventNumber}
    </Link>
  );
};

export const getUrgencyColor = (urgency) => {
  switch (urgency) {
    case "Low":
      return lynxColors.success;
    case "Medium":
      return lynxColors.harvestOrange;
    case "High":
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};

export const getStatusColor = (status) => {
  switch (_.toLower(status)) {
    case "complete":
      return lynxColors.primary;
    case "incomplete":
      return lynxColors.success;
    case "incomplete (overdue)":
      return lynxColors.error;
    default:
      return lynxColors.harvestOrange;
  }
};

export const getComplianceColor = (isNc) => {
  switch (isNc) {
    case true:
      return lynxColors.error;
    case false:
      return lynxColors.success;

    default:
      return lynxColors.primary;
  }
};
