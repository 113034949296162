import * as React from "react";
import cn from "classnames";
import { Icon } from "../";

interface Props extends React.HTMLProps<HTMLElement> {
  className?: string;
  label?: string;
  value?: string | number;
  name: string;
  checked?: boolean;
  icon?: string;
  type?: "radio" | "checkbox";
}

const FormSelectGroupItem: React.FC<Props> = ({
  className,
  label,
  name,
  value,
  checked,
  icon,
  type = "radio",
  onChange,
  onFocus,
  onBlur,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
}) => {
  const classes = cn({ "selectgroup-item": true }, className);
  const btnClasses = cn("selectgroup-button", {
    "selectgroup-button-icon": icon,
  });
  const outputLabel = icon ? <Icon name={icon} /> : label;

  return (
    <label
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <input
        type={type}
        name={name}
        value={value}
        className="selectgroup-input"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <span className={btnClasses}>{outputLabel}</span>
    </label>
  );
};

FormSelectGroupItem.displayName = "Form.SelectGroupItem";

export default FormSelectGroupItem;
