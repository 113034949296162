import SyncIcon from "@mui/icons-material/Sync";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { localStorageService } from "../../../services/local-storage-service";
import {
  getMonitoringResultExportAsXLSX,
  getMonitoringResults,
} from "../../../services/monitoring-result-service";
import {
  getMonitoringLocationExportAsXLSX,
  getMonitoringLocations,
} from "../../../services/monitoring-location-service";
import { getMonitoringEventsExportAsXLSX } from "./../../../services/monitoring-events";
import {
  monitoringEventsStore,
  offlineDataService,
} from "../../../services/offlineDataService";
import { monitoringGridTourSteps } from "../../../tours/monitoring-grid-tour";
import {
  EntityTypes,
  MonitoringMenuKeys,
  TourTypes,
  UserRoles,
} from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
import {
  bulkUpdateMonitoringEventAttachments,
  bulkUpdateMonitoringEvents,
  getMonitoringEvents,
  getMonitoringEventsAttachments,
} from "./../../../services/monitoring-events";
import { LynxTour } from "./../../lynx-tour";
import { monEventColumns, monLocationColumns } from "./mon-event-columns";
import { MonitoringEventMobileCard } from "./monitoring-event-mobile-card";
import { MonitoringLocationMobileCard } from "./monitoring-location-mobile-card";
import { MonitoringResultMobileCard } from "./monitoring-result-mobile-card";
import { monitoringResultColumns } from "./monitroing-result-columns";
import { roleMatch } from "../../../actions/auth";
import { TimeseriesList } from "../time-series/timeseries-list";
import { useSelector } from "react-redux";
import { LynxDialog } from "../../lynx-dialog";
import { PhotoImportHistoryModal } from "../time-series/photo-import-history-modal";
import { MonitoringResultListWide } from "./monitoring-result-list-wide";
import { getMonitoringEventLookups } from "../../../services/lookup";
import {
  getCustomFields,
  transformCustomFieldDefsToColumnDefs,
} from "services/custom-fields-service";
export function MonitoringList(props) {
  const isOffline = !useOnlineStatus();
  var organization = useSelector((state) => state.organization);
  const navigateToAddEvent = (isScheduledEvent) => {
    if (isScheduledEvent) {
      props.history.push(`/add-monitoring-event?isScheduled=true`);
    } else {
      props.history.push(`/add-monitoring-event`);
    }
  };
  const handleEventNumberClick = (row) => {
    props.history.push(`/monitoring/${row.monitoringEventId ?? row.id}`);
  };
  const navigateToAddMonitoringLocation = () => {
    props.history.push(`/add-monitoring-location`);
  };
  const handleLocationNumberClick = (row) => {
    props.history.push(`/monitoring-locations/${row.id}`);
  };
  const lsKey = "monitoring_grid_view_main";
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const [eventColumns, setEventColumns] = useState([]);
  const [resultColumns, setResultColumns] = useState([]);
  const [locationColumns, setLocationColumns] = useState([]);
  const [gridView, setGridView] = useState("");
  const [showImportHistory, setShowImportHistory] = useState(false);
  const dimensions = useWindowDimensions();
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };
  const [eventCustomFields, setEventCustomFields] = useState(null);
  useEffect(() => {
    if (isOffline) {
      let isReady = true;

      offlineDataService
        .checkIfOfflineIsReady("monitoringLookups")
        .then((res) => {
          if (!res) {
            isReady = false;
          }
          setIsOfflineReady(isReady);
        });
    }
  }, [isOffline]);

  useEffect(() => {
    getCustomFields(EntityTypes.MonitoringEvent)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(
          eventCustomFields.filter((x) => !x.parentLookupId && !x.childLookupId)
        );
      })
      .catch((err) => {
        setEventCustomFields([]);
      });
  }, []);

  useEffect(() => {
    if (eventCustomFields != null) {
      let newColumns = [...monEventColumns(predefinedLookups)];
      let colIndex = newColumns.findIndex(
        (x) => x.field == "monitoringEventNumber"
      );

      newColumns = [
        ...newColumns,
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      newColumns[colIndex].renderCell = (params) => {
        return (
          <>
            {params.row.notSynced ? (
              <SyncIcon color="error" className="ml-2" />
            ) : (
              <Button
                variant="text"
                onClick={() => handleEventNumberClick(params.row)}
                disabled={isOffline}
                className="monitoring-event-number-button"
              >
                {params.value}
              </Button>
            )}
          </>
        );
      };
      setEventColumns(newColumns);
    }
  }, [monEventColumns, isOffline, eventCustomFields]);

  useEffect(() => {
    let newColumns = [...monLocationColumns];
    let colIndex = monLocationColumns.findIndex(
      (x) => x.field == "monitoringLocationNumber"
    );

    newColumns[colIndex].renderCell = (params) => {
      return (
        <>
          <Button
            variant="text"
            onClick={() => handleLocationNumberClick(params.row)}
            disabled={isOffline}
            className="monitoring-event-number-button"
          >
            {params.value}
          </Button>
        </>
      );
    };

    let colIndex2 = monLocationColumns.findIndex(
      (x) => x.field == "Edit Monitoring Parameters and Limits"
    );
    newColumns[colIndex2].renderCell = (params) => {
      return (
        <Button
          onClick={() => handleEditMonitoringLimits(params)}
          variant="outlined"
          disabled={isOffline}
        >
          Edit Monitoring Parameters and Limits
        </Button>
      );
    };

    let colIndex3 = monLocationColumns.findIndex(
      (x) => x.field == "Edit Variance Allowances"
    );
    newColumns[colIndex3].renderCell = (params) => {
      return (
        <Button
          onClick={() => handleEditMonitoringVariance(params)}
          variant="outlined"
          disabled={isOffline}
        >
          Edit Variance Allowances
        </Button>
      );
    };
    setLocationColumns(newColumns);
  }, [monLocationColumns, isOffline]);

  useEffect(() => {
    let newColumns = [...monitoringResultColumns];
    let colIndex = monitoringResultColumns.findIndex(
      (x) => x.field == "monitoringEventNumber"
    );
    newColumns[colIndex].renderCell = (params) => {
      return (
        <>
          {params.row.notSynced ? (
            <SyncIcon color="error" className="ml-2" />
          ) : (
            <Button
              variant="text"
              onClick={() => handleEventNumberClick(params.row)}
              disabled={isOffline}
            >
              {params.value}
            </Button>
          )}
        </>
      );
    };
    setResultColumns(newColumns);
  }, [monitoringResultColumns, isOffline]);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if (!values.view) {
      var selectedGridView = localStorageService.getLocalStorage(lsKey);
      if (selectedGridView) {
        setGridView(selectedGridView);
        props.history.push(`/monitoring?view=${_.toLower(selectedGridView)}`);
      } else {
        setGridView("Events");
        props.history.push("/monitoring?view=events");
      }
    } else {
      setGridView(values.view ? _.capitalize(values.view) : "Events");
    }
  }, [props.location]);

  const handleEditMonitoringLimits = (params) => {
    props.history.push(`/monitoring-locations/${params.row.id}/limits`);
  };
  const handleEditMonitoringVariance = (params) => {
    props.history.push(`/monitoring-locations/${params.row.id}/variance`);
  };
  const userRoleCanSave = roleMatch([
    UserRoles.MonitoringAdministrator,
    UserRoles.MonitoringEditor,
  ]);
  const handleGridViewChange = (e) => {
    setGridView(e.target.value);
    localStorageService.setLocalStorage(lsKey, e.target.value);
    props.history.push(`/monitoring?view=${_.toLower(e.target.value)}`);
  };
  const handleUploadPhotos = () => {
    props.history.push(`/monitoring-location-upload-photos`);
  };
  const getPropsForEventsGrid = () => {
    return {
      getDataFunction: getMonitoringEvents,
      localStorageName: "monitoringEvents",
      entityName: EntityTypes.MonitoringEvent,
      addButtonAction: navigateToAddEvent,
      hideAddButton: !userRoleCanSave,
      indexDbObjectStoreName: monitoringEventsStore,
      indexDbSortColumn: "monitoringEventNumber",
      title: "Monitoring",
      endpoint: "/monitoring?view=events",
      enableSavedFilters: true,
      addButtonText: "Add Monitoring Event",
      columns: eventColumns,
      bulkUpdateFunction: bulkUpdateMonitoringEvents,
      bulkUpdateAttachmentsFunction: bulkUpdateMonitoringEventAttachments,
      getLookupsFunction: getMonitoringEventLookups,
    };
  };
  const handleViewSelectedImportHistory = (selectedPhotoImportHistoryId) => {
    setShowImportHistory(false);
    //TODO filter grid based on PhotoImportHistoryId
  };
  const getPropsForLocationsGrid = () => {
    return {
      hideAddButton: !userRoleCanSave,
      getDataFunction: getMonitoringLocations,
      localStorageName: "monitoringLocations",
      entityName: EntityTypes.MonitoringLocation,
      addButtonAction: navigateToAddMonitoringLocation,
      title: "Monitoring",
      addButtonText: dimensions.isMobile ? "Add" : "Add Monitoring Location",
      columns: locationColumns,
      mobileCard: MonitoringLocationMobileCard,
    };
  };

  const getPropsForResultsGrid = () => {
    return {
      getDataFunction: getMonitoringResults,
      localStorageName: "monitoringResults",
      addButtonAction: navigateToAddEvent,
      entityName: EntityTypes.MonitoringResult,
      title: "Monitoring",
      columns: resultColumns,
      enableSavedFilters: true,
      mobileCard: MonitoringResultMobileCard,
      hideAddButton: !userRoleCanSave,
      endpoint: "monitoring?view=results",
    };
  };

  const getMonitoringListHeader = () => {
    return (
      <>
        {!dimensions.isMobile && (
          <Typography
            variant="h3"
            component="div"
            className="align-self-center mr-5"
          >
            Monitoring
          </Typography>
        )}

        <span className=" align-self-end grid-view-select">
          <FormControl
            variant="standard"
            sx={
              dimensions.isMobile
                ? {
                    minWidth: 250,
                  }
                : {
                    marginBottom: 1,
                    marginTop: 1,
                    minWidth: 250,
                  }
            }
          >
            <InputLabel id="demo-simple-select-label">
              Select Grid View
            </InputLabel>
            <Select
              value={gridView}
              onChange={handleGridViewChange}
              className="font-weight-bold"
            >
              <MenuItem
                value={MonitoringMenuKeys.Events}
                key={MonitoringMenuKeys.Events}
              >
                {dimensions.isMobile ? "Monitoring Events" : "Events"}
              </MenuItem>
              {!isOffline && (
                <MenuItem
                  value={MonitoringMenuKeys.Locations}
                  key={MonitoringMenuKeys.Locations}
                >
                  {dimensions.isMobile ? "Monitoring Locations" : "Locations"}
                </MenuItem>
              )}
              {!isOffline && (
                <MenuItem
                  value={MonitoringMenuKeys.Results}
                  key={MonitoringMenuKeys.Results}
                >
                  {dimensions.isMobile ? "Monitoring Results" : "Results"}
                </MenuItem>
              )}
              {!isOffline && (
                <MenuItem
                  value={MonitoringMenuKeys.ResultsWide}
                  key={MonitoringMenuKeys.ResultsWide}
                >
                  {dimensions.isMobile
                    ? "Monitoring Results (Wide)"
                    : "Results (Wide)"}
                </MenuItem>
              )}
              {!isOffline && roleMatch([UserRoles.TimeSeriesUser]) && (
                <MenuItem
                  value={MonitoringMenuKeys.Timeseries}
                  key={MonitoringMenuKeys.Timeseries}
                >
                  {dimensions.isMobile ? "Timeseries" : "Timeseries"}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </span>
        {organization.hasPhotoAnalysis &&
          gridView == MonitoringMenuKeys.Timeseries &&
          roleMatch([
            UserRoles.MonitoringAdministrator,
            UserRoles.MonitoringEditor,
          ]) && (
            <div className={`"d-flex h-100 ml-auto header-button`}>
              <Button
                size={dimensions.isMobile ? "small" : "medium"}
                variant="contained"
                onClick={() => setShowImportHistory(true)}
                className={`ml-1 mr-1 ml-auto align-self-center grid-add-button ${
                  dimensions.isMobile ? "mt-1" : "mt-3"
                }`}
              >
                View Photo Import History
              </Button>
              <Button
                size={dimensions.isMobile ? "small" : "medium"}
                variant="contained"
                onClick={handleUploadPhotos}
                className={`ml-1 mr-1 ml-auto align-self-center grid-add-button ${
                  dimensions.isMobile ? "mt-1" : "mt-3"
                }`}
              >
                Upload Photos
              </Button>
            </div>
          )}
      </>
    );
  };

  const getTour = () => {
    return (
      <LynxTour
        title={"Welcome to the Monitoring Grid!"}
        description={
          "Monitoring captures air, water and noise from measurements in the field. No more messy field notebooks! Add all your monitoring data here and filter, export and monitor compliance with ease."
        }
        steps={monitoringGridTourSteps}
        flagField={"monitoringGridTourCompletedDateTimeUtc"}
        tourType={TourTypes.MonitoringGrid}
      />
    );
  };

  return (
    <>
      {gridView == MonitoringMenuKeys.Events && (
        <LynxDataGrid
          tour={getTour}
          isOfflineReady={isOfflineReady}
          header={getMonitoringListHeader}
          mobileCard={MonitoringEventMobileCard}
          handleExportAttachments={getMonitoringEventsAttachments}
          handleEventsExportXLSX={getMonitoringEventsExportAsXLSX}
          {...getPropsForEventsGrid()}
          {...props}
          searchEnabled
          searchLabel={"Search By: Event #, Sample ID, Type or Description"}
        />
      )}
      {gridView == MonitoringMenuKeys.Locations && (
        <>
          <LynxDataGrid
            header={getMonitoringListHeader}
            handleEventsExportXLSX={getMonitoringLocationExportAsXLSX}
            {...getPropsForLocationsGrid()}
            {...props}
          />
        </>
      )}
      {gridView == MonitoringMenuKeys.Results && (
        <LynxDataGrid
          header={getMonitoringListHeader}
          handleEventsExportXLSX={getMonitoringResultExportAsXLSX}
          {...getPropsForResultsGrid()}
          {...props}
        />
      )}
      {gridView == MonitoringMenuKeys.Timeseries && (
        <>
          <TimeseriesList header={getMonitoringListHeader} {...props} />
          <LynxDialog
            open={showImportHistory}
            fullWidth
            maxWidth="lg"
            handleClose={() => setShowImportHistory(false)}
            title={`Import History`}
            dialogContent={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <PhotoImportHistoryModal
                    handleViewSelectedImportHistory={
                      handleViewSelectedImportHistory
                    }
                  ></PhotoImportHistoryModal>
                </div>
              </>
            }
          />
        </>
      )}
      {_.toLower(gridView) == _.toLower(MonitoringMenuKeys.ResultsWide) && (
        <MonitoringResultListWide header={getMonitoringListHeader} {...props} />
      )}
    </>
  );
}
