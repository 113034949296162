const { apiService } = require("../apiService");

const BASE_ROUTE = "/documents";

const assetsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (args) => {
        return {
          url: `${BASE_ROUTE}`,
          params: {
            docParams: args,
          },
        };
      },
    }),
    getDocumentById: builder.query({
      query: (id) => {
        return {
          url: `${BASE_ROUTE}/${id}`,
        };
      },
    }),
    addDocuments: builder.mutation({
      query: (args) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
    }),
    updateDocument: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}`,
        method: "PUT",
        body: args,
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useAddDocumentsMutation,
  useUpdateDocumentMutation,
} = assetsEndpoint;
