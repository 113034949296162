import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const CUSTOMERS_ROUTE = "/customers";

const customerEndpoints = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (args) => `${CUSTOMERS_ROUTE}`,
      providesTags: [RtkTagTypes.GetCustomers],
    }),
    getCustomer: builder.query({
      query: (args) => `${CUSTOMERS_ROUTE}`,
    }),
    addCustomer: builder.mutation({
      query: (args) => ({
        url: CUSTOMERS_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetCustomers],
    }),

    updateCustomer: builder.mutation({
      query: (args) => ({
        url: `${CUSTOMERS_ROUTE}`,
        method: "PATCH",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetCustomers],
    }),

    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `${CUSTOMERS_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetCustomers],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customerEndpoints;
