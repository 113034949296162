import React, { useEffect, useState } from "react";
import { Form, Grid } from "components/lynx-components";

import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../services";
import { useSelector } from "react-redux";
import {
  createGroupChartWidget,
  getGroupByData,
} from "../../../services/dashboard-widget-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import "./add-widget-modal.css";
import { BarChartWidget } from "./widgets/bar-chart-widget";
import { DonutChartWidget } from "./widgets/donut-chart-widget";
import { monEventColumns } from "../monitoring/mon-event-columns";
import { monitoringResultColumns } from "../monitoring/monitroing-result-columns";
import { getActionColumns } from "../actions/action-columns";
import { incidentColumns } from "../incidents/incident-columns";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole,
  roleMatch,
} from "../../../actions/auth";
import { EntityTypes, UserRoles } from "./../../../types/enums";
import { inspectionColumns } from "../inspections/inspection-columns";
import { energyLogColumns } from "../energy/energy-log-columns";

import { getEventColumns } from "../events/event-columns";
import useAlert from "hooks/useAlert";
const initialForm = {
  title: "",
  titleError: "",
  filterId: "",
  groupBy: "",
  dataset: "",
  datasetError: "",
};
export function CreateGroupChart(props) {
  var organization = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialForm);
  const [eventCustomFields, setEventCustomFields] = useState(null);
  const [previewData, setPreviewData] = useState([]);

  const [filters, setFilters] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };

  const { showAlert } = useAlert();

  useEffect(() => {
    getCustomFields(EntityTypes.Event).then((res) => {
      let eventCustomFields = res.data;
      setEventCustomFields(eventCustomFields);
    });
  }, []);

  useEffect(() => {
    if (formState.filterId && formState.groupBy && formState.dataset) {
      getGroupByData(
        formState.dataset,
        formState.filterId,
        formState.groupBy
      ).then((res) => {
        setPreviewData(res.data);
      });
    }
  }, [formState.filterId, formState.groupBy, formState.dataset]);

  useEffect(() => {
    if (!_.isEmpty(props.filters) && formState.dataset != "") {
      const filtersToSet = props.filters.filter(
        (x) => _.toLower(x.entityType) === _.toLower(formState.dataset)
      );
      setFilters(filtersToSet);
    }
  }, [props.filters, formState.dataset]);

  useEffect(() => {
    if (formState.dataset != "" && !organization.isLoading) {
      const predefinedLookups = {
        users: users,
      };

      let newColumns =
        _.toLower(formState.dataset) == "event"
          ? getEventColumns(
              organization.hasPermitFeature,
              false,
              predefinedLookups
            ).filter((x) => !x.disableColumnMenu)
          : _.toLower(formState.dataset) == "monitoringevent"
          ? monEventColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "action"
          ? getActionColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "incident"
          ? incidentColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "inspectionevent"
          ? inspectionColumns
          : _.toLower(formState.dataset) == _.toLower(EntityTypes.EnergyLog)
          ? energyLogColumns(predefinedLookups)
          : _.toLower(formState.dataset) ==
            _.toLower(EntityTypes.InspectionEvent)
          ? inspectionColumns
          : monitoringResultColumns;
      if (
        !_.isEmpty(eventCustomFields) &&
        _.toLower(formState.dataset) == "event"
      ) {
        newColumns = [
          ...newColumns,
          ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
        ];
      }

      newColumns = newColumns.filter(
        (x) => x.filterable != false && x.useInDashboard != false
      );
      setGroupByColumns(newColumns);
    }
  }, [formState.dataset, eventCustomFields, organization.isLoading]);

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "titleError",
      "filterIdError",
      "groupByError"
    );
    formToSave.widgetType = props.widgetType;
    createGroupChartWidget(formToSave)
      .then(() => {
        props.handleModalClose(true);
        showAlert("success", "Widget created.");
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Title"
    );

    validationService.validateRequiredField(
      newState,
      "groupBy",
      "groupByError",
      "Group By"
    );

    validationService.validateRequiredField(
      newState,
      "dataset",
      "datasetError",
      "Data set"
    );

    validationService.validateRequiredField(
      newState,
      "filterId",
      "filterIdError",
      "Filter"
    );

    isFormValid = !validationService.hasError(
      newState,
      "titleError",
      "filterIdError",
      "groupByError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <>
      <Grid.Col md={12} width={12}>
        <Form.Group isRequired label="Title" id="title">
          <Form.Input
            name="title"
            error={formState.titleError}
            onChange={handleInputChange}
            value={formState.title}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Select Dataset" isRequired id="dataset">
          <Form.Select
            name="dataset"
            onChange={handleInputChange}
            value={formState.dataset}
            error={formState.datasetError}
          >
            <option value={""}></option>
            {hasAnyEventRole() ||
              (roleMatch([UserRoles.IncidentsUser]) && (
                <option value={"Action"}>Actions</option>
              ))}

            {hasAnyEventRole() && <option value={"Event"}>Events</option>}
            {roleMatch([UserRoles.IncidentsUser]) && (
              <option value={"Incident"}>Incidents</option>
            )}
            {roleMatch([UserRoles.InspectionsUser]) && (
              <option value={EntityTypes.InspectionEvent}>Inspections</option>
            )}

            {hasAnyMonitoringRole() && (
              <>
                <option value={"MonitoringEvent"}>Monitoring Events</option>
                <option value={"MonitoringResult"}>Monitoring Results</option>
              </>
            )}
            {roleMatch([UserRoles.EnergyUser]) && (
              <option value={EntityTypes.EnergyLog}>Energy Logs</option>
            )}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Select Filter" isRequired id="filterId">
          <Form.Select
            name="filterId"
            onChange={handleInputChange}
            value={formState.filterId}
            error={formState.filterIdError}
            disabled={formState.dataset == ""}
          >
            <option value={""}></option>
            {_.orderBy(filters, "name").map((filter) => (
              <option value={filter.id} key={filter.id}>
                {filter.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Group By" isRequired id="groupBy">
          <Form.Select
            name="groupBy"
            onChange={handleInputChange}
            value={formState.groupBy}
            error={formState.groupByError}
            disabled={formState.dataset == ""}
          >
            <option value={""}></option>
            {_.orderBy(groupByColumns, "headerName").map((col) => (
              <option value={col.field} key={col.field}>
                {col.headerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        {props.widgetType == "DonutChart" && (
          <DonutChartWidget
            title={formState.title}
            data={previewData}
            loading={loading}
          />
        )}
        {props.widgetType == "BarChart" && (
          <BarChartWidget
            title={formState.title}
            data={previewData}
            loading={loading}
          />
        )}
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Create Widget
        </Button>
      </Grid.Col>
    </>
  );
}
