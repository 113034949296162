import React, { useState } from "react";
import { Grid, Card, Form } from "components/lynx-components";
import { createLookup } from "../../../../services/lookup";
import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../../services";
import { LookupTypes } from "../../../../types/enums";
import useAlert from "hooks/useAlert";

const initialForm = {
  id: "",
  lookupType: "",
  type: "",
  description: "",
  codeError: "",
};

export function TagGroupModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const { showAlert } = useAlert();

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "codeError");
    if (props.id > 0) {
    } else {
      formToSave.id = 0;
      formToSave.lookupType = LookupTypes.TagGroup;
      createLookup(formToSave)
        .then((res) => {
          showAlert("success", "Tag group created.");
          props.handleAddTagGroup(true);
          clearForm();
          setFormState(initialFormState);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      "Group"
    );

    isFormValid = !validationService.hasError(newState, "codeError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  return (
    <Form className="w-30">
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={5} width={12}>
            <Form.Group label="Group" isRequired>
              <Form.Input
                type="text"
                value={formState.code}
                name="code"
                onChange={handleInputChange}
                error={formState.codeError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={5} width={12}>
            <Form.Group label="Description">
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2} width={12}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
