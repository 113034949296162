import React from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { EntityTypes } from "../../../types/enums";
import { EquipmentModal } from "./equipment-modal";
import { getEquipment } from "../../../services/equipment-service";
export const equipmentColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Asset",
    width: 150,
    type: "string",
  },
];
export function EquipmentList(props) {
  return (
    <>
      <LynxDataGrid
        columns={equipmentColumns}
        hasNoLookups
        getDataFunction={getEquipment}
        title="Equipment"
        localStorageName="equipment"
        entityName={EntityTypes.Equipment}
        entityModal={EquipmentModal}
        hasEditButton
        passEntityOnClick
        addButtonText="Add Equipment"
        {...props}
      />
    </>
  );
}
