import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { BootstrapInput } from "../bootstrap-inputs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export function SearchableSelect(props) {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(props.options) && !props.multiple) {
      if (props.idField) {
        var optionToSet = props.options.find(
          (x) => x[props.idField] == props.value
        );
        if (optionToSet) {
          setValue(optionToSet);
        } else {
          setValue(null);
        }
      } else {
        setValue(props.value);
      }
    }
    if (props.multiple) {
      setValue(props.value);
    }
  }, [props.value, props.options, props.idField]);
  const handleChange = (e, newValue) => {
    if (newValue == null) {
      props.idField
        ? props.onChange({ [props.idField]: "" })
        : props.onChange("");
    } else {
      props.onChange(newValue);
    }
  };

  const autoCompleteValueInputChange = (e, newValue, reason) => {
    setInputValue(newValue);
  };
  const mainProps = props;
  return (
    <FormControl sx={{ width: "100%" }} className={props.className}>
      <Autocomplete
        multiple={props.multiple}
        name={props.name}
        id={props.id}
        getOptionLabel={(option) =>
          props.labelFunction
            ? props.labelFunction(option)
            : option[props.labelField]
        }
        clearOnEscape
        renderTags={props.renderTags}
        disabled={props.disabled}
        autoHighlight={true}
        options={props.options}
        sx={{ width: props.width ?? "100%" }}
        inputValue={inputValue}
        disableCloseOnSelect={props.multiple}
        onInputChange={autoCompleteValueInputChange}
        value={props.multiple ? props.value : value}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {mainProps.renderOption
                ? mainProps.renderOption(option)
                : mainProps.labelFunction
                ? mainProps.labelFunction(option)
                : option[mainProps.labelField]}
            </li>
          );
        }}
        clearOnBlur
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <BootstrapInput
              {...params.InputProps}
              {...rest}
              placeholder={props.placeholder}
              readOnly={props.disabled}
              error={props.error}
            />
          );
        }}
      />
      {props.error && (
        <span className="invalid-feedback" style={{ display: "inline" }}>
          {props.error}
        </span>
      )}
    </FormControl>
  );
}
