import * as React from "react";
import cn from "classnames";
import FormGroup from "./form-group";

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  valid?: boolean;
  tick?: boolean;
  invalid?: boolean;
  cross?: boolean;
  feedback?: string;
  error?: string;
  placeholder?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  rows?: number;
  children?: string;
  label?: string;
}

const FormTextarea: React.FC<Props> = ({
  className,
  name,
  valid,
  tick,
  invalid,
  cross,
  error,
  placeholder,
  defaultValue,
  value,
  disabled,
  rows,
  children,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  label,
  feedback,
}) => {
  const classes = cn(
    "form-control",
    {
      "is-valid": valid,
      "state-valid": tick,
      "is-invalid": invalid || !!error,
      "state-invalid": cross || !!error,
    },
    className
  );

  feedback = error || feedback;

  const textareaComponent = (
    <React.Fragment>
      <textarea
        className={classes}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value || children}
        disabled={disabled}
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      />
      {feedback && <span className="invalid-feedback">{feedback}</span>}
    </React.Fragment>
  );

  return label ? (
    <FormGroup label={label}>{textareaComponent}</FormGroup>
  ) : (
    textareaComponent
  );
};

FormTextarea.displayName = "Form.Textarea";

export default FormTextarea;
