import Backdrop from "@mui/material/Backdrop";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { Card, Grid } from "components/lynx-components";
import { getLookups } from "../../../../services/lookup";

import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { LookupTypes } from "../../../../types/enums";
import { AddLookupHeader } from "../add-lookup-header";
import { EditLookupModal } from "../edit-lookup-modal";
const initialLookupData1 = {
  id: "",
  lookupType: "",
  code: "",
  description: "",
};
export function ContactSettings(props) {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [contactTypes, setContactTypes] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState(0);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [contactGroupTypes, setContactGroupTypes] = useState([]);
  const [selectedLookup, setSelectedLookup] = useState({});
  const [selectedLookupData, setSelectedLookupData] =
    useState(initialLookupData1);
  const [initialLookupData, setInitialLookupData] = useState({});
  const [typesLoading, setTypesLoading] = useState(true);
  const [groupTypesLoading, setGroupTypesLoading] = useState(true);
  const history = useHistory();
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const defaultColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const handleEditLookup = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const getCategoryData = async () => {
    setTypesLoading(true);
    const data = await getLookups(LookupTypes.ContactType);
    setContactTypes(data.data);
    setTypesLoading(false);
  };

  const loadContactGroupTypes = async () => {
    setGroupTypesLoading(true);
    const data = await getLookups(LookupTypes.ContactGroupType);
    setContactGroupTypes(data.data);
    setGroupTypesLoading(false);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  React.useEffect(() => {
    getCategoryData();
    loadContactGroupTypes();
  }, []);

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupSave = () => {
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.ContactType.toLowerCase()
    ) {
      getCategoryData();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.ContactGroupType.toLowerCase()
    ) {
      loadContactGroupTypes();
    }

    handleLookupModalClose();
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Contact Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Contact Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      {/* <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Contact Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              (e.g., Primary, Site Contact, Contractor).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType={LookupTypes.ContactType}
              lookupName="Contact Type"
              handleAddLookup={() => getCategoryData()}
            ></AddLookupHeader>

            <div style={{ height: 400, width: "100%" }}>
              <DataGridPro
                rows={contactTypes}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={typesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row> */}
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Contact Group Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              (e.g., First Nations Group, Service Provider).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType={LookupTypes.ContactGroupType}
              lookupName="Contact Group Type"
              handleAddLookup={() => loadContactGroupTypes()}
            ></AddLookupHeader>

            <div style={{ height: 400, width: "100%" }}>
              <DataGridPro
                rows={contactGroupTypes}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={groupTypesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupSave}
          lookupName={
            selectedLookup.lookupType
              ? selectedLookup.lookupType.toLowerCase() == "unit"
                ? "Unit"
                : selectedLookup.lookupType.toLowerCase() ==
                  "monitoringeventtype"
                ? "Monitoring Event Type"
                : ""
              : ""
          }
        />
      </Dialog>
    </Grid>
  );
}
