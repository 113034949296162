import React from "react";
import cn from "classnames";

interface Props extends React.HTMLProps<HTMLTableSectionElement> {
  children?: React.ReactNode;
  className?: string;
}

const TableHeader: React.FC<Props> = ({ className, children, ...props }) => {
  const classes = cn(className);
  return (
    <thead className={classes} {...props}>
      {children}
    </thead>
  );
};

export default TableHeader;
