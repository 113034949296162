import * as React from "react";
import cn from "classnames";
import FormGroup from "./form-group";

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  children?: React.ReactNode;
  className?: string;
  valid?: boolean;
  tick?: boolean;
  invalid?: boolean;
  cross?: boolean;
  feedback?: string;
  error?: string;
  label?: string;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  multiple?: boolean;
}

const FormSelect: React.FC<Props> = ({
  className,
  children,
  valid,
  tick,
  invalid,
  cross,
  feedback,
  error,
  label,
  disabled,
  name,
  value,
  onChange,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onClick,
  multiple,
}) => {
  const classes = cn(
    "form-control",
    "custom-select",
    {
      "is-valid": valid,
      "state-valid": tick,
      "is-invalid": invalid || !!error,
      "state-invalid": cross || !!error,
    },
    className
  );

  const feedbackMessage = error || feedback;

  const selectComponent = (
    <React.Fragment>
      <select
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onClick={onClick}
        className={classes}
        disabled={disabled}
        multiple={multiple}
      >
        {children}
      </select>
      {feedbackMessage && (
        <span className="invalid-feedback">{feedbackMessage}</span>
      )}
    </React.Fragment>
  );

  return label ? (
    <FormGroup label={label}>{selectComponent}</FormGroup>
  ) : (
    selectComponent
  );
};

export default FormSelect;
