import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState, FC } from "react";
import { Dimmer } from "components/lynx-components";
import { getTag } from "../../../services/tag-service";
import { LookupDto } from "types";

interface TagQuickCardProps {
  id?: number;
  type?: string;
  content?: string | JSX.Element;
}

const TagQuickCard: FC<TagQuickCardProps> = (props) => {
  const [tag, setTag] = useState<LookupDto>({} as LookupDto);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (props.id) {
      getTag(props.id).then((res) => {
        setTag(res.data);
        setIsLoading(false);
      });
    }
  }, [props.id, props.type]);

  return (
    <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
      <CardContent>
        <div style={{ maxWidth: 400, minWidth: 200 }}>
          {props.content || (
            <Dimmer active={isLoading} loader>
              {tag.parentLookupCode && (
                <Typography component="span">
                  <b>{tag.parentLookupCode} -&nbsp;</b>
                </Typography>
              )}
              <Typography component="span" className="mr-1">
                <b>{tag.code}</b>
              </Typography>
              {tag.childLookupCode && (
                <Typography variant="body2" className="mr-1">
                  {tag.childLookupCode}
                </Typography>
              )}
              {tag.description && (
                <Typography variant="body2" className="mr-1">
                  {tag.description}
                </Typography>
              )}
            </Dimmer>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TagQuickCard;
