import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { dateUtil } from "../../../services/date-util";
import React from "react";
import { useHistory } from "react-router-dom";
import { severityColorSwitch, statusColorSwitch } from "./incident-columns";
import { IncidentDto } from "types";

interface IncidentMobileCardProps {
  entity: IncidentDto;
}

const IncidentMobileCard: React.FC<IncidentMobileCardProps> = (props) => {
  const history = useHistory();
  const handleNavigateTo = (e: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    if (e) {
      e.preventDefault();
    }
    history.push(url);
  };

  const incident = props.entity;
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) => handleNavigateTo(e, `incidents/${incident.id}`)}
          >
            {incident.incidentNumber}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Asset: {incident.assetName}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {dateUtil.convertDateTimeToLocal(incident.incidentDateTime)}
        </Typography>
        <Typography variant="h6">{incident.title}</Typography>
        <Typography color="text.secondary">
          {`${incident.type}${incident.subType ? ` - ${incident.subType}` : ""}`}
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }} variant="body2">
          Description: {incident.description || "-"}
        </Typography>
        <Typography variant="body2">
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(incident.status || "") }}
          />
          &nbsp;Status: {incident.status}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <i
            className={"fe fe-circle "}
            style={{ color: severityColorSwitch(incident.severity || "") }}
          />
          &nbsp;Severity: {incident.severity || "None"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default IncidentMobileCard;