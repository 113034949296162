import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { WylooMap } from "./wyloo-map";
import "../wyloo/wyloo-microsite.scss";
import { WylooMenu } from "./wyloo-menu";
import { wylooTheme } from "./wyloo-theme";
import { useSelector } from "react-redux";
import { Dimmer } from "components/lynx-components";
import PageNotFound from "components/PageNotFound";
import { Grid } from "components/lynx-components";
import useWindowDimensions from "hooks/useWindowDimensions";
export function WylooMicrositeHome(props) {
  var portal = useSelector((state) => state.portal);
  const { width } = useWindowDimensions();
  const getMapCard = () => {
    return <WylooMap />;
  };

  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <Dimmer active={portal.isLoading} loader>
            <div
              className="header-container-wyloo"
              style={{
                backgroundImage: `url(
                "https://lynx-public.s3.amazonaws.com/wyloo/portal/images/Eagles-nest-bg.png"
              )`,
              }}
            >
              <div className="wyloo-header-text">
                <Typography variant={width < 550 ? "h3" : "h2"}>
                  {portal.name}
                </Typography>
              </div>
            </div>
            <Container maxWidth="xl">
              <Grid>
                <Grid.Row>
                  <Grid.Col width={12} className="mt-2 ">
                    <div
                      className="wyloo-text-section d-flex ml-auto mr-auto"
                      style={{ width: "fit-content", alignContent: "center" }}
                    >
                      <Typography
                        variant="h4"
                        className="text-center "
                        sx={{ width: "fit-content" }}
                      >
                        Welcome to the {portal.name}.
                      </Typography>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <WylooMenu {...props} />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col md={6} width={12} className="mb-2">
                    <div className="wyloo-text-section">
                      <Typography sx={{ width: "fit-content" }}>
                        <b>The Glass Box approach:</b>
                      </Typography>
                      <Typography
                        sx={{ width: "fit-content" }}
                        className="mt-2"
                      >
                        In partnership with Lynx Global Intelligence, Wyloo is
                        developing a novel approach on how we collect, store and
                        analyze and share our data, intended to provide maximum
                        transparency to communities and our stakeholders.
                      </Typography>
                      <Typography
                        sx={{ width: "fit-content" }}
                        className="mt-2"
                      >
                        This approach strives to provide near real-time access
                        to “whole-of-project” information to enable near
                        real-time access to data as well as compliance
                        monitoring once the project goes into construction and
                        operation. Through our Glass Box approach, we aim to
                        grant communities, stakeholders and regulators with
                        access to Project planning documentation and data. This
                        strategy is intended to eliminate information
                        disparities and help to foster relationships built on
                        trust.
                      </Typography>
                    </div>
                  </Grid.Col>
                  <Grid.Col md={6} width={12} className="mb-2">
                    <div className="wyloo-text-section">
                      <Typography sx={{ width: "fit-content" }}>
                        Wyloo is advancing extensive baseline data collection to
                        ensure we develop a deep understanding of the natural
                        environment of the Project area prior to development. In
                        line with this vision, this database is meant to be an
                        interface to share the most recent baseline data that
                        has been collected as part of our field programs. The
                        categories of information will expand in the coming
                        months as we collect, upload, and categorize
                        environmental and social data into the Lynx database.
                      </Typography>
                      <Typography sx={{ width: "fit-content" }}>
                        Further information on Wyloo’s comprehensive
                        sustainability reporting efforts is outlined in
                        our&nbsp;
                        <a
                          href="https://drive.google.com/file/d/1Kb8wRLptovImXaHMMjZNrj6Fzwa3G8to/view"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Canada Sustainability Approach – 2024 Report
                        </a>
                        .
                      </Typography>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12} className="mb-2">
                    <div className="wyloo-text-section">
                      <Typography sx={{ width: "fit-content" }}>
                        In the map below, you can view data by monitoring
                        location for water, air, wildlife and vegetation plots.
                        Outlined are also the mining lease for the Eagle’s Nest
                        Project as well as the current mine design footprint (~1
                        km2).
                      </Typography>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12} className="mb-5">
                    {!portal.isLoading && getMapCard()}
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </Container>
          </Dimmer>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
