import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Form, Grid } from "components/lynx-components";
import { getActionCard } from "../events/event-details-actions";
import "./incident-form.css";
import { getActions } from "services/action-service";
import { ActionDto, IncidentDto } from "types";

interface IncidentActionsProps {
  incident: IncidentDto;
}

const IncidentActions: React.FC<IncidentActionsProps> = (props) => {
  const history = useHistory();
  const [actions, setActions] = useState<ActionDto[]>([]);

  const navigateToAddActions = () => {
    history.push(`/incidents/${props.incident.id}/add-action`);
  };

  const handleActionNumberClick = (
    e: React.MouseEvent<HTMLDivElement>,
    action: ActionDto
  ) => {
    e.preventDefault();
    history.push(`/incidents/${props.incident.id}/actions/${action.id}`);
  };

  useEffect(() => {
    if (props.incident && props.incident.id) {
      getActions({ incidentIds: props.incident.id }).then((res) => {
        setActions(res.data);
      });
    }
  }, [props.incident]);

  return (
    <Container className="mt-2" maxWidth="xl">
      <Form className="card mb-0">
        <Card.Header>
          <Card.Title>Actions</Card.Title>
          <Button onClick={navigateToAddActions} className="ml-auto">
            Add Linked Action
          </Button>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              {_.isEmpty(actions) ? (
                <div>No linked actions</div>
              ) : (
                <div className="compliance-grid-container">
                  {actions.map((action) => (
                    <div className="dashboard-item" key={action.id}>
                      {getActionCard(
                        (e: any, action: ActionDto) =>
                          handleActionNumberClick(e, action),
                        action
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Form>
    </Container>
  );
};

export default IncidentActions;
