import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Grid as MuiGrid } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import "./LynxDataGrid.css";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "lodash";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CustomExportButton } from "./custom-export-button";
import { EntityTypes, UserRoles } from "../../types/enums";
import { roleMatch } from "../../actions/auth";
export function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <MuiGrid container item xs>
        <GridToolbarColumnsButton />
        <Tooltip title="Reset columns">
          <Button size="small" onClick={props.handleShowResetColumnDialog}>
            <RestartAltIcon fontSize="small" className="mr-1" />
            Reset Columns
          </Button>
        </Tooltip>
        <GridToolbarFilterButton className="filter-grid-button" />
        {!_.isEmpty(props.filterModel.items) && (
          <Tooltip title="Clear filter">
            <Button size="small" onClick={props.handleClearFilter}>
              <ClearIcon fontSize="small" className="mr-1" />
              Clear Filter
            </Button>
          </Tooltip>
        )}

        {!props.isOffline && props.enableSaveFilters && (
          <Tooltip title="Save filter">
            <Button size="small" onClick={props.handleLoadFilter}>
              <FolderSpecialIcon fontSize="small" className="mr-1" />
              Saved Filters
            </Button>
          </Tooltip>
        )}
        <GridToolbarDensitySelector />

        <CustomExportButton
          handleExportAsXLSX={props.handleExportAsXLSX}
          handleExportAttachments={props.handleExportAttachments}
          entityName={props.entityName}
        />

        {!props.isOffline && props.enableReports && (
          <Tooltip title="Reports">
            <Button size="small" onClick={props.handleReportsClick}>
              <DescriptionOutlinedIcon fontSize="small" className="mr-1" />
              Reports
            </Button>
          </Tooltip>
        )}
        {!props.isOffline &&
          props.bulkUpdateFunction &&
          roleMatch([
            UserRoles.Admin,
            UserRoles.EventsAdministrator,
            UserRoles.MonitoringAdministrator,
          ]) && (
            <Tooltip title="Bulk Update">
              <Button size="small" onClick={props.handleBulkUpdate}>
                <SyncAltIcon fontSize="small" className="mr-1" />
                Bulk Update
              </Button>
            </Tooltip>
          )}
        {!props.isOffline && props.getDataFunction && (
          <Tooltip title="Refresh">
            <Button size="small" onClick={props.handleRefresh}>
              <RefreshIcon fontSize="small" className="mr-1" />
              Refresh
            </Button>
          </Tooltip>
        )}
        {props.getQueryParamDisplay && <props.getQueryParamDisplay />}
      </MuiGrid>
    </GridToolbarContainer>
  );
}
