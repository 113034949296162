import React, { FC } from "react";
import GroupIcon from "@mui/icons-material/Group";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import { searchContacts } from "../../../services/contacts";
import { EventContactTypes } from "../../../types/enums";

export interface TaggableEntity {
  id: number | string;
  display: string;
}

interface CommentInputProps {
  value: string;
  onChange: OnChangeHandlerFunc;
  style?: React.CSSProperties;
  users: TaggableEntity[];
  isDisabled?: boolean;
}

const CommentsInput: FC<CommentInputProps> = ({
  value,
  onChange,
  style,
  users,
  isDisabled
}) => {
  const renderSuggestion = (
    entry: any,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => (
    <div className="d-flex">
      {entry.type === "ContactGroup" && (
        <GroupIcon className="mr-2" style={{ color: "rgba(0, 0, 0, 0.26)" }} />
      )}
      {highlightedDisplay}
    </div>
  );

  const fetchContacts = (
    query: string,
    callback: (data: TaggableEntity[]) => void
  ) => {
    if (!query) return;
    searchContacts(query)
      .then((res) =>
        res.data.map((contact: any) => ({
          id:
            contact.type === EventContactTypes.Contact
              ? "c_" + contact.id
              : "g_" + contact.id,
          display: contact.name,
          type: contact.type,
        }))
      )
      .then(callback);
  };

  return (
    <MentionsInput
      disabled={isDisabled}
      allowSpaceInQuery
      className="lynx-mentions-input"
      rows={4}
      value={value}
      onChange={onChange}
      style={style}
    >
      <Mention
        trigger="@"
        style={{
          backgroundColor: "#f3a738",
          opacity: 0.3,
          borderRadius: 3,
        }}
        displayTransform={(id, display) => `@${display}`}
        markup="@[__display__](__id__)"
        data={users}
      />
      <Mention
        trigger="#"
        style={{
          backgroundColor: "#00A9E0",
          opacity: 0.3,
          borderRadius: 3,
        }}
        renderSuggestion={renderSuggestion}
        displayTransform={(id, display) => `#${display}`}
        markup="#[__display__](__id__)"
        data={fetchContacts}
      />
    </MentionsInput>
  );
};

export default CommentsInput;
