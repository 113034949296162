import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Loader: React.FC<Props> = ({ className, children }) => {
  const classes = cn({ loader: true }, className);
  return <div className={classes}>{children}</div>;
};

export default Loader;
