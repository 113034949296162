import * as React from "react";
import cn from "classnames";
import CardHeader from "./card-header";
import CardTitle from "./card-title";
import CardBody from "./card-body";
import CardFooter from "./card-footer";

type Props = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  body?: React.ReactNode;
  RootComponent?: React.ElementType;
  options?: React.ReactNode;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  isClosable?: boolean;
  isClosed?: boolean;
  isFullscreenable?: boolean;
  statusColor?: string;
  statusSide?: boolean;
  alert?: React.ReactNode;
  alertColor?: string;
  footer?: string;
  aside?: boolean;
};

type State = {
  isClosed: boolean;
  isCollapsed: boolean;
  isFullscreen: boolean;
};

class Card extends React.PureComponent<Props, State> {
  state: State = {
    isClosed: this.props.isClosed || false,
    isCollapsed: this.props.isCollapsed || false,
    isFullscreen: false,
  };

  static Header = CardHeader;
  static Body = CardBody;
  static Title = CardTitle;
  static Footer = CardFooter;

  handleCloseOnClick = (): void => {
    this.setState((s) => ({
      isClosed: !s.isClosed,
    }));
  };

  handleCollapseOnClick = (): void => {
    this.setState((s) => ({
      isCollapsed: !s.isCollapsed,
    }));
  };

  handleFullscreenOnClick = (): void => {
    this.setState((s) => ({
      isFullscreen: !s.isFullscreen,
    }));
  };

  render(): React.ReactNode {
    const { className, children, RootComponent, title, body, aside, footer } =
      this.props;
    const { isClosed, isCollapsed, isFullscreen } = this.state;

    if (isClosed) {
      return null;
    }

    const classes = cn(
      {
        card: true,
        aside: aside,
        "card-collapsed": isCollapsed,
        "card-fullscreen": isFullscreen,
      },
      className
    );

    const Component = RootComponent || "div";

    const card_header = title && (
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
    );

    const card_body = body && <Card.Body>{body}</Card.Body>;

    const card_footer = footer && <Card.Footer>{footer}</Card.Footer>;

    if (card_header !== null || card_body !== null) {
      return (
        <Component className={classes}>
          {card_header}
          {card_body || children}
          {card_footer}
        </Component>
      );
    } else {
      return <Component className={classes}>{children}</Component>;
    }
  }
}

export default Card;
