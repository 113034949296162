import { dateUtil } from "services/date-util";

export const userHistoryColumns = [
  {
    field: "actionDateTime",
    headerName: "Date Time",
    width: 150,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "userFullName",
    headerName: "User",
    width: 100,
    type: "string",
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    type: "string",
  },
  {
    field: "url",
    headerName: "Url",
    width: 250,
    type: "string",
  },
  {
    field: "details",
    headerName: "Details",
    width: 400,
    type: "string",
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    width: 200,
    type: "string",
  },
];
