import { apiUrl, client } from "../modules/store";
const mainUrl = `${apiUrl}/customerPortal`;
export function getCustomerPortal() {
  return client.get(mainUrl);
}

export function toggleCustomerPortalStatus(isEnabled) {
  return client.post(mainUrl, isEnabled);
}
export function getUserHistory(params) {
  return client.get(mainUrl + "/history", {
    params: params,
  });
}
// Public endpoints
export function getMonitoringLocationsForMicrositeMap(url) {
  return client.get(mainUrl + "/mapLocations?url=" + url);
}
export function getDistinctParamtersPerPortalType(portalTypes, url) {
  return client.get(
    mainUrl +
      `/distinctParameters?portalTypes=${portalTypes.join(",")}&url=${url}`
  );
}
export function getPortalStats(portalTypes, url, monitoringLocationId = null) {
  return client.get(
    mainUrl +
      `/stats?portalTypes=${portalTypes.join(",")}&url=${url}${
        monitoringLocationId
          ? "&monitoringLocationId=" + monitoringLocationId
          : ""
      }`
  );
}
export function getLocationsPerPortalType(portalTypes, url) {
  return client.get(
    mainUrl + `/locations?portalTypes=${portalTypes.join(",")}&url=${url}`
  );
}
export function getObservationStats(url) {
  return client.get(`${mainUrl}/wildlifeStats?url=${url}`);
}
export function downloadImage(filePath, url) {
  return client
    .get(mainUrl + `/downloadImage?filePath=${filePath}&url=${url}`, {
      responseType: "blob",
    })
    .then((res) => res);
}

// Private endpoints
export function getMonitoringLocationForMicrositeMap(id, url) {
  return client.get(mainUrl + `/location/${id}?url=${url}`);
}

export function getDistinctParamtersPerMonitoringLocation(id, url) {
  return client.get(mainUrl + `/location/${id}/parameters?url=${url}`);
}
export function getObservations(locationId, url) {
  return client.get(
    `${mainUrl}/location/${locationId}/observations?url=${url}`
  );
}

export function getPortalDetails(url) {
  return client.get(`${mainUrl}/details?url=${url}`);
}
export function logUserHistory(historyDto) {
  return client.post(`${mainUrl}/history`, historyDto);
}
