import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
export function MonitoringLocationMobileCard(props) {
  const location = props.entity;
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const handleEditMonitoringLimits = (id) => {
    history.push(`/monitoring-locations/${id}/limits`);
  };
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) =>
              props.isForMap && location.isTimeSeriesLocation
                ? handleNavigateTo(
                    e,
                    `monitoring?view=timeseries&monitoringLocationId=${location.id}`
                  )
                : props.isForMap
                ? handleNavigateTo(
                    e,
                    `monitoring?view=events&monitoringLocationId=${location.id}`
                  )
                : handleNavigateTo(e, `monitoring-locations/${location.id}`)
            }
          >
            {location.monitoringLocationNumber}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Asset: {location.assetName}
        </Typography>

        <Typography variant="h6">{location.name}</Typography>

        <Typography variant="body2">
          Type: {location.monitoringLocationType || "-"}
        </Typography>
        <Typography variant="body2" className="mb-2">
          Description: {location.description || "-"}
        </Typography>
      </CardContent>
      {!props.isForMap && (
        <CardActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleEditMonitoringLimits(location.id)}
          >
            Edit Monitoring Parameters and Limits
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
