import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form, Dimmer } from "components/lynx-components";
import Button from "@mui/material/Button";
import _ from "lodash";
import * as jsonpatch from "fast-json-patch";

import { roleMatch } from "../../../../actions/auth";
import { validationService } from "./../../../../services/validation";
import { patchParameter } from "../../../../services/parameters";
import { deleteParameter } from "./../../../../services/parameters";
import { UserRoles } from "../../../../types/enums";
import useAlert from "hooks/useAlert";

const initialForm = {
  id: "",
  name: "",
  description: "",
  defaultUnitId: "",
  nameError: "",
};
export function ParameterModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const { showAlert } = useAlert();
  useEffect(() => {
    if (props.parameter) {
      setFormStateFromProps(props.parameter);
      setIsLoading(false);
    }
  }, [props.parameter]);

  const setFormStateFromProps = (param) => {
    let newState = {
      id: param.id,
      name: param.name,
      defaultUnitId: param.defaultUnitId,
      description: param.description,
    };
    setFormState(newState);
    setInitialFormState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "nameError");
    if (props.parameter.id > 0) {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      patchParameter(props.parameter.id, diff)
        .then((res) => {
          showAlert("success", "Parameter saved.");
          props.handleParameterSave();
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    isFormValid = !validationService.hasError(newState, "nameError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleDelete = () => {
    deleteParameter(props.parameter.id).then((res) => {
      showAlert("success", `${props.parameter.name} deleted.`);
      props.handleParameterSave();
    });
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Dimmer active={isLoading} loader>
          <Card.Title>
            {"Edit Parameter"}

            <Icon
              name="x"
              onClick={props.handleModalClose}
              className="float-right pointer"
            ></Icon>
          </Card.Title>

          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label={"Name"}>
                <Form.Input
                  type="text"
                  value={formState.name}
                  name="name"
                  onChange={handleInputChange}
                  error={formState.nameError}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label={"Description"}>
                <Form.Input
                  type="text"
                  value={formState.description}
                  name="description"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label="Default Unit">
                <Form.Select
                  name="defaultUnitId"
                  onChange={handleInputChange}
                  value={formState.defaultUnitId}
                >
                  <option value={""}></option>
                  {props.units.map((unit) => (
                    <option value={unit.id} key={unit.id}>
                      {unit.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {((roleMatch([UserRoles.Admin, UserRoles.MonitoringAdministrator]) &&
          !props.parameter.isInUse) ||
          roleMatch([UserRoles.InternalUser])) && (
          <Button
            variant="contained"
            color="error"
            className="float-left"
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Save
        </Button>
      </Card.Footer>
    </Form>
  );
}
