import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  getSubmission,
  patchSubmission,
} from "../../services/portal-submission-service";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as jsonpatch from "fast-json-patch";
import { Card, Form, Grid } from "components/lynx-components";
import lynxColors from "../../modules/lynxColors";
import { LynxDialog } from "../lynx-dialog";
import { portalForm } from "./community-portal";
import useAlert from "hooks/useAlert";
export function SubmissionDetails(props) {
  const [formState, setFormState] = useState({});
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const { showAlert } = useAlert();
  useEffect(() => {
    if (props.match.params.id) {
      getSubmission(props.match.params.id).then((res) => {
        setFormState(res.data);
      });
    }
  }, [props.match.params.id]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleCloseSubmission = () => {
    var diff = jsonpatch.compare({ name: "matt" }, { name: "matt" });
    patchSubmission(formState.id, diff).then((res) => {
      setFormState(res.data);
      setShowCloseDialog(false);
      showAlert("success", "Submission Closed.");
    });
  };

  return (
    <>
      <Paper>
        {" "}
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col lg={12} width={12} className="">
            <div className="d-flex">
              <Typography variant="h3" component="div">
                Portal Submission {formState.submissionNumber}
              </Typography>{" "}
              <div className="align-self-center ml-5">
                {getStatusChip(formState)}
              </div>
            </div>
          </Grid.Col>{" "}
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/portal-submissions")}
              >
                Portal Submissions
              </Link>
              <Typography color="text.primary">
                {formState.submissionNumber}
              </Typography>
            </Breadcrumbs>{" "}
          </Grid.Col>
        </Grid.Row>
      </Paper>
      {_.toLower(formState.status) == "pending review" && (
        <Container className="mt-2" maxWidth="xl">
          <Card>
            <Card.Header>
              {" "}
              <Card.Title>Next Steps</Card.Title>
            </Card.Header>
            <Card.Footer>
              <Button
                variant="contained"
                onClick={(e) => handleNavigateTo(e, "/submit-incident")}
                className="mr-2"
              >
                Create Incident
              </Button>

              <Button
                color="error"
                onClick={() => setShowCloseDialog(true)}
                variant="contained"
              >
                Close Complaint
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      )}
      <Container className="mt-2" maxWidth="xl">
        <Form className="card ">
          <Card.Header>
            <Card.Title>Submission Details</Card.Title>
          </Card.Header>
          <Card.Body>
            {portalForm(handleInputChange, formState, true)}
          </Card.Body>
        </Form>
      </Container>
      {showCloseDialog && (
        <LynxDialog
          open={showCloseDialog}
          title="Are you sure you want to close this submission?"
          handleClose={() => setShowCloseDialog(false)}
          handleConfirm={handleCloseSubmission}
        />
      )}
    </>
  );
}

export const getStatusChip = (action, size) => {
  if (_.toLower(action.status) == "pending review") {
    return (
      <Chip
        label="Pending Review"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(action.status) == "overdue") {
    return (
      <Chip
        label="Overdue"
        size={size}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
      />
    );
  }
  if (_.toLower(action.status) == "completed") {
    return <Chip label="Completed" size={size} color="primary" />;
  }
  return <Chip label="Closed" size={size} color="primary" />;
};
