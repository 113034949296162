import { getContactGroups } from "../../../services/contacts";

export const contactColumns = [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 150,
    type: "string",
  },
  {
    field: "preferredName",
    headerName: "Preferred Name",
    width: 150,
    type: "string",
  },
  {
    field: "contactGroupName",
    headerName: "Group",
    width: 150,
    type: "singleSelect",
    lookupFunction: getContactGroups,
    valueField: "id",
    labelField: "name",
  },

  {
    field: "email",
    headerName: "Email",
    width: 150,
    type: "string",
    renderCell: (params) => (
      <div>
        {params.value ? (
          <a href={`mailto:${params.value}`}>{params.value}</a>
        ) : (
          ""
        )}
      </div>
    ),
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    type: "string",
    renderCell: (params) => (
      <div>
        {params.value ? <a href={`sms:${params.value}`}>{params.value}</a> : ""}
      </div>
    ),
  },

  {
    field: "title",
    headerName: "Title",
    width: 150,
    type: "string",
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 250,
    type: "string",
  },
  {
    field: "fullAddress",
    headerName: "Address",
    width: 250,
    type: "string",
  },
  {
    field: "mailingAddress",
    headerName: "Mailing Address",
    width: 250,
    type: "string",
  },
  {
    field: "numberOfAssociatedEvents",
    headerName: "# Associated Events",
    width: 250,
    type: "number",
  },
];
