import { apiUrl, client } from "../modules/store";
import _ from "lodash";
import queryString from "query-string";
export function getInspections(params) {
  return client.get(apiUrl + "/inspections", {
    params: params,
  });
}

export function updateInspection(
  id,
  dto,
  isSubmit,
  updateInspectionFormOnly = false
) {
  const qObj = {
    isCompleted: isSubmit,
    updateInspectionFormOnly: updateInspectionFormOnly,
  };
  return client.put(
    `${apiUrl}/inspections/${id}?${queryString.stringify(qObj)}`,
    dto
  );
}

export function createInspection(formData, isSubmit) {
  return client.post(
    `${apiUrl}/inspections${isSubmit ? "?isCompleted=true" : ""}`,
    formData
  );
}
export function deleteInspection(id) {
  return client.delete(`${apiUrl}/inspections/${id}`);
}
export function getInspection(id) {
  return client.get(apiUrl + "/inspections/" + id);
}
export function getInspectionCountByFilterId(filterId) {
  let url = apiUrl + "/inspections/count/" + filterId;
  return client.get(url);
}

export function updateInspectionDetails(id, dto) {
  let url = `${apiUrl}/inspections/${id}/details`;

  return client.put(url, dto);
}
