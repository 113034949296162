import React, { useState } from "react";
import { getOrganizations } from "../../services/admin";
import { LynxDataGrid } from "../data-grid/LynxDataGrid";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { OrganizationModal } from "./organization-modal";
import { Link } from "@mui/material";
export function OrganizationList(props) {
  const [showAddEditOrganization, setShowAddEditOrganization] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const handleClickOrganization = (org) => {
    setSelectedOrganization(org);
    setShowAddEditOrganization(true);
  };
  const organizationColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      type: "string",
      renderCell: (params) => {
        return (
          <Link
            href="#"
            onClick={() => {
              handleClickOrganization(params.row);
            }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer",
      width: 150,
      type: "string",
    },
    {
      field: "hasEvents",
      headerName: "Has Events",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox checked={params.value} name="hasEvents" disabled={true} />
        </div>
      ),
    },
    {
      field: "hasMonitoring",
      headerName: "Has Monitoring",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasMonitoring"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasIncidents",
      headerName: "Has Incidents",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasIncidents"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasInspections",
      headerName: "Has Inspections",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasInspections"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasEnergyLog",
      headerName: "Has Energy Log",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasEnergyLog"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasPhotoAnalysis",
      headerName: "Has Photo Analysis",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasPhotoAnalysis"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasPermitFeature",
      headerName: "Has Permit Feature",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasPermitFeature"
            disabled={true}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {" "}
      <LynxDataGrid
        addButtonAction={() => {
          setShowAddEditOrganization(true);
        }}
        addButtonText="Add Organization"
        columns={organizationColumns}
        getDataFunction={getOrganizations}
        localStorageName="organizations"
        entityName={"Organization"}
        {...props}
        hasNoLookups
      />
      {showAddEditOrganization && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showAddEditOrganization}
        >
          <OrganizationModal
            selectedOrganization={selectedOrganization}
            handleModalClose={() => {
              setSelectedOrganization({});
              setShowAddEditOrganization(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
}
