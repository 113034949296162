import * as React from "react";
import cn from "classnames";
import PageMain from "./page-main";
import PageContent from "./page-content";
import PageHeader from "./page-header";
import PageTitle from "./page-title";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Page: React.FC<Props> & {
  Main: typeof PageMain;
  Content: typeof PageContent;
  Header: typeof PageHeader;
  Title: typeof PageTitle;
} = ({ className, children }) => {
  const classes = cn("page", className);
  return <div className={classes}>{children}</div>;
};

Page.Main = PageMain;
Page.Content = PageContent;
Page.Header = PageHeader;
Page.Title = PageTitle;

export default Page;
