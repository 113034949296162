import * as React from "react";
import cn from "classnames";
import FormInput from "./form-input";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isInline?: boolean;
}

const FormRadio: React.FC<Props> = ({
  className,
  label,
  value,
  name,
  checked,
  disabled,
  readOnly,
  onChange,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onBlur,
  onFocus,
  onClick,
  isInline,
}) => {
  const classes = cn(
    "custom-control custom-radio",
    { "custom-control-inline": isInline },
    className
  );

  const inputComponent = (
    <FormInput
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className={classes}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onBlur={onBlur}
      onFocus={onFocus}
      onClick={onClick}
    />
  );

  return label ? (
    <label className={classes}>
      {inputComponent}
      <span className="custom-control-label">{label}</span>
    </label>
  ) : (
    inputComponent
  );
};

FormRadio.displayName = "Form.Radio";

export default FormRadio;
