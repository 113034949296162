import { LynxDialog } from "./../../lynx-dialog";
import useAlert from "hooks/useAlert";
import { FC, useEffect, useState } from "react";
import { Card, Grid } from "components/lynx-components";
import { ContactDto, ContactSearchResultDto } from "types";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  chipClasses,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getContact, mergeContact, searchContacts } from "services/contacts";
import useDebounce from "hooks/useDebounce";
import { navigateTo } from "services/navigation-service";
import { useHistory } from "react-router-dom";

type Props = {
  isOpen: boolean;
  fromContact: ContactDto;
  handleClose: () => void;
};

const na = "-";

const ContactMergeModal: FC<Props> = ({ isOpen, fromContact, handleClose }) => {
  const [toContact, setToContact] = useState<ContactDto | null>(null);
  const [options, setOptions] = useState<ContactSearchResultDto[]>([]);
  const [selectedContact, setSelectedContact] =
    useState<ContactSearchResultDto | null>(null);
  const [autoCompleteContactInputValue, setAutoCompleteContactInputValue] =
    useState("");
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isMerging, setIsMerging] = useState(false);

  const { showAlert } = useAlert();
  const history = useHistory();
  const debouncedInputValue = useDebounce(autoCompleteContactInputValue, 300);

  const handleAutoCompleteContactInputChange = (
    e: any,
    newValue: string,
    reason: string
  ) => {
    if (reason === "reset") {
      setSelectedContact(null);
    } else {
      setAutoCompleteContactInputValue(newValue);
    }
  };

  const handleSelectContact = (
    e: any,
    value: ContactSearchResultDto | null
  ) => {
    if (value) {
      setSelectedContact(value);
      setAutoCompleteContactInputValue("");
    }
  };

  const handleSubmit = () => {
    if (toContact != null) {
      setIsMerging(true);
      const payload = {
        fromContactDto: fromContact,
        toContactDto: toContact,
      };
      mergeContact(payload)
        .then((result) => {
          showAlert("success", "Contact merged succesfully.");
          handleClose();
          navigateTo(history, "/contacts");
        })
        .catch((err) => showAlert("error", err.response.data.message))
        .finally(() => {
          setIsMerging(false);
          setIsConfirmOpen(false);
        });
    }
  };

  useEffect(() => {
    if (debouncedInputValue === "") {
      setOptions([]);
      return;
    }
    setOptionsLoading(true);
    searchContacts(debouncedInputValue).then((res) => {
      setOptions(
        res.data.filter((f: ContactSearchResultDto) => f.id !== fromContact.id)
      );
      setOptionsLoading(false);
    });
  }, [debouncedInputValue]);

  useEffect(() => {
    if (selectedContact) {
      getContact(selectedContact.id).then((res) => {
        setToContact(res.data);
      });
    } else {
      setToContact(null);
    }
  }, [selectedContact]);

  return (
    <>
      <LynxDialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        handleClose={handleClose}
        title="Merge Contact"
        dialogContent={
          <Stack direction="row" spacing={1}>
            <Card>
              <Card.Header className="px-3">Merge From</Card.Header>
              <Card.Body className="px-0 py-2">
                <Grid>
                  <Grid.Row className="mb-2">
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Full Name</div>
                      <div className="details-value-text">
                        {fromContact.fullName || na}
                      </div>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Preferred Name</div>
                      <div className="details-value-text">
                        {fromContact.preferredName || na}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-2">
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Contact Group</div>
                      <div className="details-value-text">
                        {fromContact.contactGroupName || na}
                      </div>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Title</div>
                      <div className="details-value-text">
                        {fromContact.title || na}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-2">
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Phone</div>
                      <div className="details-value-text">
                        {fromContact.phone || na}
                      </div>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Email</div>
                      <div className="details-value-text">
                        {fromContact.email || na}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-2">
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Full Address</div>
                      <div className="details-value-text">
                        {fromContact.fullAddress || na}
                      </div>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <div className="details-label-text">Mailing Address</div>
                      <div className="details-value-text">
                        {fromContact.mailingAddress || na}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-2">
                    <Grid.Col md={12} width={12}>
                      <div className="details-label-text">Notes</div>
                      <div className="details-value-text">
                        {fromContact.notes || na}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header className="px-3 py-0">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <span>Merge To</span>
                  {selectedContact ? (
                    <Chip
                      label={`${selectedContact.name}`}
                      variant="outlined"
                      sx={{
                        [`& .${chipClasses.icon}`]: {
                          color: "rgba(0, 0, 0, 0.26)",
                        },
                      }}
                      onDelete={() => setSelectedContact(null)}
                    />
                  ) : (
                    <Box width="70%">
                      <Autocomplete
                        loading={optionsLoading}
                        filterOptions={(x) => x}
                        id="contact-search"
                        options={options}
                        noOptionsText={
                          !autoCompleteContactInputValue
                            ? "Enter text to search"
                            : "No options"
                        }
                        getOptionLabel={(option) =>
                          option.name + " " + option.contactGroupName
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name}
                            <Typography variant="body2" className="ml-2">
                              {option.contactGroupName}
                            </Typography>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Search contacts"
                          />
                        )}
                        onChange={handleSelectContact}
                        value={selectedContact}
                        inputValue={autoCompleteContactInputValue}
                        onInputChange={handleAutoCompleteContactInputChange}
                      />
                    </Box>
                  )}
                </Box>
              </Card.Header>
              <Card.Body className="px-0 py-2">
                {selectedContact && toContact ? (
                  <Grid>
                    <Grid.Row className="mb-2">
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Full Name</div>
                        <div className="details-value-text">
                          {toContact.fullName || na}
                        </div>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Preferred Name</div>
                        <div className="details-value-text">
                          {toContact.preferredName || na}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-2">
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Contact Group</div>
                        <div className="details-value-text">
                          {toContact.contactGroupName || na}
                        </div>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Title</div>
                        <div className="details-value-text">
                          {toContact.title || na}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-2">
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Phone</div>
                        <div className="details-value-text">
                          {toContact.phone || na}
                        </div>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Email</div>
                        <div className="details-value-text">
                          {toContact.email || na}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-2">
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">Full Address</div>
                        <div className="details-value-text">
                          {toContact.fullAddress || na}
                        </div>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <div className="details-label-text">
                          Mailing Address
                        </div>
                        <div className="details-value-text">
                          {toContact.mailingAddress || na}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-2">
                      <Grid.Col md={12} width={12}>
                        <div className="details-label-text">Notes</div>
                        <div className="details-value-text">
                          {toContact.notes || na}
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Col>No Selected Contact.</Grid.Col>
                  </Grid>
                )}
              </Card.Body>
            </Card>
          </Stack>
        }
        buttons={() => {
          return (
            <Button
              disabled={toContact == null}
              variant="contained"
              color="success"
              onClick={() => setIsConfirmOpen(true)}
            >
              Merge
            </Button>
          );
        }}
      />
      {isConfirmOpen && (
        <LynxDialog
          open={isConfirmOpen}
          title="Confirm Merge"
          dialogContent={
            <p>
              Are you sure you want to merge contact{" "}
              <strong>{fromContact.fullName}</strong> into contact{" "}
              <strong>{toContact?.fullName}</strong>? {fromContact.fullName}{" "}
              will be deleted. This cannot be undone.
            </p>
          }
          handleClose={() => setIsConfirmOpen(false)}
          handleConfirm={handleSubmit}
        />
      )}
    </>
  );
};

export default ContactMergeModal;
