import { Container, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { Grid } from "components/lynx-components";
import { BadgeWidget } from "../../dashboards/dashboards/widgets/badge-widget";
import { WylooMap } from "./wyloo-map";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { wylooTheme } from "./wyloo-theme";
import moment from "moment";
import PageNotFound from "components/PageNotFound";
import { MonitoringLocationPortalTypes } from "../../../types/enums";
import { WylooLineChart } from "./wyloo-line-chart";
import { getPortalStats } from "../../../services/customer-portal-service";
import { useHistory, useLocation } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import { useSelector } from "react-redux";
export function WylooMicrositeAir(props) {
  const [portalStats, setPortalStats] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const portal = useSelector((state) => state.portal);
  useEffect(() => {
    getPortalStats([MonitoringLocationPortalTypes.Air], location.pathname)
      .then((response) => {
        setPortalStats(response.data);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  }, []);

  const handleLocationSelected = (e) => {
    setSelectedLocation(e);
  };

  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <div className="header-container-wyloo" style={{}}>
            <img
              alt="wyloo-header-image"
              className="wyloo-header-image"
              src="https://lynx-public.s3.amazonaws.com/wyloo/portal/images/Mincor-220906-016-64.png"
            ></img>
            <div className="wyloo-header-text">
              <Typography variant="h2">
                <IconButton
                  onClick={(e) => navigateTo(history, "/wyloo/", e)}
                  variant="outlined"
                  className="mr-5"
                  sx={{ color: "white", border: "3px solid white" }}
                  size="large"
                >
                  <ArrowBackIcon />
                </IconButton>
                {`Air Monitoring`}
              </Typography>
            </div>
          </div>
          <Container maxWidth="xl">
            <Grid className="">
              <Grid.Row>
                <Grid.Col width={12} lg={6} className="mt-2">
                  <div className="wyloo-text-section">
                    <Typography sx={{ width: "fit-content" }}>
                      The Project aims to operate as a net zero emissions mine,
                      prioritizing environmental responsibility alongside
                      productivity. Comprehensive power studies are currently
                      underway to determine the most efficient and sustainable
                      energy sources for the Project. Upgrades have been made to
                      on-site weather stations and long-term air quality
                      monitoring programs continue to characterize the ambient
                      environment to support an understanding of viability of
                      alternative power generation.
                    </Typography>
                  </div>
                </Grid.Col>
                <Grid.Col width={12} lg={3} sm={6} className="mt-2">
                  <BadgeWidget
                    isLoading={statsLoading}
                    title={"Total Sample Events"}
                    value={portalStats.totalSampleEvents}
                    subValue={`Between ${moment(
                      portalStats.firstSampleDate
                    ).format("MMM YYYY")} and ${moment(
                      portalStats.lastSampleDate
                    ).format("MMM YYYY")}`}
                    className="portal-badge"
                  />
                </Grid.Col>
                <Grid.Col width={12} lg={3} sm={6} className="mt-2">
                  <BadgeWidget
                    isLoading={statsLoading}
                    title={"Unique Parameters Sampled"}
                    value={portalStats.distinctParametersSampled}
                    className="portal-badge"
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12} md={12} className="mt-2">
                  <div className="wyloo-text-section">
                    <Typography sx={{ width: "fit-content" }}>
                      In alignment with emission reduction goals, Wyloo intends
                      to deploy an all-electric vehicle (EV) fleet for on-site
                      activities during operations. This proactive measure will
                      not only significantly reduce greenhouse gas emissions,
                      but also foster a cleaner and healthier working
                      environment for our workforce and surrounding communities
                      by reducing ventilation requirements underground. By
                      exclusively utilizing an EV fleet, we aim to significantly
                      reduce our potential carbon footprint while maintaining
                      operational efficiency.
                    </Typography>
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12} lg={6} className="mt-2 mb-5">
                  <WylooMap
                    selectedLocation={selectedLocation}
                    portalTypes={[MonitoringLocationPortalTypes.Air]}
                  />
                </Grid.Col>
                <Grid.Col width={12} lg={6} className="mt-2">
                  <WylooLineChart
                    handleLocationSelected={handleLocationSelected}
                    portalTypes={[MonitoringLocationPortalTypes.Air]}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
