import React from "react";
import { EntityTypes } from "types/enums";
import { getUserHistory } from "../../../../services/customer-portal-service";
import { LynxDataGrid } from "../../../data-grid/LynxDataGrid";
import { userHistoryColumns } from "./user-history-columns";
export function CustomerPortalHistory(props) {
  return (
    <LynxDataGrid
      hasNoLookups
      title="Customer Portal User History"
      columns={userHistoryColumns}
      getDataFunction={getUserHistory}
      localStorageName="customer_portal_user_history"
      entityName={EntityTypes.CustomerPortalUserHistory}
      {...props}
    />
  );
}
