import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { dateUtil } from "../../../services/date-util";
import React from "react";
import { useHistory } from "react-router-dom";

export function EnergyLogMobileCard(props) {
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const energyLog = props.entity;
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            //onClick={(e) => handleNavigateTo(e, `energy-log/${energylog.id}`)}
          >
            {energyLog.energyLogNumber}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Asset: {energyLog.assetName}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Log Date: {dateUtil.convertDateOnlyToLocal(energyLog.energyLogDate)}
        </Typography>
        <Typography color="text.secondary">
          Scope 1 Total: {energyLog.scope1Total}
        </Typography>
        <Typography color="text.secondary">
          Scope 2 Total: {energyLog.scope2Total}
        </Typography>
        <Typography color="text.secondary">
          Scope 3 Total: {energyLog.scope3Total}
        </Typography>
      </CardContent>
    </Card>
  );
}
