import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { EntityTypes, UserRoles } from "../../../types/enums";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole,
  roleMatch,
} from "../../../actions/auth";
export function Step1(props) {
  return (
    <>
      <FormControl sx={{ marginBottom: 2, marginTop: 1, minWidth: 250 }}>
        <Select
          value={props.entityType}
          onChange={(event) => props.handleSetEntityType(event.target.value)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
        >
          <MenuItem value={""}>&nbsp;</MenuItem>
          {hasAnyEventRole() && (
            <MenuItem value={EntityTypes.Event}>Events</MenuItem>
          )}
          {hasAnyMonitoringRole() && (
            <MenuItem value={EntityTypes.MonitoringResult}>
              Monitoring Results
            </MenuItem>
          )}
          {roleMatch([UserRoles.TimeSeriesUser]) && (
            <MenuItem value={EntityTypes.TimeSeries}>Timeseries</MenuItem>
          )}
        </Select>
      </FormControl>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
            disabled={!props.entityType}
          >
            Continue
          </Button>
        </div>
      </Box>
    </>
  );
}
