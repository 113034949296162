import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
  RootComponent?: React.ElementType;
};

const CardTitle: React.FC<Props> = ({ className, children, RootComponent }) => {
  const classes = cn("card-title", className);
  const Component = RootComponent || "h3";
  return <Component className={classes}>{children}</Component>;
};

export default CardTitle;
