import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const CardBody: React.FC<Props> = ({ className, children }) => {
  const classes = cn("card-body", className);
  return <div className={classes}>{children}</div>;
};

export default CardBody;
