import Container from "@mui/material/Container";
import React from "react";
import { Card, Grid } from "components/lynx-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { dateUtil } from "../../../services/date-util";
import lynxColors from "../../../modules/lynxColors";
import { IncidentDto } from "types";

interface IncidentAuditProps {
  incident?: IncidentDto;
}

const IncidentAudit: React.FC<IncidentAuditProps> = (props) => {
  return (
    <Container className="mt-2" maxWidth="xl">
      <Grid.Col md={12} width={12} className="pl-0 pr-0 mb-2">
        <Card>
          <Card.Header className="justify-content-between">
            <Card.Title>{"Audit"}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={12} width={12}>
                <TableContainer
                  sx={{ width: 500, border: `1px solid ${lynxColors.gray}` }}
                  component={"div"}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Initial Report Submitted By:</TableCell>
                        <TableCell>
                          {props.incident?.submittedByUserFullName}
                        </TableCell>
                        <TableCell>
                          {dateUtil.convertDateTimeToLocal(
                            props.incident?.submittedDateTimeUtc
                          )}
                        </TableCell>
                      </TableRow>
                      {props.incident?.initialReviewCompletedDateTimeUtc && (
                        <TableRow>
                          <TableCell>Initial Review Submitted By:</TableCell>
                          <TableCell>
                            {
                              props.incident
                                .initialReviewCompletedByUserFullName
                            }
                          </TableCell>
                          <TableCell>
                            {dateUtil.convertDateTimeToLocal(
                              props.incident.initialReviewCompletedDateTimeUtc
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {props.incident?.investigationSubmittedDateTimeUtc && (
                        <TableRow>
                          <TableCell>Investigation Submitted By:</TableCell>
                          <TableCell>
                            {
                              props.incident
                                .investigationSubmittedByUserFullName
                            }
                          </TableCell>
                          <TableCell>
                            {dateUtil.convertDateTimeToLocal(
                              props.incident.investigationSubmittedDateTimeUtc
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {props.incident?.finalReviewCompletedDateTimeUtc && (
                        <TableRow>
                          <TableCell>Final Review Submitted By:</TableCell>
                          <TableCell>
                            {props.incident.finalReviewCompletedByUserFullName}
                          </TableCell>
                          <TableCell>
                            {dateUtil.convertDateTimeToLocal(
                              props.incident.finalReviewCompletedDateTimeUtc
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Container>
  );
};

export default IncidentAudit;
