import React, { useState } from "react";
import { Grid, Card, Form } from "components/lynx-components";
import { createLookup } from "../../../../services/lookup";
import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../../services";
import useAlert from "hooks/useAlert";

type Props = {
  handleSaveSuccess: () => void;
};

const EventCorrespondenceLogType: React.FC<Props> = ({ handleSaveSuccess }) => {
  const initialForm = {
    code: "",
    description: "",
    codeError: "",
  };
  const [formState, setFormState] = useState(initialForm);
  const { showAlert } = useAlert();

  const handleInputChange = (e: any) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(formState, "codeError");
    formToSave.id = 0;
    formToSave.lookupType = "CorrespondenceLogType";
    createLookup(formToSave)
      .then((res) => {
        showAlert("success", "Event correspondence log type created.");
        handleSaveSuccess();
        setFormState(initialForm);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      "Type"
    );

    isFormValid = !validationService.hasError(newState, "codeError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="w-30" id="incident-type-form">
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group label="Type" isRequired>
              <Form.Input
                type="text"
                value={formState.code}
                name="code"
                onChange={handleInputChange}
                error={formState.codeError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group label="Description">
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={12} width={6}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
};

export default EventCorrespondenceLogType;
