import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  AudioFile as AudioFileIcon,
  Info as InfoIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Lock as LockIcon,
  VideoFile as VideoFileIcon,
} from "@mui/icons-material";
import { saveAs } from "file-saver";
import _ from "lodash";
import { Dimmer, Form } from "components/lynx-components";
import { LynxDialog } from "components/lynx-dialog";
import {
  addEntityAttachments,
  deleteEntityAttachment,
  downloadAttachment,
  downloadThumbnail,
  getEntityAttachments,
  updateAttachmentDesciption,
  validateFileSize,
} from "services/attachments";
import { downloadImportedAttachment } from "services/import";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import useAlert from "hooks/useAlert";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { EntityAttachmentDto, EventDto } from "types";
import { dateUtil } from "services/date-util";

interface Props {
  event: EventDto;
  eventLocked: boolean;
  importAttachments: any[];
}

const EventAttachmentDetails: React.FC<Props> = ({
  event,
  eventLocked,
  importAttachments,
}) => {
  const [attachments, setAttachments] = useState<EntityAttachmentDto[] | null>(
    null
  );
  const [thumbnails, setThumbnails] = useState<EntityAttachmentDto[]>([]);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<EntityAttachmentDto>(
    {} as EntityAttachmentDto
  );
  const [loading, setLoading] = useState(true);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);
  const [attachmentDescription, setAttachmentDescription] = useState("");
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!_.isEmpty(event)) {
      setThumbnails([]);
      getEntityAttachments("event", event.id).then((res) => {
        prepAndSetExistingFiles(res.data);
        setAttachments(res.data);
        setLoading(false);
      });
    }
  }, [event]);

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const prepAndSetExistingFiles = (files: EntityAttachmentDto[]) => {
    const newFiles = [...files];
    newFiles
      .filter(
        (x) =>
          x.thumbnailPath &&
          !thumbnails.some((y) => y.entityAttachmentId === x.entityAttachmentId)
      )
      .forEach((file) => {
        downloadThumbnail(file.entityAttachmentId).then((res) => {
          setThumbnails((existing) => [
            {
              fileName: file.fileName,
              thumbnail: res.data,
              entityAttachmentId: file.entityAttachmentId,
              description: file.description,
              thumbnailPath: file.thumbnailPath,
            },
            ...existing,
          ]);
        });
      });
  };

  const handleViewAttachment = (file: EntityAttachmentDto) => {
    setSelectedFile(file);
    setShowAttachmentDialog(true);
    if (file.isImport) {
      setAttachmentDescription(file.description || "");
    }
  };

  const handleDeleteAttachment = () => {
    deleteEntityAttachment(selectedFile.entityAttachmentId).then(() => {

      if (selectedFile.thumbnailPath) {
        setThumbnails(
          thumbnails.filter(
            (x) => x.entityAttachmentId !== selectedFile.entityAttachmentId
          )
        );
        setAttachments(
          attachments?.filter(
            (x) => x.entityAttachmentId !== selectedFile.entityAttachmentId
          ) || []
        );
      } else {
        setAttachments(
          attachments?.filter(
            (x) => x.entityAttachmentId !== selectedFile.entityAttachmentId
          ) || []
        );
      }
      showAlert("success", "Attachment deleted.");
    });
    setShowAttachmentDialog(false);
  };

  const handleDownloadFile = (id: number, fileName: string) => {
    downloadAttachment(id).then((res) => saveAs(res.data, fileName));
  };

  const handleDownloadImportFile = (id: number, fileName: string) => {
    downloadImportedAttachment(id).then((res) => saveAs(res.data, fileName));
  };

  const handleUploadClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files || [];
    if (!_.isEmpty(selectedFiles)) {
      if (validateFileSize(Array.from(selectedFiles))) {
        setAttachmentsUploading(true);
        addEntityAttachments("event", event.id, selectedFiles)
          .then(() => {
            getEntityAttachments("event", event.id).then((res) => {
              setTimeout(() => {
                setAttachmentsUploading(false);
                prepAndSetExistingFiles(res.data);
                setAttachments(res.data);
              }, 5000);
            });
          })
          .catch(() => {
            showAlert("error", "Error uploading attachments.");
            setAttachmentsUploading(false);
          });
      } else {
        setShowFileSizeError(true);
      }
    }
  };

  const handleSaveDescription = () => {
    const dto = { description: attachmentDescription };
    updateAttachmentDesciption(selectedFile.entityAttachmentId!, dto).then(
      () => {
        showAlert("success", "Attachment description updated.");
        setThumbnails((current) =>
          current.map((f) =>
            f.entityAttachmentId === selectedFile.entityAttachmentId
              ? { ...f, description: attachmentDescription }
              : f
          )
        );
      }
    );
  };

  const getFileIcon = (file: EntityAttachmentDto) => {
    if (file.contentType?.includes("video"))
      return <VideoFileIcon color="action" sx={{ fontSize: 150 }} />;
    if (file.contentType?.includes("audio"))
      return <AudioFileIcon color="action" sx={{ fontSize: 150 }} />;
    return <InsertDriveFileIcon color="action" sx={{ fontSize: 150 }} />;
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);
  const userRoleCanDelete = roleMatch([UserRoles.EventsAdministrator]);

  return (
    <div className="w-100 d-flex justify-content-center">
      <Card
        className="lynx-card"
        variant="outlined"
        sx={{ width: "100%", minHeight: loading ? "283px" : "" }}
      >
        <CardHeader
          title="Attachments"
          className="lynx-card-header"
          action={
            userRoleCanSave &&
            (eventLocked ? (
              <LockIcon color="disabled" fontSize="small" />
            ) : (
              <Button onClick={handleUploadClick}>Upload</Button>
            ))
          }
        />
        <CardContent>
          <Dimmer active={loading} loader>
            {_.isEmpty(attachments) && _.isEmpty(importAttachments) && (
              <div>No attachments</div>
            )}
            <ImageList cols={3} gap={8} rowHeight={150}>
              {importAttachments?.map((file, i) => (
                <ImageListItem key={i}>
                  <div>{getFileIcon(file)}</div>
                  <ImageListItemBar
                    title={file.fileName}
                    actionIcon={
                      <IconButton onClick={() => handleViewAttachment(file)}>
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
              {thumbnails.map((file) => (
                <ImageListItem key={file.entityAttachmentId}>
                  <img
                    src={`${URL.createObjectURL(
                      file.thumbnail as Blob | MediaSource
                    )}`}
                    loading="lazy"
                    alt="attachment"
                  />
                  <ImageListItemBar
                    title={file.fileName}
                    actionIcon={
                      <IconButton onClick={() => handleViewAttachment(file)}>
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
              {attachments
                ?.filter((x) => !x.thumbnailPath)
                .map((file) => (
                  <ImageListItem key={file.entityAttachmentId}>
                    <div>{getFileIcon(file)}</div>
                    <ImageListItemBar
                      title={file.fileName}
                      actionIcon={
                        <IconButton onClick={() => handleViewAttachment(file)}>
                          <InfoIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
            </ImageList>
          </Dimmer>
        </CardContent>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          onClick={handleInputClick}
          accept=".csv, .xlsx, .xls, .txt, image/*, .html, video/*, audio/*, .pdf, .doc, .docx, .ppt"
        />
      </Card>
      {showAttachmentDialog && !_.isEmpty(selectedFile) && (
        <LynxDialog
          open={showAttachmentDialog && !_.isEmpty(selectedFile)}
          handleClose={() => setShowAttachmentDialog(false)}
          handleDownload={() => {
            if (selectedFile.isImport) {
              handleDownloadImportFile(
                selectedFile.importHistoryId as number,
                selectedFile.fileName as string
              );
            } else {
              handleDownloadFile(
                selectedFile.entityAttachmentId as number,
                selectedFile.fileName as string
              );
            }
          }}
          handleDelete={
            selectedFile.isImport || !userRoleCanDelete
              ? null
              : handleDeleteAttachment
          }
          title={selectedFile.fileName}
          isCloseInHeader
          description={
            <Box sx={{ minWidth: 500 }}>
              <div className="mb-3">
                {selectedFile.thumbnail != null ? (
                  <img
                    src={`${URL.createObjectURL(selectedFile.thumbnail)}`}
                    loading="lazy"
                    key={"img" + _.toString(selectedFile.entityAttachmentId)}
                  />
                ) : (
                  getFileIcon(selectedFile)
                )}
              </div>
              <Box>
                <Form.Group label="Description">
                  <LynxTextArea
                    name="contactDetails"
                    disabled={selectedFile.isImport || !userRoleCanSave}
                    onChange={(e: any) =>
                      setAttachmentDescription(e.target.value)
                    }
                    value={
                      selectedFile.isImport
                        ? `File imported on ${dateUtil.convertDateTimeToLocal(
                            selectedFile.importDateTimeUtc
                          )} by ${selectedFile.uploaderUserFullName}.`
                        : attachmentDescription
                    }
                  ></LynxTextArea>
                </Form.Group>
              </Box>
            </Box>
          }
          handleSave={
            selectedFile.isImport || !userRoleCanSave
              ? null
              : handleSaveDescription
          }
        />
      )}
      <LynxDialog
        open={attachmentsUploading}
        title={`Uploading attachments. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
      {showFileSizeError && (
        <p className="text-danger">File size exceeds the limit</p>
      )}
    </div>
  );
};

export default EventAttachmentDetails;
