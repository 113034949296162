import * as React from "react";

import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import { getUsersLookups } from "../../../services/users";
import { getAssetLookups } from "../../../services/assets";
import { getInspectionForms } from "../../../services/inspection-form-service";
export const inspectionColumns = [
  {
    field: "inspectionNumber",
    headerName: "Inspection #",
    width: 100,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Asset",
    width: 100,
    type: "singleSelect",
    lookupFunction: getAssetLookups,
    valueField: "id",
    labelField: "name",
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    type: "singleSelect",
    valueOptions: ["Completed", "Draft", "Overdue"],
    renderCell: (params) => (
      <div className="event-status">
        <i
          className={"fe fe-circle "}
          style={{ color: statusColorSwitch(params.row) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "inspectionDate",
    headerName: "Date",
    width: 150,
    type: "date",
    valueGetter: (params) => {
      return dateUtil.convertDateOnlyToLocal(params.value);
    },
  },
  {
    field: "inspectionFormName",
    headerName: "Form",
    width: 300,
    type: "singleSelect",
    lookupFunction: getInspectionForms,
    lookupFunctionParams: { onlyInspectionForms: true },
    valueField: "id",
    labelField: "name",
  },
  {
    field: "equipmentName",
    headerName: "Equipment",
    width: 150,
    type: "string",
  },
  {
    field: "assignedToUserFullName",
    headerName: "Assigned To",
    width: 100,
    type: "singleSelect",
    lookupFunction: getUsersLookups,
    valueField: "id",
    labelField: "fullName",
  },

  {
    field: "completedDateTime",
    headerName: "Completed Date",
    width: 150,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "completedByUserFullName",
    headerName: "Completed By",
    width: 115,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
];
export const statusColorSwitch = (row) => {
  switch (_.toLower(row.status)) {
    case "completed":
      return lynxColors.primary;
    case "draft":
    case "incomplete":
      return lynxColors.harvestOrange;
    case "overdue":
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};
