import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import _ from "lodash";
import React, { FC } from "react";
import { EntityTypes } from "../../../types/enums";
import Review from "./review";
import { EventDto } from "types";

interface EventDetailsReviewProps {
  event: EventDto;
  setEventLocked: (locked: boolean, save: boolean) => void;
}

const EventDetailsReview: FC<EventDetailsReviewProps> = ({
  event,
  setEventLocked,
}) => {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Final Review" />
      <CardContent className="p-1 mb-2 mt-2">
        <Review
          setEventLocked={setEventLocked}
          isEventDetails
          entityType={EntityTypes.Event}
          entityId={event.id as number}
          hasPendingActions={_.toLower(event.status) === "pending actions"}
        />
      </CardContent>
    </Card>
  );
};

export default EventDetailsReview;
