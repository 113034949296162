import React from "react";
import { Layer, Source } from "react-map-gl";
export function MonitoringLocationsLayer(props) {
  return (
    <Source
      type="geojson"
      id="monitoring-locations"
      data={createLocationsGeojson(props.monitoringLocations)}
    >
      <Layer
        type="circle"
        id="internalMonitoringLocations"
        layout={{ visibility: "visible" }}
        paint={{ "circle-color": ["get", "color"], "circle-radius": 10 }}
      ></Layer>
    </Source>
  );
}
export function createLocationsGeojson(locations) {
  let geojson = {
    type: "FeatureCollection",
    features: locations.map((event) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [event.longitude, event.latitude],
      },
      properties: {
        ...event,
      },
    })),
  };

  return geojson;
}
