import React from "react";
import cn from "classnames";

interface Props extends React.HTMLProps<HTMLTableHeaderCellElement> {
  children?: React.ReactNode;
  className?: string;
  colSpan?: number;
  alignContent?: "left" | "center" | "right";
}

const TableColHeader: React.FC<Props> = ({
  className,
  children,
  colSpan,
  alignContent = "",
}) => {
  const classes = cn({ [`text-${alignContent}`]: alignContent }, className);

  return (
    <th className={classes} colSpan={colSpan}>
      {children}
    </th>
  );
};

export default TableColHeader;
