import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { getAssetLookups } from "../../../services/assets";
import { getLookups } from "../../../services/lookup";
import {
  setupInitialCustomFieldState,
  transformCustomFieldState,
} from "../../../services/custom-fields-service";
import {
  createPermit,
  deletePermit,
  getPermitById,
  patchPermit,
} from "../../../services/permit-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import {
  CustomFieldTypes,
  EntityTypes,
  UserRoles,
  LookupTypes,
} from "../../../types/enums";
import PageNotFound from "../../PageNotFound";
import { LynxDialog } from "../../lynx-dialog";
import { MultiSelect } from "../../../components/form-controls/multi-select";
import { validationService } from "./../../../services/validation";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useAlert from "hooks/useAlert";
import CustomField from "../events/custom-field";

export function PermitForm(props) {
  const initalFrom = {
    id: 0,
    assets: [],
    permitNumber: "",
    permitTypeId: "",
    startDate: "",
    expiryDate: "",
    appliedDate: "",
    circulatedDate: "",
    customFieldData: {},
  };

  const [formState, setFormState] = useState(initalFrom);
  const [initialFormState, setInitialFormState] = useState({});
  const [existingPermit, setExistingPermit] = useState({});
  const [assetLookups, setAssetLookups] = useState([]);
  const [permitSaving, setPermitSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [permitLoading, setPermitLoading] = useState(true);
  const [permitTypeLoading, setPermitTypeLoading] = useState(true);
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [permitTypes, setPermitTypes] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldState, setCustomFieldState] = useState({});
  const [existingCustomFieldValues, setExistingCustomFieldValues] =
    useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showExitWithoutSaving, setShowExitWithoutSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const dimensions = useWindowDimensions();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (
      !assetsLoading &&
      !permitLoading &&
      !permitTypeLoading &&
      !customFieldsLoading &&
      !_.isEmpty(formState)
    ) {
      setIsLoading(false);
    }
  }, [
    assetsLoading,
    permitLoading,
    permitTypeLoading,
    customFieldsLoading,
    formState,
  ]);

  useEffect(() => {
    if (isExistingPermit()) {
      if (!assetsLoading) {
        loadPermit();
      }
    } else {
      setPermitLoading(false);
    }
  }, [assetsLoading]);

  useEffect(() => {
    loadAssetLookups();
    loadCustomFields();
    loadPermitTypes();
  }, []);

  useEffect(() => {
    if (!isExistingPermit() && !_.isEmpty(customFields)) {
      let newCustomFieldState = setupInitialCustomFieldState(customFields);
      setCustomFieldState(newCustomFieldState);
    }

    if (
      isExistingPermit() &&
      !_.isEmpty(customFields) &&
      existingCustomFieldValues
    ) {
      let newCustomFieldState = setupInitialCustomFieldState(
        customFields,
        existingCustomFieldValues
      );

      setCustomFieldState(newCustomFieldState);
    }
  }, [customFields, props.match.params.id, existingCustomFieldValues]);

  useEffect(() => {
    if (!_.isEmpty(customFields)) {
      let newCustomFields = [
        ...customFields.filter((x) => x.parentLookupId == null),
      ];

      setSelectedCustomFields(newCustomFields);
    }
  }, [formState.id, customFields]);

  const loadAssetLookups = () => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
        setAssetsLoading(false);
      })
      .catch(() => {});
  };

  const loadPermitTypes = () => {
    getLookups(LookupTypes.PermitType)
      .then((res) => {
        setPermitTypes(res.data);
        setPermitTypeLoading(false);
      })
      .catch(() => {});
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.Permit)
      .then((res) => {
        let customFieldsToSet = res.data.filter((x) => !x.isDeleted);
        setCustomFields(customFieldsToSet);
        setCustomFieldsLoading(false);
      })
      .catch(() => {});
  };

  const loadPermit = () => {
    getPermitById(props.match.params.id)
      .then((res) => {
        setFormStateFromPermit(res.data);
        setExistingCustomFieldValues(res.data.eventCustomFieldValues);
        setExistingPermit(res.data);
        setPermitLoading(false);
      })
      .catch((err) => {
        setShowNotFound(true);
      });
  };

  const setFormStateFromPermit = (permit) => {
    var selectedAssets = [];
    permit.entityAssets.forEach((pa) => {
      var selectedAsset = assetLookups.filter((al) => al.id === pa.assetId)[0];
      if (selectedAsset) {
        selectedAssets.push(selectedAsset);
      }
    });

    let newState = {
      permitNumber: permit.permitNumber,
      assets: selectedAssets,
      permitTypeId: permit.permitTypeId,
      startDate: permit.startDate,
      expiryDate: permit.expiryDate,
      appliedDate: permit.appliedDate,
      circulatedDate: permit.circulatedDate,
    };

    setFormState(newState);
    setInitialFormState(newState);
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleCustomFieldChange = (e) => {
    let newState = { ...customFieldState };
    const name = e.target.name;
    const value = customFields
      .filter((x) => x.fieldType == CustomFieldTypes.Checkbox)
      .map((x) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : customFields
          .filter((x) => x.fieldType == CustomFieldTypes.MultiSelect)
          .map((x) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);

    setCustomFieldState(newState);
  };

  const isExistingPermit = () => {
    return props.match.params.id;
  };

  const handleExitWithoutSaving = () => {
    props.history.push("/permits");
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }

    let form = validationService.unsetErrors(
      formState,
      "assetsError",
      "permitNumberError",
      "permitTypeIdError"
    );

    var customFieldErrorsToUnset = customFields
      .filter((x) => x.isRequired)
      .map((field) => `${field.fieldName}Error`);

    var customFieldsToUnset = customFields
      .filter((x) => x.parentLookupId == null)
      .map((field) => `${field.fieldName}`);

    validationService.unsetErrors(
      customFieldState,
      ...customFieldErrorsToUnset
    );
    validationService.unsetFields(form, ...customFieldsToUnset);

    let formToSave = _.cloneDeep(form);

    let eventCustomFieldValues = transformCustomFieldState(
      customFields,
      customFieldState,
      formState.id
    );
    formToSave.permitFormFields = eventCustomFieldValues;

    var entityAssets = [];
    formState.assets.forEach((a) =>
      entityAssets.push({ assetId: a.id, permitId: formState.id })
    );
    formToSave.entityAssets = entityAssets;

    setPermitSaving(true);
    if (!isExistingPermit()) {
      createPermit(formToSave)
        .then((res) => {
          showAlert("success", "Permit created.");
          setPermitSaving(false);
          setExistingPermit(res.data);
          setFormStateFromPermit(res.data);
          props.history.push(`/permits/${res.data.id}`);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          setPermitSaving(false);
        });
    } else {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      var dtoToSave = {
        permitPatch: diff,
        permitFormFields: eventCustomFieldValues,
        entityAssets: entityAssets,
      };

      patchPermit(props.match.params.id, dtoToSave)
        .then((res) => {
          showAlert("success", "Permit saved.");
          setFormStateFromPermit(res.data);
          setPermitSaving(false);
          setExistingPermit(res.data);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          setPermitSaving(false);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let newCustomFieldState = { ...customFieldState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "assets",
      "assetsError",
      "Associated Assets"
    );

    validationService.validateRequiredField(
      newState,
      "permitNumber",
      "permitNumberError",
      "Permit Number"
    );

    validationService.validateRequiredField(
      newState,
      "permitTypeId",
      "permitTypeIdError",
      "Permit Type"
    );

    let errorFields = ["assetsError", "permitNumberError", "permitTypeIdError"];
    customFields
      .filter(
        (x) =>
          x.isRequired &&
          !x.isDeleted &&
          (_.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Dropdown) ||
            _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Text) ||
            _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.TextArea) ||
            _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.MultiSelect))
      )
      .forEach((value) => {
        validationService.validateRequiredField(
          newCustomFieldState,
          `${value.fieldName}.textValue`,
          `${value.fieldName}Error`,
          `${value.fieldLabel}`
        );
      });
    customFields
      .filter(
        (x) =>
          x.isRequired &&
          !x.isDeleted &&
          _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.DateTime)
      )
      .forEach((value) => {
        validationService.validateRequiredField(
          newCustomFieldState,
          `${value.fieldName}.dateTimeValue`,
          `${value.fieldName}Error`,
          `${value.fieldLabel}`
        );
      });
    customFields
      .filter(
        (x) =>
          x.isRequired &&
          !x.isDeleted &&
          _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Date)
      )
      .forEach((value) => {
        validationService.validateRequiredField(
          newCustomFieldState,
          `${value.fieldName}.dateValue`,
          `${value.fieldName}Error`,
          `${value.fieldLabel}`
        );
      });

    customFields
      .filter(
        (x) => _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Number)
      )
      .forEach((value) => {
        validationService.validateNumberField(
          newCustomFieldState,
          `${value.fieldName}.numericValue`,
          `${value.fieldName}Error`,
          value.isRequired,
          `${value.fieldLabel}`
        );
      });

    var customFieldErrorsToUnset = selectedCustomFields
      .filter((x) => x.isRequired)
      .map((field) => `${field.fieldName}Error`);

    isFormValid =
      !validationService.hasError(newState, ...errorFields) &&
      !validationService.hasError(
        newCustomFieldState,
        ...customFieldErrorsToUnset
      );

    if (!isFormValid) {
      setFormState(newState);
      setCustomFieldState(newCustomFieldState);
      showAlert("error", "Form is not valid for submitting.");
    }

    return isFormValid;
  };

  const deleteItem = () => {
    deletePermit(existingPermit.id).then(() => {
      showAlert("success", `Permit deleted.`);
      props.history.push("/permits");
    });
  };

  if (showNotFound) {
    return <PageNotFound message="Permit not found" />;
  } else
    return (
      <Grid>
        <Dimmer active={isLoading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography variant="h3" component="div">
                    {isExistingPermit()
                      ? existingPermit.permitNumber
                      : "Add Permit"}
                  </Typography>
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleNavigateTo(e, "/permits")}
                  >
                    Permits
                  </Link>
                  <Typography color="text.primary">
                    {isExistingPermit()
                      ? existingPermit.permitNumber
                      : "Add Permit"}
                  </Typography>
                </Breadcrumbs>
              </Grid.Col>
            </Grid.Row>
          </Paper>
          <Grid.Row>
            <Container className="form-container" maxWidth="xl">
              <Form className="card pt-2">
                <Grid.Col md={12} width={12}>
                  <Form.Group isRequired label="Associated Assets">
                    <MultiSelect
                      name="assets"
                      onChange={handleInputChange}
                      value={formState.assets}
                      dropdownValues={assetLookups}
                      key="assetId"
                      label="name"
                      id="assetId"
                      error={formState.assetsError}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group isRequired label="Permit Number">
                      <Form.Input
                        name="permitNumber"
                        onChange={handleInputChange}
                        value={formState.permitNumber}
                        error={formState.permitNumberError}
                        disabled={isExistingPermit()}
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group isRequired label="Permit Type">
                      <Form.Select
                        name="permitTypeId"
                        onChange={handleInputChange}
                        value={formState.permitTypeId}
                        error={formState.permitTypeIdError}
                      >
                        <option value={""}></option>
                        {permitTypes.map((elt) => (
                          <option value={elt.id} key={elt.id}>
                            {elt.code}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Start Date">
                      <Form.Input
                        type="date"
                        value={formState.startDate}
                        name="startDate"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Expiry Date">
                      <Form.Input
                        type="date"
                        value={formState.expiryDate}
                        name="expiryDate"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Applied Date">
                      <Form.Input
                        type="date"
                        value={formState.appliedDate}
                        name="appliedDate"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Circulated Date">
                      <Form.Input
                        type="date"
                        value={formState.circulatedDate}
                        name="circulatedDate"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                {!_.isEmpty(selectedCustomFields) && (
                  <Grid.Row>
                    {selectedCustomFields
                      .sort((a, b) => (a.position > b.position ? 1 : -1))
                      .filter((x) => !x.isDeleted)
                      .map((field) => (
                        <CustomField
                          customField={field}
                          key={field.position}
                          isRequired={field.isRequired}
                          handleInputChange={handleCustomFieldChange}
                          formState={customFieldState}
                        ></CustomField>
                      ))}
                  </Grid.Row>
                )}
                <Card.Footer>
                  <div class={dimensions.isMobile ? "" : "float-right"}>
                    <Button
                      className="mr-2"
                      variant="contained"
                      onClick={() => saveForm()}
                    >
                      Save
                    </Button>
                  </div>
                  <div class={dimensions.isMobile ? "mt-2" : "float-left"}>
                    <Button
                      variant="contained"
                      color="error"
                      className="mr-2"
                      onClick={() => setShowExitWithoutSaving(true)}
                    >
                      Exit Without Saving
                    </Button>
                    {roleMatch([UserRoles.Admin]) && isExistingPermit() && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Form>
            </Container>
          </Grid.Row>
          {permitSaving && (
            <LynxDialog
              open={permitSaving}
              title="Saving Permit"
              description={
                <>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <CircularProgress />
                  </div>
                </>
              }
            />
          )}
          <LynxDialog
            open={showExitWithoutSaving}
            handleConfirm={handleExitWithoutSaving}
            handleClose={() => setShowExitWithoutSaving(false)}
            title="Exit without saving?"
            description="Are you sure that you want to exit without saving your changes?"
          />

          <LynxDialog
            open={showDelete}
            handleClose={() => setShowDelete(false)}
            handleDelete={deleteItem}
            title="Delete Permit"
            description={`Are you sure that you want to delete Permit ${formState.permitNumber}?`}
          />
        </Dimmer>
      </Grid>
    );
}
