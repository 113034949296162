import { Typography } from "@mui/material";

export const mainTourSteps = [
  {
    target: ".sidebar",
    placementBeacon: "top",
    placement: "right",
    disableBeacon: true,
    title: "Use the Left Sidebar to Navigate",
    content:
      "The left sidebar is the primary way to navigate the Lynx Platform.",
  },
  {
    target: ".sidebar-assets",
    placementBeacon: "right",
    placement: "right",
    title: "Assets are your Operational Areas",
    content:
      "Assets are operational areas that we’ll attach events, incidents, and other types of data to. Add your company or department’s asset and its location here. You’ll also see your asset(s) on the map.",
  },
  {
    target: ".sidebar-events",
    placementBeacon: "right",
    placement: "right",
    title:
      "Use Events to manage your organization’s activities around the asset ",
    content:
      "Events are used to track a variety of data, including community engagement, emissions, inspections and any other event based data. Track events as they happen in and around the asset area in real time. Events will be logged in the events grid, and appear on the map.",
  },
  {
    target: ".sidebar-incidents",
    placementBeacon: "right",
    placement: "right",
    title:
      "Accidents happen. Track them and other unexpected occurrences using Incidents.",
    content:
      "Keep track of incidents over time using a reviewal process, corrective/preventative actions and investigations to reduce risk to your organization and community.",
  },
  {
    target: ".sidebar-actions",
    placementBeacon: "right",
    placement: "right",
    title: "Assign corrective and preventative actions to your team",
    content:
      "Associate actions to events or incidents to correct a past occurence or prevent it from happening again. Built in approval and verification workflows and notifications ensure actions are prioritized and completed on time.",
  },
  {
    target: ".sidebar-monitoring",
    placementBeacon: "right",
    placement: "right",
    title: "Monitoring the Environment",
    content:
      "Monitor water quality, air quality, blast noise and more over time with Monitoring. Monitoring results are automatically compared to the configured monitoring limits to ensure compliance.",
  },
  {
    target: ".sidebar-dashboard",
    placementBeacon: "right",
    placement: "right",
    title: "Visualize All of Your Data with the Dashboard",
    content:
      "Data from your Events, Incidents and Monitoring can be visualized using widgets. Pick from predefined system widgets, or customize your own bar, donut, or count list chart on the dashboard.",
  },
  {
    target: ".sidebar-map",
    placementBeacon: "right",
    placement: "right",
    title: "The Map - a High-Level View of your Assets and Events",
    content:
      "Visualize your assets, events, monitoring and more using the map.",
  },
  {
    target: ".sidebar-settings",
    placementBeacon: "right",
    placement: "right",
    title: "Customize your Experience with Settings",
    content:
      "Settings contain all the options you’ll need for customizing the look, feel and functionality of the Lynx Platform.",
  },
  {
    target: ".help-menu",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Need More Help?",
    content:
      "That's it! If you need more help, click the question mark on the top right of the menu bar to read the user guide or contact support at support@lynxglobalintelligence.com. Thanks for using the Lynx Platform.",
  },
];
