import AirIcon from "@mui/icons-material/Air";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import Typography from "@mui/material/Typography";
import React from "react";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import "../wyloo/wyloo-microsite.scss";
import { navigateTo } from "services/navigation-service";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { LynxDialog } from "components/lynx-dialog";
import { useHistory } from "react-router-dom";
export function WylooMenu(props) {
  const history = useHistory();
  const [showComingSoon, setShowComingSoon] = React.useState(false);
  return (
    <div className="wyloo-button-container">
      <div
        className="wyloo-menu-button mr-2"
        onClick={(e) => navigateTo(history, "/wyloo/water", e)}
      >
        <WaterDropIcon className="" sx={{ fontSize: 40 }} />
        <Typography>Water</Typography>
      </div>
      <div
        onClick={(e) => navigateTo(history, "/wyloo/air", e)}
        className="wyloo-menu-button ml-2 mr-2"
      >
        <AirIcon sx={{ fontSize: 40 }} />
        <Typography>Air</Typography>
      </div>
      <div
        className="wyloo-menu-button ml-2 mr-2"
        onClick={(e) => navigateTo(history, "/wyloo/wildlife", e)}
      >
        <PhotoCameraIcon sx={{ fontSize: 40 }} />
        <Typography>Wildlife</Typography>
      </div>
      <div
        className="wyloo-menu-button-disabled ml-2 mr-2"
        onClick={(e) => {
          setShowComingSoon(true);
        }}
      >
        <GraphicEqIcon sx={{ fontSize: 40 }} />
        <Typography>Noise</Typography>
      </div>
      <div
        className="wyloo-menu-button-disabled ml-2 "
        onClick={(e) => {
          setShowComingSoon(true);
        }}
      >
        <ThermostatIcon sx={{ fontSize: 40 }} />
        <Typography>Weather</Typography>
      </div>
      <LynxDialog
        title="Coming Soon!"
        open={showComingSoon}
        handleConfirm={() => setShowComingSoon(false)}
      />
    </div>
  );
}
