import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SecurityIcon from "@mui/icons-material/Security";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Dimmer, Grid } from "components/lynx-components";
import { saveAs } from "file-saver";
import _ from "lodash";
import { RootState } from "types";
import queryString from "query-string";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { canViewInvestigation } from "services/incident-assignment-service";
import { IncidentDto, ReportDto } from "types";
import lynxColors from "../../../modules/lynxColors";
import { getIncident } from "../../../services/incident-service";
import {
  getReports,
  runIncidentReport,
} from "../../../services/reports-service";
import { EntityTypes, IncidentStatus } from "../../../types/enums";
import PageNotFound from "../../PageNotFound";
import IncidentActions from "./incident-actions";
import IncidentAttachments from "./incident-attachments";
import IncidentAudit from "./incident-audit";
import IncidentFinalReview from "./incident-final-review";
import IncidentForm from "./incident-form";
import "./incident-form.css";
import IncidentInvestigation from "./incident-investigation";
import IncidentInvestigationParticipants from "./incident-investigation-participants";
import { LynxDialog } from "components/lynx-dialog";
import { CircularProgress } from "@mui/material";

interface RouteParams {
  id: string;
}

const IncidentContainer: React.FC = () => {
  const [incident, setIncident] = useState<IncidentDto | undefined>();
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const [view, setView] = useState<string>("incident");
  const [reports, setReports] = useState<ReportDto[]>([]);
  const history = useHistory();
  const location = useLocation();
  const account = useSelector((state: RootState) => state.account);
  const incidentStatus = incident ? _.toLower(incident.status) : "";
  const [size, setSize] = useState<[number, number]>([0, 0]);
  const targetRef = useRef<HTMLDivElement>(null);
  const [showDownloadingReport, setShowDownloadingReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reportsAnchorEl, setReportsAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const reportsOpen = Boolean(reportsAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setReportsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setReportsAnchorEl(null);
  };

  const params = useParams<RouteParams>();

  useEffect(() => {
    if (params.id) {
      loadIncident();

      const values = queryString.parse(location.search);
      if (!values.view) {
        history.push(`/incidents/${params.id}?view=incident`);
      }
    } else {
      setLoading(false);
    }
  }, [params.id, location.search]);

  useEffect(() => {
    const values = queryString.parse(location.search);
    setView(values.view?.toString() ?? "incident");
  }, [location]);

  useEffect(() => {
    if (account && incident) {
      loadReports();
      setLoading(false);
    }
  }, [account, incident]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (targetRef.current) {
        setSize([
          targetRef.current.offsetWidth,
          targetRef.current.offsetHeight,
        ]);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const isExistingIncident = (): boolean => {
    return !!params.id;
  };

  const loadReports = () => {
    getReports(EntityTypes.Incident).then((res) => {
      var reportsToSet = res.data as ReportDto[];
      if (!canViewInvestigation(incident, account.id)) {
        reportsToSet = reportsToSet.filter(
          (x) =>
            x.name != "Full Incident Report" &&
            x.name != "Incident Investigation Report"
        );
      }
      setReports(reportsToSet);
    });
  };

  const menuOptions = () => {
    let baseOptions = [{ label: "INITIAL REPORT", view: "incident" }];
    if (
      incident?.investigationRequired &&
      canViewInvestigation(incident, account.id)
    ) {
      baseOptions.push({ label: "INVESTIGATION", view: "investigation" });
    }
    baseOptions.push({ label: "ACTIONS", view: "actions" });
    if (showFinalReview()) {
      baseOptions.push({ label: "FINAL REVIEW", view: "final-review" });
    }
    baseOptions.push({ label: "ATTACHMENTS", view: "attachments" });
    return baseOptions;
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: { view: string }
  ) => {
    setView(item.view);
    setAnchorEl(null);
  };

  const loadIncident = () => {
    getIncident(params.id)
      .then((res) => {
        setIncident(res.data);
      })
      .catch(() => {
        setShowNotFound(true);
      });
  };

  const handleNavigateTo = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    history.push(url);
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setView(newValue);
    window.history.pushState({}, "", location.pathname + `?view=${newValue}`);
  };

  const showTabs = (): boolean => {
    if (!isExistingIncident()) return false;
    if (incidentStatus === IncidentStatus.PendingReview) return false;
    return size[0] >= 650;
  };

  const showMenu = (): boolean => {
    return size[0] < 650;
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const showFinalReview = (): boolean => {
    return [
      IncidentStatus.FinalReview,
      IncidentStatus.Closed,
      IncidentStatus.PendingActions,
    ].includes(incidentStatus);
  };

  const handleRunReport = (reportId: number) => {
    setShowDownloadingReport(true);
    handleClose();
    runIncidentReport(reportId, incident?.id).then((res) => {
      const reportToRun = reports.find((x) => x.id == reportId);
      saveAs(
        res.data,
        `${incident?.incidentNumber}_${reportToRun?.name?.replace(
          / /g,
          ""
        )}.pdf`
      );
      setShowDownloadingReport(false);
    });
  };

  if (showNotFound) {
    return <PageNotFound message="Incident not found" />;
  }

  return (
    <Grid>
      <div ref={targetRef}>
        <Dimmer active={loading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12}>
                <div className="d-flex">
                  <Typography variant="h3" component="div">
                    {isExistingIncident()
                      ? incident?.incidentNumber
                      : "New Incident Report"}
                  </Typography>
                  {isExistingIncident() && (
                    <>
                      <div className="align-self-center ml-5">
                        {incident && getStatusChip(incident, "medium")}
                      </div>
                      <div className="align-self-center ml-2 mt-3 mb-2">
                        <Button
                          variant="outlined"
                          aria-controls={reportsOpen ? "basic-menu" : undefined}
                          className="mb-1"
                          aria-haspopup="true"
                          aria-expanded={reportsOpen ? "true" : undefined}
                          onClick={handleClick}
                          startIcon={<DescriptionOutlinedIcon />}
                        >
                          Reports
                        </Button>

                        <Menu
                          id="basic-menu"
                          anchorEl={reportsAnchorEl}
                          open={reportsOpen}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {reports.map((report) => (
                            <MenuItem
                              key={report.id}
                              onClick={() =>
                                handleRunReport(report.id as number)
                              }
                            >
                              <ListItemIcon>
                                <SummarizeIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{report.name}</ListItemText>
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="mb-2 d-print-none"
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleNavigateTo(e, "/incidents")}
                  >
                    Incidents
                  </Link>
                  <Typography color="text.primary">
                    {isExistingIncident()
                      ? incident?.incidentNumber
                      : "New Incident Report"}
                  </Typography>
                </Breadcrumbs>
              </Grid.Col>
            </Grid.Row>
            {showMenu() && isExistingIncident() && (
              <Grid.Row>
                <Box
                  sx={{
                    width: "100%",
                    borderTop: `1px solid ${lynxColors.gray}`,
                  }}
                >
                  <List
                    component="nav"
                    aria-label="Device settings"
                    sx={{ bgcolor: "background.paper" }}
                  >
                    <ListItem
                      button
                      id="lock-button"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="when device is locked"
                      aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                      onClick={handleClickListItem}
                    >
                      <ListItemText
                        primary={
                          menuOptions().find((x) => x.view === view)?.label
                        }
                      />
                    </ListItem>
                  </List>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {menuOptions().map((option) => (
                      <MenuItem
                        key={option.view}
                        selected={view === option.view}
                        onClick={(event) => handleMenuItemClick(event, option)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid.Row>
            )}
            {showTabs() && (
              <Grid.Row>
                <Box
                  className="d-print-none"
                  sx={{
                    width: "100%",
                    borderTop: `1px solid ${lynxColors.gray}`,
                  }}
                >
                  <Tabs
                    value={view}
                    onChange={handleTabChange}
                    sx={{ height: "50px" }}
                  >
                    <Tab
                      label="Initial Report"
                      value="incident"
                      sx={{ height: "50px" }}
                    />
                    {incident?.investigationRequired && (
                      <Tab
                        label={"Investigation"}
                        icon={<SecurityIcon fontSize="small" />}
                        iconPosition="end"
                        value="investigation"
                        disabled={!canViewInvestigation(incident, account.id)}
                        sx={{ height: "50px", minHeight: "50px" }}
                      />
                    )}
                    {incident?.investigationRequired && (
                      <Tab
                        label={"Participants"}
                        icon={<SecurityIcon fontSize="small" />}
                        iconPosition="end"
                        value="participants"
                        disabled={!canViewInvestigation(incident, account.id)}
                        sx={{ height: "50px", minHeight: "50px" }}
                      />
                    )}
                    <Tab
                      label="Actions"
                      value="actions"
                      sx={{ height: "50px" }}
                    />
                    {showFinalReview() && (
                      <Tab
                        label="Final Review"
                        value="final-review"
                        disabled={!showFinalReview()}
                        sx={{ height: "50px" }}
                      />
                    )}
                    <Tab
                      label="Attachments"
                      value="attachments"
                      sx={{ height: "50px" }}
                    />
                    <Tab
                      label="Audit"
                      iconPosition="end"
                      icon={<SecurityIcon fontSize="small" />}
                      value="audit"
                      sx={{ height: "50px", minHeight: "50px" }}
                      disabled={!canViewInvestigation(incident, account.id)}
                    />
                  </Tabs>
                </Box>
              </Grid.Row>
            )}
          </Paper>
        </Dimmer>
        <Grid.Row>
          {view === "incident" &&
            (!_.isEmpty(incident) || !isExistingIncident()) && (
              <IncidentForm
                showAttachments={
                  !isExistingIncident() ||
                  incidentStatus === IncidentStatus.PendingReview
                }
                incident={incident}
                isExistingIncident={isExistingIncident()}
                updateIncident={setIncident}
              />
            )}
          {view === "actions" && !_.isEmpty(incident) && (
            <IncidentActions incident={incident} />
          )}
          {view === "investigation" &&
            !_.isEmpty(incident) &&
            incident.investigationRequired &&
            canViewInvestigation(incident, account.id) && (
              <IncidentInvestigation
                incident={incident}
                updateIncident={setIncident}
              />
            )}
          {view === "participants" &&
            !_.isEmpty(incident) &&
            incident.investigationRequired &&
            canViewInvestigation(incident, account.id) && (
              <IncidentInvestigationParticipants
                isLoading={loading}
                incident={incident}
                updateIncident={setIncident}
              />
            )}
          {view === "final-review" &&
            !_.isEmpty(incident) &&
            showFinalReview() && (
              <IncidentFinalReview
                incident={incident}
                updateIncident={setIncident}
              />
            )}
          {view === "attachments" && !_.isEmpty(incident) && (
            <IncidentAttachments incident={incident} />
          )}
          {view === "audit" && <IncidentAudit incident={incident} />}
        </Grid.Row>
      </div>
      {showDownloadingReport && (
        <LynxDialog
          open={showDownloadingReport}
          title={`Running Report. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
    </Grid>
  );
};

export const getStatusChip = (
  incident: IncidentDto,
  size: "small" | "medium"
) => {
  const status = _.toLower(incident.status);
  if (status === IncidentStatus.PendingReview) {
    return (
      <Chip
        label="Pending Review"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (status === IncidentStatus.Investigation) {
    return <Chip label="Investigation" size={size} color="primary" />;
  }
  if (status === IncidentStatus.FinalReview) {
    return (
      <Chip
        label="Final Review"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (status === IncidentStatus.PendingActions) {
    return (
      <Chip
        label="Pending Actions"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }

  return <Chip label="Closed" size={size} color="primary" />;
};

export default IncidentContainer;
