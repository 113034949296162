import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { FC } from "react";
import { EntityTypes } from "../../../types/enums";
import Tags from "./tags";
import { EventDto } from "types";

interface EventDetailsTagsProps {
  commentsLoading: boolean;
  event: EventDto;
}

const EventDetailsTags: FC<EventDetailsTagsProps> = ({
  commentsLoading,
  event,
}) => {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Tags" />
      <CardContent className="p-2">
        <Tags
          isLoading={commentsLoading}
          entityId={event.id?.toString() as string}
          entityType={EntityTypes.Event}
        />
      </CardContent>
    </Card>
  );
};

export default EventDetailsTags;
