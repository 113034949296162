import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useState } from "react";
import { Form, Grid } from "components/lynx-components";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export function EnergyLogDetailsParameters(props) {
  var organization = useSelector((state) => state.organization);
  const [detailsExpanded, setDetailsExpanded] = useState(true);

  return (
    <Accordion
      expanded={detailsExpanded}
      onChange={() => setDetailsExpanded(!detailsExpanded)}
      className="w-100"
    >
      <AccordionSummary
        aria-controls="details-content"
        id={`details-header"-${props.parameterType}`}
      >
        <Typography>{props.parameterType}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid>
          <Grid.Row>
            {_.sortBy(props.energyParameters).map((ep, i) => (
              <Grid.Col md={6} width={12} className="pl-0" key={i}>
                <Grid>
                  <Grid.Row>
                    <Grid.Col md={12} width={12}>
                      <Typography variant="subtitle1">
                        {ep.energyParameter.name} ({ep.energyParameter.unit})
                      </Typography>
                    </Grid.Col>
                    {organization.featureFlags.showInDevelopmentFeatures && (
                      <Grid.Col md={12} width={12}>
                        <Typography variant="subtitle2" component="span">
                          Previous Month: {ep.previousMonthValue}
                        </Typography>
                        <Link
                          href="#"
                          underline="none"
                          color="inherit"
                          onClick={() =>
                            props.handleEnergyParameterInputChange(
                              props.parameterType,
                              i,
                              "value",
                              ep.previousMonthValue
                            )
                          }
                        >
                          <Typography variant="caption" className="float-right">
                            Copy this
                          </Typography>
                        </Link>
                      </Grid.Col>
                    )}
                    <Grid.Col md={12} width={12}>
                      <Form.Group>
                        <Form.Input
                          type="number"
                          name={`${props.parameterType}_${i}_value`}
                          onChange={(e) =>
                            props.handleEnergyParameterInputChange(
                              props.parameterType,
                              i,
                              "value",
                              e.target.value
                            )
                          }
                          value={ep.value}
                        ></Form.Input>
                      </Form.Group>
                      <FormControl>
                        <RadioGroup
                          row
                          name={`${props.parameterType}_${i}_radio`}
                          value={ep.isEstimated}
                          onChange={(e) =>
                            props.handleEnergyParameterInputChange(
                              props.parameterType,
                              i,
                              "isEstimated",
                              e.target.value
                            )
                          }
                        >
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Actual"
                          />
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Estimate"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Grid.Col>
            ))}
          </Grid.Row>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
