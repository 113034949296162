import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import PageNotFound from "components/PageNotFound";
import useAlert from "hooks/useAlert";
import _ from "lodash";
import lynxColors from "modules/lynxColors";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { queryRagModelAi } from "services/admin";
import { RootState } from "types";
interface FormState {
  prompt?: string;
  organizationId?: string;
  domain?: string;
}
const initialForm: FormState = {
  prompt: "",
  organizationId: "51",
  domain: "Demo",
};
const AiPlayground: FC<{}> = (props) => {
  const [formState, setFormState] = useState(initialForm);
  const organization = useSelector((state: RootState) => state.organization);
  const { showAlert } = useAlert();
  const [response, setResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleSendResponse = () => {
    var requestBody = formState;
    setIsLoading(true);
    queryRagModelAi(requestBody)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        showAlert("error", "Error running query: " + err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  if (!organization.featureFlags?.showAiFeatures) return <PageNotFound />;
  else
    return (
      <Container>
        <Card className="mt-5">
          <Card.Header>
            <Card.Title>Ai Playground</Card.Title>
          </Card.Header>
          <Card.Body className="pt-2">
            <Grid>
              <Grid.Row className="mb-2">
                <Typography variant="h6">Request</Typography>
              </Grid.Row>
              <Box
                sx={{
                  border: `1px solid ${lynxColors.gray}`,
                  padding: "0.5rem",
                }}
              >
                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Domain">
                      <Form.Select
                        name="domain"
                        disabled
                        onChange={handleInputChange}
                        value={formState.domain}
                      >
                        <option value={"Demo"}>Demo</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={6} width={12}>
                    <Form.Group label="Organization ID">
                      <Form.Input
                        type="text"
                        name="organizationId"
                        disabled={true}
                        onChange={handleInputChange}
                        value={formState.organizationId}
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Prompt">
                      <LynxTextArea
                        name="prompt"
                        onChange={handleInputChange}
                        value={formState.prompt}
                      ></LynxTextArea>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Button
                      disabled={_.isEmpty(formState.prompt)}
                      variant="contained"
                      onClick={handleSendResponse}
                      className="float-right mb-2"
                    >
                      Send
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Box>
              <Grid.Row className="mb-2 mt-5">
                <Typography variant="h6">Response</Typography>
              </Grid.Row>{" "}
              <Dimmer active={isLoading} loader>
                <Box
                  sx={{
                    border: `1px solid ${lynxColors.gray}`,
                    padding: "0.5rem",
                    minHeight: "100px",
                  }}
                >
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Form.Group label="Answer">
                        <LynxTextArea
                          autoResize
                          value={response?.answer}
                        ></LynxTextArea>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={12}>
                      <Form.Group label="Sources">
                        <LynxTextArea
                          autoResize
                          value={JSON.stringify(response?.documentSources)}
                        ></LynxTextArea>
                      </Form.Group>{" "}
                    </Grid.Col>
                  </Grid.Row>
                </Box>
              </Dimmer>
            </Grid>
          </Card.Body>
        </Card>
      </Container>
    );
};

export default AiPlayground;
