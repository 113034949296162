import { getPermissionsForLookup } from "../../../services/users";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import _ from "lodash";
import { LookupTypes } from "../../../types/enums";
import { OrganizationUserLookupDto, RootState, UserDto } from "types";
import { useAppSelector } from "modules/hooks";
interface Props {
  lookupType: string;
  lookupId: number;
  refresh: boolean;
  setOrganizationUserLookups: (
    organizationUserLookups: OrganizationUserLookupDto[]
  ) => void;
}
export interface LookupPermissionDto {
  fullName?: string;
  email?: string;
  canAccess?: boolean;
  id?: number;
  organizationUserId?: number;
}
export function UsersPermissions(props: Props) {
  const [usersPermissionsData, setUsersPermissionsData] = useState<
    LookupPermissionDto[]
  >([]);
  const [userLookups, setUserLookups] = useState<
    OrganizationUserLookupDto[] | null
  >(null);
  const users = useAppSelector((state: RootState) => state.lookups.users);
  const organization = useAppSelector((state: RootState) => state.organization);
  useEffect(() => {
    if (!_.isEmpty(users) && userLookups != null && !organization.isLoading) {
      var permissions: LookupPermissionDto[] = [];
      users?.forEach((user) => {
        var orgUser = user.organizationUsers.find(
          (x) => x.organizationId == organization.id
        );
        permissions.push({
          fullName: user.fullName,
          email: user.email,
          canAccess: userLookups.some((x) => x.userId == user.id),
          id: user.id,
          organizationUserId: orgUser?.id,
        });
      });
      setUsersPermissionsData(permissions);
    }
  }, [users, userLookups, organization]);
  let defaultPermissionsColumns = [
    {
      field: "fullName",
      headerName: "Name",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      type: "string",
    },
    {
      field: "canAccess",
      headerName: "Can access",
      width: 125,
      type: "boolean",
      renderCell: (params: any) => (
        <div>
          <Checkbox
            checked={params.value}
            name="canAccess"
            onChange={(e) => handleUserPermissionChanged(e, params.row)}
            disabled={params.row.disabled}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getPermissionsForLookup(props.lookupType, props.lookupId).then((res) => {
      setUserLookups(res.data);
      props.setOrganizationUserLookups(res.data);
    });
  }, [props.refresh]);

  const getColumns = () => {
    let columns = [...defaultPermissionsColumns];

    return columns;
  };

  const handleUserPermissionChanged = (e: any, user: UserDto) => {
    const { name, checked } = e.target;

    let existingPermissions = [...usersPermissionsData];
    var permissionIndex = existingPermissions.findIndex((x) => x.id == user.id);
    _.set(existingPermissions[permissionIndex], name, checked);
    setUsersPermissionsData(existingPermissions);
    props.setOrganizationUserLookups(
      transformToOrgUserLookups(existingPermissions)
    );
  };

  const transformToOrgUserLookups = (permissions: LookupPermissionDto[]) => {
    var organizationUserLookups: OrganizationUserLookupDto[] = [];
    permissions.forEach((permission) => {
      if (permission.canAccess) {
        organizationUserLookups.push({
          userId: permission.id,
          lookupId: props.lookupId,
          organizationUserId: permission.organizationUserId,
        });
      }
    });
    return organizationUserLookups;
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGridPro
        rows={usersPermissionsData}
        columns={getColumns()}
        disableSelectionOnClick
      ></DataGridPro>
    </Box>
  );
}
