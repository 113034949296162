import Button from "@mui/material/Button";
import { roleMatch } from "actions/auth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EventCustomFieldDto, EventDto, RootState } from "types";
import { getCustomFields } from "../../../services/custom-fields-service";
import {
  bulkUpdateEventAttachments,
  bulkUpdateEvents,
  getEvents,
  getEventsAttachments,
  getEventsExportAsXLSX,
} from "../../../services/events";
import { getEventLookups } from "../../../services/lookup";
import { eventGridTourSteps } from "../../../tours/event-grid-tour";
import {
  EntityTypes,
  EventStatuses,
  TourTypes,
  UserRoles,
} from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import {
  eventStore,
  offlineDataService,
} from "./../../../services/offlineDataService";
import { LynxTour } from "./../../lynx-tour";
import { getEventColumns, latLongColumns, utmColumns } from "./event-columns";
import EventMobileCard from "./event-mobile-card";
import { tagParseToChip } from "services/helpers/tagParser";
import _ from "lodash";

const EventList = () => {
  const [eventCustomFields, setEventCustomFields] = useState<
    EventCustomFieldDto[]
  >([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const history = useHistory();
  const isOffline = !useOnlineStatus();

  const account = useSelector((state: RootState) => state.account);
  const organization = useSelector((state: RootState) => state.organization);
  const useUtm = account.coordinateType !== "LatLong";

  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users };

  useEffect(() => {
    getCustomFields(EntityTypes.Event)
      .then((res) => {
        const eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch(() => {
        setEventCustomFields([]);
      });
  }, []);

  useEffect(() => {
    if (isOffline) {
      offlineDataService.checkIfOfflineIsReady().then((res) => {
        setIsOfflineReady(res);
      });
    }
  }, [isOffline]);

  useEffect(() => {
    if (
      !_.isEmpty(eventCustomFields) &&
      !account.isLoading &&
      !organization.isLoading
    ) {
      let newColumns: any = getEventColumns(
        organization.hasPermitFeature,
        false,
        predefinedLookups,
        organization.hasDateOnlyEventDate
      );

      newColumns = [
        ...newColumns,
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      newColumns = useUtm
        ? [...newColumns, ...utmColumns]
        : [...newColumns, ...latLongColumns];

      setDefaultColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading, organization.isLoading]);

  const handleEventNumberClick = (row: EventDto) => {
    history.push(
      `/events/${row.id}${
        row.status?.toString() === EventStatuses.PendingEmailReview
          ? "?edit=true"
          : ""
      }`
    );
  };

  const setDefaultColumns = (columns: any[]) => {
    const newColumns = [...columns];
    const eventNumberIndex = newColumns.findIndex(
      (obj) => obj.field === "eventNumber"
    );

    if (eventNumberIndex > -1) {
      newColumns[eventNumberIndex].renderCell = (params: any) => (
        <Button
          variant="text"
          className="event-number-button"
          onClick={() => handleEventNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    }

    const descriptionIndex = newColumns.findIndex(
      (obj) => obj.field === "description"
    );

    if (descriptionIndex > -1) {
      newColumns[descriptionIndex].renderCell = (params: any) => (
        <>
          {params.value ? (
            <div>
              {tagParseToChip(params.value)}
            </div>
          ) : (
            "--"
          )}
        </>
      );
    }

    setColumns(newColumns);
  };

  const navigateToAddEvent = (isScheduledEvent: boolean) => {
    history.push(`/add-event${isScheduledEvent ? "?isScheduled=true" : ""}`);
  };

  const getTour = () => (
    <LynxTour
      title="Welcome to the Events Grid!"
      description="Events are used to track a variety of data, including community engagement, emissions, inspections, and any other types of event-based data. The event grid shows your events and provides a number of options to sort, filter, export, and report."
      steps={eventGridTourSteps}
      flagField="eventsGridTourCompletedDateTimeUtc"
      tourType={TourTypes.EventsGrid}
    />
  );

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
  ]);

  return (
    <>
      <LynxDataGrid
        tour={getTour}
        enableSavedFilters
        hideAddButton={!userRoleCanSave}
        columns={columns}
        getDataFunction={getEvents}
        indexDbObjectStoreName={eventStore}
        localStorageName="events"
        addButtonAction={navigateToAddEvent}
        entityName={EntityTypes.Event}
        customFields={eventCustomFields}
        indexDbSortColumn="eventNumber"
        enableReports
        isOfflineReady={isOfflineReady}
        getLookupsFunction={getEventLookups}
        mobileCard={EventMobileCard}
        handleExportAttachments={getEventsAttachments}
        handleEventsExportXLSX={getEventsExportAsXLSX}
        bulkUpdateFunction={bulkUpdateEvents}
        bulkUpdateAttachmentsFunction={bulkUpdateEventAttachments}
        searchEnabled
        searchLabel="Search By: Event #, Category, Subcategory, Description or Tags"
      />
    </>
  );
};

export default EventList;
