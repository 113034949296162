import React, { useState, useEffect } from "react";
import { Grid, Card, Form } from "components/lynx-components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";
import { getLookups } from "./../../../../services/lookup";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import { EditLookupModal } from "./../edit-lookup-modal";
import Button from "@mui/material/Button";
import {
  getCustomFields,
  updateCustomFieldSort,
} from "../../../../services/custom-fields-service";
import {
  getEnergyParameters,
  getParameterTypes,
  updateEnergyParameterSort,
} from "../../../../services/energy-service";
import { CustomFieldModal } from "../EventSettings/custom-field-modal";
import { energyTrackingCustomFieldColumns } from "./energy-tracking-custom-field-columns";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { EntityTypes, LookupTypes } from "../../../../types/enums";
import { EnergyParameterModal } from "./energy-parameter-modal";

export function EnergyTrackingSettings() {
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const energyParameterColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEnergyParameterEdit(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
    {
      field: "energyLogTypes",
      headerName: "Energy Log Types",
      width: 225,
      type: "string",
      valueGetter: (params) => {
        if (params.value.length > 0) {
          var codes = params.value.map((p) => {
            return p.lookup.code;
          });
          return codes.join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "cO2Factor",
      headerName: "CO2 Factor",
      width: 100,
      type: "number",
    },
    {
      field: "cH4Factor",
      headerName: "CH4 Factor",
      width: 100,
      type: "number",
    },
    {
      field: "n2OFactor",
      headerName: "N2O Factor",
      width: 100,
      type: "number",
    },
    {
      field: "biogeniceCO2Factor",
      headerName: "Biogenice CO2 Factor",
      width: 150,
      type: "number",
    },
    {
      field: "aR4",
      headerName: "AR4",
      width: 75,
      type: "number",
    },
    {
      field: "aR5",
      headerName: "AR5",
      width: 75,
      type: "number",
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 125,
      type: "string",
    },
    {
      field: "isRequired",
      headerName: "Is Required?",
      width: 100,
      type: "string",
    },
  ];

  const lookupColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleLookupEdit(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const [energyParameters, setEnergyParameters] = useState([]);
  const [energyTrackingCustomFields, setEnergyTrackingCustomFields] = useState(
    []
  );
  const [units, setUnits] = useState([]);
  const [energyLogTypes, setEnergyLogTypes] = useState([]);
  const [parameterTypes, setParameterTypes] = useState([]);
  const [selectedParameterType, setSelectedParameterType] = useState("Scope1");
  const [selectedEnergyParameter, setSelectedEnergyParameter] = useState({
    id: 0,
  });
  const [selectedLookup, setSelectedLookup] = useState({});
  const [selectedCustomField, setSelectedCustomField] = useState({});
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [showEnergyParameterModal, setShowEnergyParameterModal] =
    useState(false);

  const history = useHistory();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadParameterTypes();
    loadEnergyParameters(selectedParameterType);
    loadUnits();
    loadEnergyLogTypes();
    loadCustomFields();
  };

  const loadParameterTypes = () => {
    getParameterTypes().then((res) => {
      setParameterTypes(res.data);
    });
  };

  const loadEnergyParameters = (parameterType) => {
    getEnergyParameters(parameterType).then((res) => {
      setEnergyParameters(res.data);
    });
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.EnergyLog).then((res) => {
      setEnergyTrackingCustomFields(res.data);
    });
  };

  const loadUnits = () => {
    getLookups(LookupTypes.Unit).then((res) => {
      setUnits(res.data);
    });
  };

  const loadEnergyLogTypes = () => {
    getLookups(LookupTypes.EnergyLogType).then((res) => {
      setEnergyLogTypes(res.data);
    });
  };

  const handleEnergyParameterEdit = (e) => {
    setSelectedEnergyParameter(e);
    setShowEnergyParameterModal(true);
  };

  const handleEnergyParameterModalClose = () => {
    loadEnergyParameters(selectedParameterType);
    setSelectedEnergyParameter({ id: 0 });
    setShowEnergyParameterModal(false);
  };

  const handleEnergyParameterRowOrderChange = (e) => {
    updateEnergyParameterSort({
      entityId: e.row.id,
      newPosition: e.targetIndex + 1,
      entityType: selectedParameterType,
    });
    let newValues = [...energyParameters];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setEnergyParameters(newValues);
  };

  const handleParameterTypeChange = (e) => {
    setSelectedParameterType(e.target.value);
    loadEnergyParameters(e.target.value);
  };

  const handleCustomFieldRowOrderChange = (e) => {
    updateCustomFieldSort({
      entityId: e.row.id,
      newPosition: e.targetIndex + 1,
      entityType: EntityTypes.EnergyLog,
    });
    let newValues = [...energyTrackingCustomFields];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setEnergyTrackingCustomFields(newValues);
  };

  const handleCustomFieldModalClosed = (refresh) => {
    setShowCustomFieldModal(false);

    loadCustomFields();
    setSelectedCustomField({});
  };

  const handleCustomFieldEdit = (e) => {
    if (e.field == "edit") {
      setSelectedCustomField(
        energyTrackingCustomFields.find((x) => x.id == e.row.id)
      );
      setShowCustomFieldModal(true);
    }
  };

  const handleLookupEdit = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupModalSave = () => {
    loadUnits();
    loadEnergyParameters(selectedParameterType);
    loadEnergyLogTypes();
    handleLookupModalClose();
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Energy Tracking Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">
                Energy Tracking Settings
              </Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Energy Log Types
            </Typography>
            {/* <Typography variant="body2" className="mt-2" component="div">
              COME BACK TO
            </Typography> */}
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType={LookupTypes.EnergyLogType}
              lookupName={LookupTypes.EnergyLogType}
              handleAddLookup={() => loadEnergyLogTypes()}
            ></AddLookupHeader>
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={energyLogTypes}
                columns={lookupColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Energy Parameters
            </Typography>
            {/* <Typography variant="body2" className="mt-2" component="div">
              COME BACK TO
            </Typography> */}
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Form.Group label="Parameter Type">
            <Form.Select
              name="parameterType"
              value={selectedParameterType}
              onChange={handleParameterTypeChange}
            >
              {parameterTypes.map((type) => (
                <option value={type.item1} key={type.item1}>
                  {type.item2}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => setShowEnergyParameterModal(true)}
              >
                Add
              </Button>
            </Card.Header>
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={energyParameters}
                columns={energyParameterColumns}
                disableMultipleSelection={true}
                onRowOrderChange={handleEnergyParameterRowOrderChange}
                rowReordering
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Energy Tracking Form Additional Fields
            </Typography>
            {/* <Typography variant="body2" className="mt-2" component="div">
              COME BACK TO
            </Typography> */}
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => setShowCustomFieldModal(true)}
              >
                Add
              </Button>
            </Card.Header>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                columns={energyTrackingCustomFieldColumns}
                rows={energyTrackingCustomFields}
                onRowOrderChange={handleCustomFieldRowOrderChange}
                onCellClick={handleCustomFieldEdit}
                rowReordering
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>{" "}
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Units
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              A standard of measurement for a quantity on the Add Monitoring
              Event form (e.g., dB, mg/L).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType={LookupTypes.Unit}
              lookupName={LookupTypes.Unit}
              handleAddLookup={() => loadUnits()}
            ></AddLookupHeader>
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={units}
                columns={lookupColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showCustomFieldModal}
        onClose={() => {
          setShowCustomFieldModal(false);
          setSelectedCustomField({});
        }}
      >
        <CustomFieldModal
          handleModalClose={handleCustomFieldModalClosed}
          customField={selectedCustomField}
          entityType={EntityTypes.EnergyLog}
        ></CustomFieldModal>
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupModalSave}
          lookupName={selectedLookup.lookupType}
        />
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showEnergyParameterModal}
        onClose={handleEnergyParameterModalClose}
      >
        <EnergyParameterModal
          handleModalClose={handleEnergyParameterModalClose}
          entity={selectedEnergyParameter}
          parameterType={selectedParameterType}
        ></EnergyParameterModal>
      </Dialog>
    </Grid>
  );
}
