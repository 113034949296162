import * as React from "react";
import GridRow from "./grid-row";
import GridCol from "./grid-col";

type Props = {
  children: React.ReactNode;
};

const Grid: React.FC<Props> & {
  Row: typeof GridRow;
  Col: typeof GridCol;
} = ({ children }) => {
  return <>{children}</>;
};
Grid.Row = GridRow;
Grid.Col = GridCol;
export default Grid;
