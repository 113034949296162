import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { loggedIn, roleMatch } from "../actions/auth";
import queryString from "query-string";
import PageNotFound from "./PageNotFound";
import { UserRoles } from "types/enums";

function PrivateRoute({ component: Component, roles, path, ...rest }) {
  const redirectPath = {
    redirect: rest.location.pathname + rest.location.search,
  };
  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn() && !isPortalUser && roles === undefined) {
          return <Component {...props} />;
        }

        if (loggedIn() && !isPortalUser && roleMatch(roles)) {
          return <Component {...props} />;
        }
        if (loggedIn() && !isPortalUser && !roleMatch(roles)) {
          return <PageNotFound />;
        } else {
          return (
            <Redirect
              to={{
                pathname: isPortalUser ? "/wyloo" : `/authorize`,
                state: { from: props.location },
                search: isPortalUser
                  ? null
                  : `?${queryString.stringify(redirectPath)}`,
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;

export function RequireAuth({ children, roles }) {
  var location = useLocation();
  const redirectPath = { redirect: location.pathname };
  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;
  if (loggedIn() && !isPortalUser && roles === undefined) {
    return children;
  }

  if (loggedIn() && !isPortalUser && roleMatch(roles)) {
    return children;
  }
  if (loggedIn() && !isPortalUser && !roleMatch(roles)) {
    return <PageNotFound />;
  } else {
    return (
      <Redirect
        to={{
          pathname: isPortalUser ? "/wyloo" : `/authorize`,
          state: { from: location },
          search: isPortalUser
            ? null
            : `?${queryString.stringify(redirectPath)}`,
        }}
      />
    );
  }
}
