import React, { useEffect, useMemo, useState } from "react";

import { Card, Form, Grid, Icon } from "components/lynx-components";

import Box from "@mui/material/Box";
import _ from "lodash";
import { validationService } from "../../../services";
import Button from "@mui/material/Button";
import {
  createDepartment,
  deleteDepartment,
  updateDepartment,
} from "services/department-service";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import Checkbox from "@mui/material/Checkbox";
import { LynxDialog } from "components/lynx-dialog";
import { Stack, Typography } from "@mui/material";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";

const initialForm = {
  id: 0,
  name: "",
  nameError: "",
  description: "",
  departmentUsers: [],
};

export function DepartmentModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const isForEdit = useMemo(
    () => Boolean(props.selectedDepartment),
    [props.selectedDepartment]
  );
  const { showAlert } = useAlert();

  const userMemberColumns = [
    {
      field: "fullName",
      headerName: "Name",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      type: "string",
    },
    {
      field: "member",
      headerName: "Member",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const deptUser = formState.departmentUsers.find(
          (f) => f.userId == params.id
        );
        return (
          <Checkbox
            checked={Boolean(deptUser)}
            onChange={(e) => handleCheckMember(e, params.row)}
          />
        );
      },
    },
    {
      field: "isDepartmentHead",
      headerName: "Department Head",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const deptUser = formState.departmentUsers.find(
          (f) => f.userId == params.id
        );
        return (
          <Checkbox
            checked={Boolean(deptUser) && deptUser.isDepartmentHead}
            onChange={(e) => handleCheckHead(e, params.row)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (props.selectedDepartment) {
      setFormState({
        ...formState,
        id: props.selectedDepartment.id || 0,
        name: props.selectedDepartment.name,
        description: props.selectedDepartment.description,
        departmentUsers: props.selectedDepartment.departmentUsers,
      });
    }
  }, []);

  const handleFormDelete = () => {
    deleteDepartment(props.selectedDepartment.id)
      .then(() => {
        showAlert("success", "Department deleted.");
        props.handleModalClose();
        props.onSaveEffect();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const handleCheckMember = (e, user) => {
    const { checked } = e.target;
    let existingMembers = [...formState.departmentUsers];

    if (checked) {
      existingMembers.push({
        userId: user.id,
        isDepartmentHead: false,
      });
    } else {
      existingMembers = existingMembers.filter((f) => f.userId != user.id);
    }
    setFormState({ ...formState, departmentUsers: existingMembers });
  };

  const handleCheckHead = (e, user) => {
    const { checked } = e.target;

    let existingMembers = [...formState.departmentUsers];
    const index = existingMembers.findIndex((f) => f.userId == user.id);

    if (checked) {
      if (index != -1) {
        existingMembers[index].isDepartmentHead = true;
      } else {
        existingMembers.push({
          userId: user.id,
          isDepartmentHead: true,
        });
      }
    } else {
      if (index != -1) {
        const index = existingMembers.findIndex((f) => f.userId == user.id);
        existingMembers[index].isDepartmentHead = false;
      } else {
        existingMembers = existingMembers.filter((f) => f.userId != user.id);
      }
    }

    setFormState({ ...formState, departmentUsers: existingMembers });
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    isFormValid = !validationService.hasError(newState, "nameError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleFormSave = () => {
    if (!validateDataForSave()) {
      return;
    }

    let formToSave = validationService.unsetErrors(formState, "nameError");

    const operation = isForEdit
      ? updateDepartment(formToSave.id, formToSave)
      : createDepartment(formToSave);

    operation
      .then(() => {
        showAlert("success", "Department added.");
        props.handleModalClose();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      })
      .finally(() => props.onSaveEffect());
  };

  return (
    <Form className="card dsmodal-main lynx-modal-80">
      <Card.Body>
        <Card.Title>
          {`${isForEdit ? "Edit" : "Add"} Department`}
          <Icon
            name="x"
            onClick={() => props.handleModalClose()}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid.Row>
          <Grid.Col width={12}>
            <Form.Group label="Department Name" isRequired>
              <Form.Input
                type="text"
                name="name"
                onChange={handleInputChange}
                value={formState.name}
                error={formState.nameError}
              ></Form.Input>
            </Form.Group>
            <Form.Group label="Description">
              <LynxTextArea name="description" onChange={handleInputChange}>
                {formState.description}
              </LynxTextArea>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Users">
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGridPro
                  rows={props.userList}
                  columns={userMemberColumns}
                  disableSelectionOnClick
                ></DataGridPro>
              </Box>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        <Stack
          direction="row"
          justifyContent="end"
          spacing={1}
          alignItems="center"
        >
          {Boolean(props.selectedDepartment) &&
            props.selectedDepartment.isInUse && (
              <Typography as="span" fontSize={14} className="text-red">
                Unable to delete when in use
              </Typography>
            )}
          {Boolean(props.selectedDepartment) && (
            <Button
              disabled={props.selectedDepartment.isInUse}
              variant="contained"
              color="error"
              onClick={() =>
                !props.selectedDepartment.isInUse && setIsDeleteFormOpen(true)
              }
            >
              Delete
            </Button>
          )}
          <Button
            variant="contained"
            className="float-right"
            onClick={handleFormSave}
          >
            Save
          </Button>
        </Stack>
      </Card.Footer>
      <LynxDialog
        title={`Delete Department`}
        description={"Are you sure you want to delete this item?"}
        open={isDeleteFormOpen}
        handleClose={() => setIsDeleteFormOpen(false)}
        buttons={() => {
          return (
            <Button
              variant="contained"
              color="error"
              onClick={!props.selectedDepartment.isInUse && handleFormDelete}
            >
              Yes
            </Button>
          );
        }}
      />
    </Form>
  );
}
