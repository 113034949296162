import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState, FC } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../../services/date-util";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { EventDto } from "types";

interface EventDetailsLinkedEventsProps {
  event: EventDto;
  handleAddLinkedEvent: (isScheduled: boolean) => void;
  isLoading: boolean;
}

const EventDetailsLinkedEvents: FC<EventDetailsLinkedEventsProps> = ({
  event,
  handleAddLinkedEvent,
  isLoading,
}) => {
  const [linkedEvents, setLinkedEvents] = useState<EventDto[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const eventMenuOpen = Boolean(anchorEl);
  const history = useHistory();

  useEffect(() => {
    let allEvents = [event];
    if (!_.isEmpty(event.linkedEvents)) {
      allEvents = [...(event.linkedEvents ?? []), ...allEvents];
    }

    setLinkedEvents(
      allEvents.sort((a, b) => {
        return (
          new Date(
            b.eventDateTime ||
              dateUtil.convertDateOnlyToLocal(b.eventDueDateTime)
          ).getTime() -
          new Date(
            a.eventDateTime ||
              dateUtil.convertDateOnlyToLocal(a.eventDueDateTime)
          ).getTime()
        );
      })
    );
  }, [event.linkedEvents, event]);

  const getDotColor = (eventStatus: string) => {
    switch (_.toLower(eventStatus)) {
      case "pending review":
      case "pending actions":
        return "secondary";
      case "incomplete (overdue)":
        return "error";
      case "incomplete":
        return "success";
      default:
        return "primary";
    }
  };

  const handleEventAddClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEventMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEventNumberClick = (e: React.MouseEvent, eventItem: any) => {
    e.preventDefault();
    history.push(`/events/${eventItem.id}`);
  };

  const userRoleCanAddLinkedEvent = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
  ]);

  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader
        className="lynx-card-header"
        title="Linked Events"
        action={
          <>
            {userRoleCanAddLinkedEvent && (
              <Button
                id="basic-button"
                aria-controls={eventMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={eventMenuOpen ? "true" : undefined}
                onClick={handleEventAddClick}
              >
                Add Linked Event
              </Button>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={eventMenuOpen}
              onClose={handleEventMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleAddLinkedEvent(false);
                  handleEventMenuClose();
                }}
              >
                Add Event
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAddLinkedEvent(true);
                  handleEventMenuClose();
                }}
              >
                Schedule Event
              </MenuItem>
            </Menu>
          </>
        }
      />

      <CardContent>
        <Timeline sx={{ align: "left" }} className="mb-0 p-0">
          {!isLoading &&
            linkedEvents.map((eventItem) => (
              <TimelineItem key={eventItem.id}>
                <TimelineOppositeContent style={{ flex: 0.1 }}>
                  <div style={{ width: "100px" }}>
                    {eventItem.eventDateTime
                      ? moment(eventItem.eventDateTime)
                          .utc()
                          .format("YYYY-MM-DD")
                      : moment
                          .utc(
                            dateUtil.convertDateOnlyToLocal(
                              eventItem.eventDueDateTime
                            )
                          )
                          .format("YYYY-MM-DD")}
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    color={
                      event.id === eventItem.id
                        ? undefined
                        : getDotColor(eventItem.status as string)
                    }
                  >
                    <EventOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div className="d-flex">
                    <div>
                      Event&nbsp;
                      {eventItem.id === event.id ? (
                        `#${eventItem.eventNumber}`
                      ) : (
                        <Link
                          underline="hover"
                          href="#"
                          onClick={(e) => handleEventNumberClick(e, eventItem)}
                        >
                          #{eventItem.eventNumber}
                        </Link>
                      )}
                      &nbsp;
                      {`(${eventItem.eventCategory}${
                        eventItem.eventSubCategory
                          ? ` - ${eventItem.eventSubCategory}`
                          : ``
                      })`}
                      &nbsp;
                      {eventItem.eventDateTime ? "completed." : "is due."}
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default EventDetailsLinkedEvents;
