import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form } from "components/lynx-components";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { validationService } from "./../../../services/validation";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { IncidentParticipantDto, LookupDto } from "types";
import _ from "lodash";
interface ParticipantModalProps {
  open: boolean;
  handleClose: () => void;
  handleUpdate: (participant: IncidentParticipantDto) => void;
  handleAdd: (participant: IncidentParticipantDto) => void;
  participant: IncidentParticipantDto | null;
  participantTypes: LookupDto[];
  isWitness?: boolean;
}

const initialForm: IncidentParticipantDto & {
  nameError?: string;
  participantTypeIdError?: string;
} = {
  name: "",
  nameError: "",
  details: "",
  participantTypeId: undefined,
  participantTypeIdError: "",
};

const ParticipantModal: React.FC<ParticipantModalProps> = (props) => {
  const [formState, setFormState] = useState(initialForm);

  useEffect(() => {
    if (props.participant) {
      setFormStateFromProps(props.participant);
    }
  }, [props.participant]);

  const isExistingParticipant = (): boolean => {
    return !_.isEmpty(props.participant);
  };

  const setFormStateFromProps = (participant: IncidentParticipantDto) => {
    setFormState({
      name: participant.name,
      details: participant.details,
      participantTypeId: participant.participantTypeId,
      id: participant.id,
      newId: participant.newId,
      nameError: "",
      participantTypeIdError: "",
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getEntityName = (): string => {
    return props.isWitness ? "Witness" : "Affected Party";
  };

  const addParticipant = () => {
    if (!validateDataForSave()) {
      return;
    }
    const formToSave = validationService.unsetErrors(
      formState,
      "nameError",
      "participantTypeIdError"
    );
    if (isExistingParticipant()) {
      props.handleUpdate(formToSave);
    } else {
      props.handleAdd(formToSave);
    }
  };

  const validateDataForSave = (): boolean => {
    const newState = { ...formState };
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    validationService.validateRequiredField(
      newState,
      "participantTypeId",
      "participantTypeIdError",
      "Type"
    );
    const isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "participantTypeIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
    }
    return isFormValid;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Form className="card dsmodal-main lynx-modal">
        <Card.Header>
          <Card.Title>
            {isExistingParticipant()
              ? `Edit ${getEntityName()}`
              : `Add ${getEntityName()}`}
          </Card.Title>
          <Icon
            name="x"
            onClick={props.handleClose}
            className="ml-auto pointer"
          />
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Name" isRequired>
                <Form.Input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  value={formState.name}
                  error={formState.nameError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Type" isRequired>
                <Form.Select
                  name="participantTypeId"
                  onChange={handleInputChange}
                  value={formState.participantTypeId}
                  error={formState.participantTypeIdError}
                >
                  <option value={""}></option>
                  {props.participantTypes.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Contact Details">
                <LynxTextArea
                  name="details"
                  onChange={handleInputChange}
                  value={formState.details}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="contained"
            className="float-right"
            onClick={addParticipant}
          >
            {isExistingParticipant()
              ? `Save ${getEntityName()}`
              : `Add ${getEntityName()}`}
          </Button>
        </Card.Footer>
      </Form>
    </Dialog>
  );
};

export default ParticipantModal;
