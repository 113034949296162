import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
  backgroundURL?: string;
};

const CardHeader: React.FC<Props> = ({
  className,
  children,
  backgroundURL = "",
}) => {
  const classes = cn("card-header", className);

  return (
    <div
      className={classes}
      style={
        backgroundURL
          ? {
              backgroundImage: `url(${backgroundURL})`,
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};

export default CardHeader;
