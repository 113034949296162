import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import SyncIcon from "@mui/icons-material/Sync";
import { Stack } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
} from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import localforage from "localforage";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Form, Grid } from "components/lynx-components";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getContact, getContactGroup } from "../../services/contacts";
import {
  convertFilterDefToQueryString,
  deleteFilter,
  getFilters,
  saveFilter,
} from "../../services/filter-service";
import { localStorageService } from "../../services/local-storage-service";
import { getMultipleLookups } from "../../services/lookup";
import { getMonitoringLocation } from "../../services/monitoring-location-service";
import {
  getReports,
  runEventsReport,
  runIncidentsReport,
} from "../../services/reports-service";
import { EntityTypes, UserRoles } from "../../types/enums";
import CustomFilterPanel from "../CustomFilterPanel";
import { LynxDialog } from "../lynx-dialog";
import "./LynxDataGrid.css";
import { betweenDateOperator } from "./between-date-filter-operator";
import { betweenDateTimeOperator } from "./between-datetime-filter-operator";
import { BulkUpdate } from "./bulk-update";
import { CustomToolbar } from "./custom-toolbar";
import { DataGridSearch } from "./datagrid-search";
import { FilterModal } from "./filter-modal";
import { rangeOfDateOperator } from "./range-of-date-filter-operator";
import { ReportsModal } from "./reports-modal";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
export function LynxDataGrid(props) {
  const defaultFilterModel = { items: [] };
  const filterStorageName = props.localStorageName + "_filter";
  const columnsStorageName = props.localStorageName + "_columns";
  const filterIdStorageName = props.localStorageName + "_filterId";
  const sortStorageName = props.localStorageName + "_sort";
  const paginationStorageName = props.localStorageName + "_pagination";
  const gridViewStorageName = `${props.localStorageName}_gridView`;
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const [allLookups, setAllLookups] = useState({});
  const history = useHistory();
  const location = history.location;
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [data, setData] = useState([]);
  const [reportDownloading, setReportDownloading] = useState(false);
  const [pageNumber, setPageNumber] = useState(null);
  const [showReportError, setShowReportError] = useState(false);
  const [pageSize, setPageSize] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState(null);
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [lookupValues, setLookupValues] = useState([]);
  const [filterModel, setFilterModel] = useState(defaultFilterModel);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterChipLabel, setFilterChipLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(0);
  const isOffline = !useOnlineStatus();
  const [selectedRow, setSelectedRow] = useState({});
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filtersLoading, setFilterLoading] = useState(true);
  const [isLoadFilterMode, setIsLoadFilterMode] = useState(false);
  const [showDeleteFilter, setShowDeleteFilter] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [isScheduledEvent, setIsScheduledEvent] = useState(false);
  const [showResetColumnsDialog, setShowResetColumnsDialog] = useState(false);
  const [offlineMessageOpen, setOfflineMessageOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExportingXlsx, setIsExportingXlsx] = useState(false);
  const [exportError, setExportError] = useState(null);
  const [showFollowUpDialog, setShowFollowUpDialog] = useState(false);
  const [defaultEntityModalValues, setDefaultEntityModalValues] = useState({});
  const [linkedEntityId, setLinkedEntityId] = useState(0);
  const [size, setSize] = useState([0, 0]);
  const [showReportsModal, setShowReportsModal] = useState(false);
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [reports, setReports] = useState([]);
  const [gridView, setGridView] = useState({});
  const [eventCategories, setEventCategories] = useState([]);
  const [eventSubCategories, setEventSubCategories] = useState([]);
  const [qsValues, setQsValues] = useState(null);
  const [initialFilterModelSet, setInitialFilterModelSet] = useState(false);
  const [exportParams, setExportParams] = useState({});
  const [addingBulkAttachments, setAddingBulkAttachments] = useState(false);
  const [gridViewOptions, setGridViewOptions] = useState([
    {
      catId: null,
      subCatId: null,
      cat: null,
      subCat: null,
      sort: "All",
      label: "All Events",
    },
  ]);
  const [columnVisibilityModel, setColumnVisabilityModel] = useState({});
  const [isExportingAttachments, setIsExportingAttachments] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const eventMenuOpen = Boolean(anchorEl);
  const dimensions = useWindowDimensions();
  const targetRef = React.useRef();
  const isMobileCardView =
    dimensions && dimensions.isMobile && props.mobileCard;
  const { showAlert } = useAlert();
  //3. After the filters are done loading, load the grid data
  useEffect(() => {
    if (!props.isLoading) {
      if (
        sortParams != null &&
        pageNumber != null &&
        pageSize != null &&
        initialFilterModelSet
      ) {
        if (props.enableSavedFilters) {
          if (!filtersLoading || isOffline) {
            loadGridData();
          }
        } else {
          loadGridData();
        }
      }
    }
  }, [
    searchValue,
    pageNumber,
    sortParams,
    pageSize,
    props.getDataFunction,
    isOffline,
    gridView,
    filtersLoading,
    props.isLoading,
    initialFilterModelSet,
    location.search,
    props.data,
  ]);

  useEffect(() => {
    handleSetFilterChipLabel();
  }, []);

  useEffect(() => {
    if (searchParams.get("bulkUpdate")) {
      setShowBulkUpdate(true);
    } else {
      setShowBulkUpdate(false);
    }
  }, [search]);

  //1. Set lookups based on column def
  useEffect(() => {
    if (!_.isEmpty(props.columns)) {
      let newColumns = [...props.columns];
      if (props.getLookupsFunction) {
        setupLookups();
      } else {
        let lookupTypes = newColumns
          .filter((x) => x.type == "singleSelect" && x.lookupType)
          .map((x) => ({ type: x.lookupType, field: x.field }));
        let lookupArray = [];
        if (!_.isEmpty(lookupTypes)) {
          let lookupString = lookupTypes.map((x) => x.type).join(",");

          getMultipleLookups(lookupString)
            .then((res) => {
              for (const [key, value] of Object.entries(res.data)) {
                lookupArray.push({
                  field: lookupTypes.find((x) => x.type == key).field,
                  values: value.map((x) => x.code),
                });
              }
              setLookupValues((values) => [...lookupArray, ...values]);
            })
            .catch((err) => {
              showAlert("error", err.response.data.message);
            });
        }

        newColumns.forEach((column) => {
          if (
            column.type == "singleSelect" &&
            column.lookupFunction &&
            column.valueField &&
            column.labelField
          ) {
            column.lookupFunction(column.lookupFunctionParams).then((res) => {
              let valueOptions = res.data.map(
                (data) => data[column.labelField]
              );
              lookupArray.push({
                field: column.field,
                values: valueOptions,
              });
              setLookupValues((values) => [
                { field: column.field, values: valueOptions },
                ...values,
              ]);
            });
          }
        });
      }
    }
  }, [props.columns]);

  //1. setup columns once lookups are set
  useEffect(() => {
    //setup columns
    if (
      !_.isEmpty(props.columns) &&
      (!_.isEmpty(lookupValues) || hasNoLookups() || isOffline)
    ) {
      setupColumns(false);
    }
  }, [lookupValues, props.columns, isOffline]);
  //update qs values anytime they change
  useEffect(() => {
    const qsToSet = queryString.parse(location.search);
    setQsValues(qsToSet);
  }, [location.search]);

  //prep event grid view options
  useEffect(() => {
    if (!_.isEmpty(eventCategories)) {
      var optionsToAdd = eventCategories.map((c) => ({
        catId: c.id,
        subCatId: null,
        cat: c.code,
        subCat: null,
        sort: c.code,
        label: c.code,
      }));
      setGridViewOptions((prev) => [...optionsToAdd, ...prev]);
    }
  }, [eventCategories]);

  useEffect(() => {
    if (!_.isEmpty(eventCategories) && !_.isEmpty(eventSubCategories)) {
      var optionsToAdd = eventSubCategories.map((s) => ({
        catId: eventCategories.find((x) => x.id == s.parentLookupId).id,
        subCatId: s.id,
        cat: eventCategories.find((x) => x.id == s.parentLookupId).code,
        subCat: s.code,
        sort:
          eventCategories.find((x) => x.id == s.parentLookupId).code +
          " - " +
          s.code,
        label:
          eventCategories.find((x) => x.id == s.parentLookupId).code +
          " - " +
          s.code,
      }));
      setGridViewOptions((prev) => [...optionsToAdd, ...prev]);
    }
  }, [eventCategories, eventSubCategories]);

  React.useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;

      setSize([
        width,
        targetRef.current.offsetHeight - (props.onlyShowTable ? 0 : 70),
      ]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  //set initial filter values
  useEffect(() => {
    const search = queryString.parse(location.search);
    if (search && search.filterId && search.filterId > 0 && !filtersLoading) {
      let filter = savedFilters.find((x) => x.id == qsValues.filterId);
      if (filter != null) {
        let filterDef = JSON.parse(filter.filterDef);

        setFilterModel(filterDef);
        localStorageService.setLocalStorage(filterIdStorageName, filter.id);
        localStorageService.setLocalStorage(filterStorageName, filterDef);
        setSelectedFilter(filter);
        //TO DO warn if filter does not exist
      }
      setInitialFilterModelSet(true);
      return;
    }

    const lsFilterId = localStorageService.getLocalStorage(filterIdStorageName);
    if (lsFilterId && lsFilterId > 0) {
      let filter = savedFilters.find((x) => x.id == lsFilterId);
      if (filter != null) {
        let filterDef = JSON.parse(filter.filterDef);

        setFilterModel(filterDef);
        localStorageService.setLocalStorage(filterStorageName, filterDef);
        setSelectedFilter(filter);
        var qsObjet = { filterId: lsFilterId };
        if (search.view) {
          qsObjet = { ...qsObjet, view: search.view };
        }
        history.replace({
          search: queryString.stringify(qsObjet),
        });
        //TO DO warn if filter does not exist
      }
      setInitialFilterModelSet(true);
      return;
    }

    if (search.varianceStatus && !filtersLoading) {
      let filterToSet = {
        items: [
          {
            columnField: "varianceStatus",
            operatorValue: "is",
            id: 82373,
            value: "Exceedance",
          },
        ],
      };
      setFilterModel(filterToSet);
      localStorageService.setLocalStorage(filterStorageName, filterToSet);
      setInitialFilterModelSet(true);
      return;
    }

    if (!search.filterId && (!filtersLoading || !props.enableSaveFilters)) {
      var filterModelString =
        localStorageService.getLocalStorage(filterStorageName);
      if (filterModelString && _.isObject(filterModelString)) {
        setInitialFilterModelSet(true);
        setFilterModel(filterModelString);
      } else {
        setInitialFilterModelSet(true);
        setFilterModel(defaultFilterModel);
        localStorageService.setLocalStorage(filterStorageName, "");
      }
    }
  }, [filtersLoading]);

  //2. After qs values are initialy set, load the filters and set from either qs or ls
  useEffect(() => {
    if (props.enableSavedFilters && !isOffline) {
      loadFilters(true);
    } else {
      setInitialFilterModelSet(true);
    }
    if (props.enableReports) {
      loadReports();
    }
  }, [isOffline]);

  //set state from ls on initial load
  useEffect(() => {
    //load grid view from ls
    if (_.toLower(props.entityName) == _.toLower(EntityTypes.Event)) {
      let gridViewObject =
        localStorageService.getLocalStorage(gridViewStorageName);
      if (gridViewObject && !_.isEmpty(gridViewObject)) {
        setGridView(gridViewObject);
      } else {
        setGridView({
          catId: null,
          subCatId: null,
          cat: null,
          subCat: null,
          sort: "All",
          label: "All Events",
        });
      }
    }

    //load sort from ls
    let sortObject = localStorageService.getLocalStorage(sortStorageName);
    if (!sortObject) {
      setSortParams([]);
    } else {
      setSortParams(sortObject);
    }

    //load pagination from ls
    let pageObject = localStorageService.getLocalStorage(paginationStorageName);
    if (!pageObject) {
      setPageNumber(0);
      setPageSize(20);
    } else {
      setPageNumber(pageObject.pageNumber);
      setPageSize(pageObject.pageSize);
    }
  }, []);

  const setupLookups = () => {
    props.getLookupsFunction().then((res) => {
      setAllLookups(res.data);
      let lookupArray = [];
      let newColumns = [...props.columns];
      let lookupTypes = newColumns
        .filter((x) => x.type == "singleSelect" && x.lookup)
        .map((x) => ({
          type: x.lookup,
          field: x.field,
          valueField: x.valueField,
          labelField: x.labelField,
          lookupValues: x.lookupValues,
        }));

      //handle column lookupValues
      lookupTypes.map((lookup) => {
        if (lookup.lookupValues) {
          //if column has lookup values then set lookup from it.
          lookupArray.push({
            field: lookup.field,
            values: lookup.lookupValues.map((m) => m[lookup.labelField]),
          });
        }
      });

      for (const [key, value] of Object.entries(res.data)) {
        var object = JSON.parse(value);
        if (_.toLower(key) == "eventcategory") {
          setEventCategories(object);
        }
        if (_.toLower(key) == "eventsubcategory") {
          setEventSubCategories(object);
        }
        var lookupTypeMatches = lookupTypes.filter(
          (x) => _.toLower(x.type) == _.toLower(key)
        );

        for (const lookupType of lookupTypeMatches) {
          if (lookupType) {
            lookupArray.push({
              field: lookupType.field,
              values: lookupType.labelField
                ? object.map((x) => x[lookupType.labelField])
                : object.map((x) => x.code),
            });
          }
        }
      }

      setLookupValues(lookupArray);
    });
  };

  const loadReports = () => {
    getReports(props.entityName).then((res) => {
      var reportsToSet = res.data;
      if (props.entityName == EntityTypes.Incident) {
        if (!roleMatch([UserRoles.Admin])) {
          reportsToSet = reportsToSet.filter(
            (x) =>
              x.name != "Full Incident Report" &&
              x.name != "Incident Investigation Report"
          );
        }
      }
      setReports(reportsToSet);
    });
  };

  const loadFilters = () => {
    setFilterLoading(true);
    getFilters(props.entityName)
      .then((res) => {
        setSavedFilters(res.data);
        setFilterLoading(false);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const hasNoLookups = () => {
    if (props.hasNoLookups) {
      return true;
    }
    switch (_.toLower(props.entityName)) {
      case "event":
      case "audit log":
      case "monitoring location":
      case "kpi":
      case "region":
      case "kpihistory":
      case "user":
      case "action":
      case "incident":
      case "energylog":
      case "permit":
        return true;
      default:
        return false;
    }
  };

  const handleEventAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEventMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSetFilterChipLabel = () => {
    const qs = queryString.parse(history.location.search);
    if (qs.monitoringLocationId && qs.monitoringLocationId > 0) {
      getMonitoringLocation(qs.monitoringLocationId).then((res) => {
        setFilterChipLabel(res.data.name);
      });
    }
    if (qs.contactId && qs.contactId > 0) {
      getContact(qs.contactId).then((res) => {
        setFilterChipLabel(res.data.fullName);
      });
    }
    if (qs.contactGroupId && qs.contactGroupId > 0) {
      getContactGroup(qs.contactGroupId).then((res) => {
        setFilterChipLabel(res.data.name);
      });
    }
  };

  const setupColumns = (resetToDefault = false) => {
    let newColumns = _.cloneDeep(props.columns);
    if (props.hasEditButton) {
      let selectionColumn = {};

      selectionColumn = {
        field: "Actions",
        headerName: "",
        filterable: false,
        sortable: false,
        width: props.hasEditButton && props.hasDashboardButton ? 100 : 50,
        type: "actions",
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        hideSortIcons: true,
        renderCell: (params) => {
          return (
            <>
              {props.hasEditButton && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      props.handleEditClick
                        ? props.handleEditClick(params.id)
                        : handleEditClick(
                            props.passEntityOnClick ? params.row : params.id
                          );
                    }}
                    aria-label="Edit"
                    size="small"
                    disabled={isOffline}
                    className="grid-edit-button"
                  >
                    {params.row.notSynced ? (
                      <SyncIcon color="error" />
                    ) : (
                      <EditIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      };

      if (!_.isEmpty(selectionColumn)) {
        newColumns = [selectionColumn, ...newColumns];
      }
    }
    newColumns.forEach((col, index) => {
      //create entityNumber
      if (col.type == "entityNumber") {
        newColumns[index] = {
          ...col,
          type: "string",
          renderCell: (params) => {
            return (
              <>
                <Button
                  variant="text"
                  onClick={() => handleEditClick(params.id)}
                >
                  {params.value}
                </Button>
              </>
            );
          },
        };
      }
      //remove is any of from numbers
      if (col.type == "number") {
        newColumns[index] = {
          ...col,
          filterOperators: getGridNumericOperators().filter(
            (operator) => operator.value !== "isAnyOf"
          ),
        };
      }

      //add floating date ranges to date
      if (col.type == "date") {
        newColumns[index] = {
          ...col,
          filterOperators: [
            ...getGridDateOperators(),
            ...rangeOfDateOperator,
            ...betweenDateOperator,
          ],
        };
      }

      if (col.type == "dateTime") {
        newColumns[index] = {
          ...col,
          filterOperators: [
            ...getGridDateOperators(true),
            ...rangeOfDateOperator,
            ...betweenDateTimeOperator,
          ],
        };
      }
      //assign lookup values
      if (col.type == "singleSelect") {
        let values = lookupValues.find((b) => b.field == col.field);
        if (values) {
          newColumns[index] = {
            valueOptions: values.values,
            ...col,
          };
        }
      }
      if (isOffline) {
        newColumns[index].sortable = false;
      } else {
        newColumns[index].sortable =
          newColumns[index].sortable === false ? false : true;
      }
    });

    setAllColumns(newColumns);
    updateColumnsForGridView(gridView, resetToDefault, newColumns);
  };

  const loadGridData = () => {
    setLoading(true);
    let params = {
      search: searchValue,
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      ...props.getDataParams,
    };
    if (!_.isEmpty(sortParams)) {
      params = {
        sort: sortParams[0].field + " " + sortParams[0].sort,
        ...params,
      };
    }
    const qs = queryString.parse(history.location.search);
    var skipFilter = false;
    if (qs.groupBy) {
      params = { groupBy: qs.groupBy, ...params };
    }

    if (
      qs.importHistoryId &&
      qs.importHistoryId > 0 &&
      props.entityName != "photoImportHistory"
    ) {
      params = { importHistoryId: qs.importHistoryId, ...params };
      if (!qs.varianceStatus) {
        skipFilter = true;
      }
    }
    if (qs.monitoringLocationId && qs.monitoringLocationId > 0) {
      params = {
        monitoringLocationId: qs.monitoringLocationId,
        ...params,
      };
      skipFilter = true;
    }
    if (qs.contactId && qs.contactId > 0) {
      params = {
        contactId: qs.contactId,
        ...params,
      };
      skipFilter = true;
    }
    if (qs.contactGroupId && qs.contactGroupId > 0) {
      params = {
        contactGroupId: qs.contactGroupId,
        ...params,
      };
      skipFilter = true;
    }

    if (
      gridView.sort != "All" &&
      !qs.importHistoryId &&
      !qs.groupBy &&
      !qs.monitoringLocationId
    ) {
      if (gridView.catId) {
        params = { eventCategoryId: gridView.catId, ...params };
      }
      if (gridView.subCatId) {
        params = { eventSubCategoryId: gridView.subCatId, ...params };
      }
    }
    if (!skipFilter) {
      let filterStringLS =
        localStorageService.getLocalStorage(filterStorageName);

      if (filterStringLS && props.localStorageName) {
        var filterQueryString = convertFilterDefToQueryString(filterStringLS);

        if (filterQueryString) {
          params = { filter: filterQueryString, ...params };
        }
      }
    } else {
      setFilterModel(defaultFilterModel);
      localStorageService.setLocalStorage(filterStorageName, "");
      localStorageService.setLocalStorage(filterIdStorageName, 0);
    }
    setExportParams(params);
    if (props.getDataFunction) {
      props
        .getDataFunction(params)
        .then((res) => {
          var data = res.data;
          const paging = JSON.parse(res.headers.pagination);
          setTotalItems(paging.totalItems);
          if (props.indexDbObjectStoreName) {
            localforage.setItem(props.indexDbObjectStoreName, res.data);
          }
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          if (props.indexDbObjectStoreName) {
            localforage.getItem(props.indexDbObjectStoreName).then((res) => {
              setData(_.orderBy(res, [props.indexDbSortColumn], ["desc"]));
              setTotalItems(res.length);
              setLoading(false);
            });
          } else {
            showAlert("error", err.response.data.message);
          }
        });
    }
    if (props.data) {
      setData(props.data);
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    if (props.passEntityOnClick) {
      setSelectedEntity(id);
    } else {
      setSelectedDataId(id);
    }

    setOpen(true);
  };

  const handleSortModelChange = (sort, e) => {
    setSortParams(sort);
  };

  const handleFilterChange = (filterDef) => {
    setFilterModel(filterDef);
    localStorageService.setLocalStorage(filterStorageName, filterDef);
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick" && selectedDataId <= 0) return;
    handleClose(false);
  };

  const handleViewDialogClose = (event, reason) => {
    handleViewClose(false);
  };

  const handleViewClose = (refreshData) => {
    setViewOpen(false);
    if (refreshData == true) {
      loadGridData();
    }
  };

  const handleClose = (refreshData) => {
    setOpen(false);
    setLinkedEntityId(0);
    setDefaultEntityModalValues({});
    setSelectedDataId(0);
    setSelectedEntity({});
    if (refreshData == true) {
      if (props.handleRefresh) {
        if (props.getDataFunction) {
          loadGridData();
        }
        props.handleRefresh();
      } else {
        loadGridData();
      }
    }
  };

  const handleAdd = (isScheduled) => {
    setSelectedDataId(0);
    if (
      props.isOfflineReady == null ||
      !isOffline ||
      (props.isOfflineReady != null &&
        props.isOfflineReady == true &&
        isOffline)
    ) {
      props.entityModal ? setOpen(true) : props.addButtonAction(isScheduled);
    } else {
      setOfflineMessageOpen(true);
    }
  };

  const handleSaveFilter = () => {
    setIsLoadFilterMode(false);
    setShowFilterModal(true);
  };

  const onFilterSave = (filterName, existingFilterId) => {
    let model = _.cloneDeep(filterModel);
    let items = model.items;
    items = items.filter(
      (x) =>
        x.value ||
        x.operatorValue == "isEmpty" ||
        x.operatorValue == "isNotEmpty"
    );
    model.items = items;
    let filterModelToSave = JSON.stringify(model);

    let dto = {
      entityType: props.entityName,
      name: filterName,
      filterDef: filterModelToSave,
      id: existingFilterId,
    };
    saveFilter(dto)
      .then((res) => {
        setShowFilterModal(false);
        const qsToSet = { filterId: res.data.id };
        history.replace({ search: queryString.stringify(qsToSet) });
        localStorageService.setLocalStorage(filterIdStorageName, res.data.id);
        setSelectedFilter(res.data);
        showAlert("success", "Filter saved.");
        loadFilters();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const handleLoadFilter = () => {
    setIsLoadFilterMode(true);
    setShowFilterModal(true);
  };

  const handleLoadFilterClose = () => {
    setShowFilterModal(false);
    setIsLoadFilterMode(false);
  };

  const handleLoadFilterSelected = (filterId) => {
    setShowFilterModal(false);
    setIsLoadFilterMode(false);
    const qsToSet = { filterId: filterId };
    history.replace({ search: queryString.stringify(qsToSet) });
    localStorageService.setLocalStorage(filterIdStorageName, filterId);
    var filter = savedFilters.find((x) => x.id == filterId);
    setSelectedFilter(filter);
    handleFilterChange(JSON.parse(filter.filterDef));
    const search = queryString.parse(location.search);
    var qsObjet = { filterId: filter.id };
    if (search.view) {
      qsObjet = { ...qsObjet, view: search.view };
    }
    history.replace({
      search: queryString.stringify(qsObjet),
    });
    if (pageNumber == 0) {
      loadGridData();
    } else {
      setPageNumber(0);
    }
  };

  const handleDeleteFilter = () => {
    setShowDeleteFilter(false);
    deleteFilter(selectedFilter.id)
      .then(() => {
        showAlert("success", "Filter deleted.");
        resetGridParamsAndReload();
        loadFilters();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  const resetGridParamsAndReload = () => {
    localStorageService.setLocalStorage(filterIdStorageName, 0);
    setSelectedFilter({});
    localStorageService.setLocalStorage(filterStorageName, "");
    setFilterModel(defaultFilterModel);
    let qsObject = queryString.parse(location.search);
    if (qsObject.view) {
      history.replace({
        search: queryString.stringify({ view: qsObject.view }),
      });
    } else {
      history.replace({ search: "" });
    }
    loadGridData(defaultFilterModel);
  };

  const showDeleteConfirmation = () => {
    setShowDeleteFilter(true);
  };

  const getColumnLocalStorageName = (gridViewValue = null) => {
    if (_.toLower(props.entityName) == "event") {
      if (!_.isEmpty(gridView)) {
        let gridViewToSet = gridView.sort.replace(/\s/g, "");
        if (gridViewValue != null) {
          gridViewToSet = gridViewValue.sort.replace(/\s/g, "");
        }
        return `${columnsStorageName}_${gridViewToSet}`;
      }
    }
    return columnsStorageName;
  };

  const handleColumnWidthChange = (e) => {
    if (props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        getColumnLocalStorageName()
      );
      if (columnDefString) {
        let colDef = JSON.parse(columnDefString);
        let colIndex = colDef.findIndex((x) => x.field == e.colDef.field);
        colDef[colIndex].width = e.width;
        localStorageService.setLocalStorage(
          getColumnLocalStorageName(),
          JSON.stringify(colDef)
        );
      }
    }
  };

  const handleColumnOrderChange = (e) => {
    if (props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        getColumnLocalStorageName()
      );

      if (columnDefString) {
        let colDef = JSON.parse(columnDefString);
        let newValues = [...colDef];
        let oldValue = newValues.splice(e.oldIndex, 1);
        newValues.splice(e.targetIndex, 0, ...oldValue);
        localStorageService.setLocalStorage(
          getColumnLocalStorageName(),
          JSON.stringify(newValues)
        );
      }
    }
  };

  const handleColumnVisibilityModelChange = (e) => {
    setColumnVisabilityModel(e);
    if (!loading && props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        getColumnLocalStorageName()
      );
      if (columnDefString) {
        let colDef = JSON.parse(columnDefString);
        colDef.forEach((col) => {
          if (e[col.field] != undefined) {
            col.enabled = e[col.field];
          } else {
            col.enabled = true;
          }
        });

        localStorageService.setLocalStorage(
          getColumnLocalStorageName(),
          JSON.stringify(colDef)
        );
      }
    }
  };

  const handleResetColumns = () => {
    setupColumns(true);
    setShowResetColumnsDialog(false);
    showAlert("success", "Columns reset.");
  };

  const handleShowResetColumnDialog = () => {
    setShowResetColumnsDialog(true);
  };

  const handleFollowUpRequired = (form, id) => {
    setDefaultEntityModalValues(form);
    setLinkedEntityId(id);
    setIsScheduledEvent(true);
    setShowFollowUpDialog(true);
  };

  const handleClearFilter = () => {
    setShowFilterModal(false);
    resetGridParamsAndReload();
    loadGridData();
  };

  const handleReportsClick = () => {
    setShowReportsModal(true);
  };

  const handleRunReport = (reportId) => {
    if (totalItems > 1000) {
      setShowReportError(true);
      return;
    }
    setReportDownloading(true);
    if (props.entityName == EntityTypes.Incident) {
      const reportParams = { ...exportParams, pageSize: totalItems };
      runIncidentsReport(reportId, reportParams)
        .then((res) => {
          const reportToRun = reports.find((x) => x.id == reportId);
          saveAs(res.data, `Incidents-${reportToRun.name?.replace(/ /g, "")}`);
          setReportDownloading(false);
        })
        .catch(async (err) => {
          var errorString = JSON.parse(await err.response.data.text());

          showAlert("error", errorString.message);
          setReportDownloading(false);
        });
    }
    if (props.entityName == EntityTypes.Event) {
      const reportParams = { ...exportParams, pageSize: totalItems };
      runEventsReport(reportId, reportParams)
        .then((res) => {
          const reportToRun = reports.find((x) => x.id == reportId);
          saveAs(res.data, `Events-${reportToRun.name?.replace(/ /g, "")}`);
          setReportDownloading(false);
        })
        .catch(async (err) => {
          var errorString = JSON.parse(await err.response.data.text());
          showAlert("error", errorString.message);
          setReportDownloading(false);
        });
    }
  };

  const handleExportAttachments = () => {
    setIsExportingAttachments(true);

    props
      .handleExportAttachments(exportParams)
      .then((res) => {
        if (res.data.size > 0) {
          saveAs(res.data, "Lynx Attachments.zip");
        } else {
          showAlert("error", "No attachments available to export.");
        }
        setIsExportingAttachments(false);
      })
      .catch((err) => {
        setIsExportingAttachments(false);
      });
  };

  const handleExportAsXLSX = () => {
    setIsExportingXlsx(true);
    let gridViewValue =
      localStorageService.getLocalStorage(gridViewStorageName);
    let savedColDef = localStorageService.getLocalStorage(
      getColumnLocalStorageName(gridViewValue)
    );

    let colDef = {};
    if (savedColDef) {
      colDef = JSON.parse(savedColDef);
      colDef.forEach(
        (item) =>
          (item.headerName = props.columns.find(
            (column) => column.field == item.field
          ).headerName)
      );
    }

    props
      .handleEventsExportXLSX(exportParams, colDef)
      .then((res) => {
        if (res.data.size > 0) {
          saveAs(res.data, "Lynx ".concat(props.entityName).concat(" Export"));
          setIsExportingXlsx(false);
        } else {
          showAlert("error", "No records to export.");
        }
      })
      .catch(async (err) => {
        var errorString = JSON.parse(await err.response.data.text());
        setIsExportingXlsx(false);
        setExportError(errorString.message);
      });
  };

  const handleRefresh = () => {
    loadGridData();
  };

  const handlePageChange = (e) => {
    setPageNumber(e);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleFilterModelChange = (e) => {
    localStorageService.setLocalStorage(filterIdStorageName, 0);
    setSelectedFilter({});
    handleFilterChange(e);
    loadGridData(e);
  };

  useEffect(() => {
    if (pageNumber !== null && pageSize !== null) {
      localStorageService.setLocalStorage(paginationStorageName, {
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
    }
  }, [pageNumber, pageSize, paginationStorageName]);

  useEffect(() => {
    if (sortParams !== null && props.localStorageName) {
      localStorageService.setLocalStorage(sortStorageName, sortParams);
    }
  }, [sortParams, sortStorageName]);

  const getDataGridCard = () => {
    if (isMobileCardView) {
      return getMobileCards();
    } else {
      return (
        <Card className={`mb-0 ${props.noBorder ? "no-border" : ""}`}>
          <div
            style={
              !props.autoHeight
                ? {
                    height: size[1],
                    width: "100%",
                  }
                : {}
            }
          >
            {!showBulkUpdate ? (
              <DataGridPro
                className="lynx-data-grid"
                autoHeight={props.autoHeight}
                pagination={!isOffline}
                rows={data}
                columns={columns}
                pageSize={pageSize}
                disableMultipleSelection={true}
                disableColumnFilter={isOffline}
                paginationMode={props.getDataFunction ? "server" : "client"}
                page={pageNumber}
                loading={loading}
                filterMode="server"
                filterModel={filterModel}
                rowCount={totalItems}
                getRowId={(row) => props.getRowId ? row[props.getRowId] : row.id}
                sortingMode={props.getDataFunction ? "server" : "client"}
                disableMultipleColumnsSorting
                disableSelectionOnClick={true}
                disableColumnPinning
                onSortModelChange={handleSortModelChange}
                sortModel={sortParams ?? []}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                onRowDoubleClick={props.handleRowDoubleClick}
                onColumnOrderChange={handleColumnOrderChange}
                onColumnWidthChange={handleColumnWidthChange}
                onColumnVisibilityModelChange={
                  handleColumnVisibilityModelChange
                }
                onFilterModelChange={handleFilterModelChange}
                columnVisibilityModel={columnVisibilityModel}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                components={{
                  Toolbar: props.disableToolbar ? null : CustomToolbar,
                  FilterPanel: CustomFilterPanel,
                }}
                componentsProps={{
                  filterPanel: {
                    filterModel,
                    handleSaveFilter,
                    selectedFilter,
                    handleLoadFilter,
                    showDeleteConfirmation,
                    enableSaveFilters: props.enableSavedFilters,
                  },
                  toolbar: {
                    filterModel,
                    handleLoadFilter,
                    isOffline,
                    handleShowResetColumnDialog,
                    handleClearFilter,
                    enableSaveFilters: props.enableSavedFilters,
                    handleReportsClick,
                    handleRefresh,
                    getDataFunction: props.getDataFunction,
                    entityName: props.entityName,
                    getQueryParamDisplay,
                    handleExportAttachments,
                    handleBulkUpdate,
                    bulkUpdateFunction: props.bulkUpdateFunction,
                    handleExportAsXLSX: props.handleEventsExportXLSX
                      ? handleExportAsXLSX
                      : null,
                    enableReports: props.enableReports,
                  },
                }}
              />
            ) : (
              <BulkUpdate
                totalItems={totalItems}
                columns={columns}
                lookups={allLookups}
                postBulkUpdate={postBulkUpdate}
                handleUploadAttachments={handleUploadAttachments}
              />
            )}
          </div>
        </Card>
      );
    }
  };
  const handleUploadAttachments = (files) => {
    setAddingBulkAttachments(true);
    props.bulkUpdateAttachmentsFunction(exportParams, files).then((res) => {
      loadGridData();
      setAddingBulkAttachments(false);
      showAlert("success", "Attachments added to events.");
      setShowBulkUpdate(false);
    });
  };
  const postBulkUpdate = (data) => {
    props.bulkUpdateFunction(exportParams, data).then((res) => {
      loadGridData();
      showAlert("success", "Bulk update complete.");
      setShowBulkUpdate(false);
    });
  };
  const handleBulkUpdate = () => {
    setShowBulkUpdate(true);
    searchParams.set("bulkUpdate", "true");
    history.push({ search: searchParams.toString() });
    //history.push(`/monitoring?view=${_.toLower(selectedGridView)}`);
    // props.bulkUpdateFunction().then((res) => {});
  };
  const updateColumnsForGridView = (
    gridViewValue,
    resetToDefault = false,
    originalColumns = null
  ) => {
    let newColumns = [];

    let allColumnsToFilter = originalColumns ?? allColumns;
    //set new columns
    if (gridViewValue.sort != "All" && _.toLower(props.entityName) == "event") {
      const standardColumns = allColumnsToFilter.filter(
        (x) => !x.eventCategoryId && !x.eventSubCategoryId
      );
      let nonStandardColumns = [];
      if (gridViewValue.subCatId == null) {
        nonStandardColumns = allColumnsToFilter.filter(
          (x) => x.eventCategoryId == gridViewValue.catId
        );
      } else {
        nonStandardColumns = allColumnsToFilter.filter(
          (x) =>
            x.eventCategoryId == gridViewValue.catId &&
            x.eventSubCategoryId == gridViewValue.subCatId
        );
      }

      newColumns = [...standardColumns, ...nonStandardColumns];
    } else {
      newColumns = [...allColumnsToFilter];
    }

    //assign sort, enabled columns, and column size
    let columnVModel = {};
    let savedColDef = localStorageService.getLocalStorage(
      getColumnLocalStorageName(gridViewValue)
    );
    if (!savedColDef || resetToDefault) {
      //setup localstorage if it doesn't exist
      let colDefArray = [];
      newColumns.forEach((col, index) => {
        columnVModel[col.field] =
          gridViewValue.sort != "All" && col.field.includes("customFieldData.")
            ? true
            : !col.disabled ?? true;

        colDefArray.push({
          field: col.field,
          enabled:
            gridViewValue.sort != "All" &&
            col.field.includes("customFieldData.")
              ? true
              : !col.disabled ?? true,
          width: col.width,
        });
      });
      localStorageService.setLocalStorage(
        getColumnLocalStorageName(gridViewValue),
        JSON.stringify(colDefArray)
      );
    } else {
      //TODO invalidate cache if new columns added or removed
      //update columns as needed
      let columnsToSet = [];
      console.log(savedColDef);
      let colDef = JSON.parse(savedColDef);
      newColumns.forEach((col) => {
        let existingColumn = colDef.find((x) => x.field == col.field);

        if (!existingColumn) {
          //reset columns if saved column doesnt exist
          setupColumns(true);
        }
      });
      colDef.forEach((col) => {
        let newColumn = newColumns.find((x) => x.field == col.field);

        if (newColumn) {
          newColumn.width = col.width;
          newColumn.disabled = !col.enabled;

          columnsToSet.push(newColumn);
          columnVModel[col.field] = col.enabled;
        } else {
          setupColumns(true);
        }
      });
      newColumns = [...columnsToSet];
    }
    setColumns(newColumns);
    setColumnVisabilityModel(columnVModel);

    //if local storage does exist, get column display settings

    //load new data
  };

  const handleGridViewChange = (e) => {
    setPageNumber(0);
    const value = e.target.value;
    const gridOption = gridViewOptions.find((x) => x.sort == value);
    if (gridOption) {
      localStorageService.setLocalStorage(gridViewStorageName, gridOption);
    }

    setGridView(gridOption);
    updateColumnsForGridView(gridOption);

    setLoading(false);
  };

  const getReadableEntityName = () => {
    return size[0] < 480
      ? ""
      : props.entityName.toLowerCase() == "event"
      ? "Event"
      : "Monitoring Event";
  };

  const isGridConfigured = () => {
    return (
      sortParams !== null &&
      pageSize != null &&
      pageNumber != null &&
      !_.isEmpty(columns)
    );
  };

  const getGroupByLabel = () => {
    const groupByValues = qsValues.groupBy.split("//");
    const groupColumn = columns.find((x) => x.field == groupByValues[0]);
    if (groupColumn) {
      return `Group By: ${groupColumn.headerName} == ${groupByValues[1]}`;
    }
  };
  const getImportHistoryIdLabel = () => {
    const importHistoryId = qsValues.importHistoryId;
    if (importHistoryId) {
      return `Import History ID == ${importHistoryId}`;
    }
  };
  const getMonLocationIdLabel = () => {
    const monitoringLocationId = qsValues.monitoringLocationId;
    if (monitoringLocationId) {
      return `Monitoring Location == ${filterChipLabel}`;
    }
  };

  const getContactIdLabel = () => {
    const contactId = qsValues.contactId;
    if (contactId) {
      return `Associated Contact == ${filterChipLabel}`;
    }
  };

  const getContactGroupIdLabel = () => {
    const contactGroupId = qsValues.contactGroupId;
    if (contactGroupId) {
      return `Associated Contact Group == ${filterChipLabel}`;
    }
  };

  const getQueryParamDisplay = () => {
    return (
      <>
        {qsValues && qsValues.groupBy && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getGroupByLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues &&
          qsValues.importHistoryId &&
          props.entityName != "photoImportHistory" && (
            <div className="d-flex h-100 ml-2">
              <Chip
                color="primary"
                variant="outlined"
                label={getImportHistoryIdLabel()}
                className="align-self-center"
                onDelete={() => {
                  resetGridParamsAndReload();
                }}
              />
            </div>
          )}
        {qsValues && qsValues.monitoringLocationId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getMonLocationIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues && qsValues.contactId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getContactIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues && qsValues.contactGroupId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getContactGroupIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
      </>
    );
  };
  const getPaginationForMobile = () => {
    return (
      <Pagination
        size="small"
        className="mt-1"
        page={pageNumber + 1}
        count={Math.ceil(totalItems / pageSize)}
        onChange={(e, value) => {
          setPageNumber(value - 1);
        }}
      />
    );
  };

  const handleSortOrderChange = (e) => {
    let existingSort = [...sortParams];

    setSortParams([{ field: existingSort[0].field, sort: e.target.value }]);
  };

  const handleSortFieldChange = (e) => {
    let existingSort = [...sortParams];
    if (!e.target.value) {
      setSortParams([]);
      return;
    }
    if (_.isEmpty(existingSort)) {
      setSortParams([{ field: e.target.value, sort: "asc" }]);
    } else {
      setSortParams([{ field: e.target.value, sort: existingSort[0].sort }]);
    }
  };
  const getMobileCards = () => {
    return (
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: size[1],
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <CircularProgress sx={{ alignSelf: "center" }} />
          </div>
        ) : (
          <>
            <div className="mobile-pagination">
              {!isOffline ? (
                <div className="d-flex justify-content-between ">
                  <Tooltip title="Sort">
                    <IconButton onClick={() => setShowSortDialog(true)}>
                      <Badge
                        color="primary"
                        invisible={_.isEmpty(sortParams)}
                        variant="dot"
                      >
                        <SortIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  {getPaginationForMobile()}
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={() => {
                        setIsLoadFilterMode(true);
                        setShowFilterModal(true);
                      }}
                    >
                      <Badge
                        color="primary"
                        invisible={_.isEmpty(selectedFilter)}
                        variant="dot"
                      >
                        <FilterListIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <div className="h-100 d-flex justify-content-center mt-2 text-red">
                  <Typography variant="body2">
                    Offline Mode - Showing Last Loaded Events
                  </Typography>
                </div>
              )}
            </div>
            <div className="mobile-card-container pb-5">
              {data.map((item, i) => (
                <props.mobileCard entity={item} key={i}></props.mobileCard>
              ))}
              {_.isEmpty(data) && (
                <div className="d-flex justify-content-center mt-2 ml-2">
                  <Typography className="">No rows</Typography>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      ref={targetRef}
    >
      {props.isForSettings ? (
        getDataGridCard()
      ) : (
        <Grid>
          {(!props.onlyShowTable || props.header) && (
            <>
              <Paper elevation={props.noElevation ? 0 : 1}>
                <Grid.Row
                  className={`ml-0 mr-0 ${
                    isMobileCardView
                      ? "subpage-header-row-fixed"
                      : "subpage-header-row"
                  }`}
                >
                  {(props.title || props.entityName) && (
                    <Grid.Col width={12}>
                      <div className="d-flex h-100 ">
                        {!props.header ? (
                          <>
                            {(!isMobileCardView ||
                              props.entityName != "Event") &&
                              !props.hasNoTitle && (
                                <>
                                  <Typography
                                    variant={isMobileCardView ? "h6" : "h3"}
                                    component="div"
                                    className="align-self-center entity-number mr-5"
                                  >
                                    {props.title ?? props.entityName + "s"}{" "}
                                    {showBulkUpdate && "Bulk Update"}
                                  </Typography>
                                </>
                              )}

                            {!_.isEmpty(gridView) &&
                              !showBulkUpdate &&
                              gridViewOptions.length > 1 &&
                              gridView != {} &&
                              props.entityName == "Event" &&
                              !qsValues.groupBy &&
                              !qsValues.importHistoryId &&
                              !qsValues.monitoringLocationId && (
                                <span className="align-self-end grid-view-select">
                                  <FormControl
                                    variant="standard"
                                    sx={
                                      dimensions.isMobile
                                        ? {
                                            minWidth: 250,
                                          }
                                        : {
                                            marginBottom: 1,
                                            marginTop: 1,
                                            minWidth: 250,
                                          }
                                    }
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Select Grid View
                                    </InputLabel>
                                    <Select
                                      value={gridView.sort}
                                      onChange={handleGridViewChange}
                                      disabled={isOffline}
                                      className="font-weight-bold"
                                    >
                                      {_.sortBy(gridViewOptions, "sort").map(
                                        (opt) => (
                                          <MenuItem
                                            value={opt.sort}
                                            key={opt.sort}
                                          >
                                            {opt.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </span>
                              )}
                          </>
                        ) : (
                          <>
                            <props.header />
                          </>
                        )}
                        {props.searchEnabled && dimensions.width > 900 && (
                          <Stack
                            width="50%"
                            minWidth={250}
                            marginX={1}
                            justifyContent="end"
                            marginBottom={1}
                          >
                            <DataGridSearch
                              placeholder={props.searchLabel}
                              localStorageName={props.localStorageName}
                              handleSearch={(search) => setSearchValue(search)}
                            />
                          </Stack>
                        )}
                        {!props.hideAddButton &&
                          (props.entityModal || props.addButtonAction) &&
                          props.entityName != "Event" &&
                          props.entityName != "MonitoringEvent" &&
                          props.entityName != "MonitoringResult" && (
                            <div className="d-flex h-100 ml-auto header-button">
                              <Button
                                size={isMobileCardView ? "small" : "medium"}
                                variant="contained"
                                onClick={handleAdd}
                                disabled={
                                  props.disableAddButton ||
                                  (isOffline &&
                                    props.entityName !=
                                      EntityTypes.InspectionEvent)
                                }
                                className="ml-1  mr-1 ml-auto  align-self-center grid-add-button"
                              >
                                {props.addButtonText ??
                                  "Add " + props.entityName}
                              </Button>
                            </div>
                          )}
                        {!props.hideAddButton &&
                          (props.entityName == "Event" ||
                            props.entityName == "MonitoringEvent" ||
                            props.entityName == "MonitoringResult") &&
                          !showBulkUpdate && (
                            <div className="d-flex h-100 ml-auto header-button">
                              <Button
                                id="basic-button"
                                size={isMobileCardView ? "small" : "medium"}
                                aria-controls={
                                  eventMenuOpen ? "basic-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  eventMenuOpen ? "true" : undefined
                                }
                                onClick={handleEventAddClick}
                                variant="contained"
                                className="ml-1 mr-1  align-self-center grid-add-button ml-auto"
                              >
                                Add {getReadableEntityName()}
                              </Button>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={eventMenuOpen}
                                onClose={handleEventMenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setIsScheduledEvent(false);
                                    handleAdd(false);
                                    handleEventMenuClose();
                                  }}
                                >
                                  Add {getReadableEntityName()}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setIsScheduledEvent(true);
                                    handleAdd(true);
                                    handleEventMenuClose();
                                  }}
                                >
                                  Schedule {getReadableEntityName()}
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                      </div>
                    </Grid.Col>
                  )}
                </Grid.Row>
              </Paper>
            </>
          )}

          <Grid.Row className="ml-0 mr-0">
            <Grid.Col width={props.gridWidth ?? 12} className="pl-0 pr-0">
              {isGridConfigured() && getDataGridCard()}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )}
      {props.viewModal && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={viewOpen}
          onClose={handleViewDialogClose}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          maxWidth={"lg"}
        >
          <props.viewModal
            handleClose={handleViewClose}
            event={selectedRow}
            customFields={props.customFields}
          />
        </Dialog>
      )}
      {props.entityModal && open && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleDialogClose}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <props.entityModal
            handleModalClose={handleClose}
            entityId={selectedDataId}
            entity={selectedEntity}
            isScheduledEvent={isScheduledEvent}
            handleFollowUpRequired={handleFollowUpRequired}
            defaultValues={defaultEntityModalValues}
            linkedEntityId={linkedEntityId}
            {...props.entityModalProps}
          />
        </Dialog>
      )}
      {showSortDialog && (
        <LynxDialog
          title={"Select Sort"}
          open={showSortDialog && isMobileCardView}
          handleConfirm={() => {
            setShowSortDialog(false);
          }}
          description={
            <>
              <Form.Group label="Sort By">
                <Form.Select
                  onChange={handleSortFieldChange}
                  value={!_.isEmpty(sortParams) ? sortParams[0].field : ""}
                >
                  <option value={""}></option>
                  {columns
                    .filter((x) => x.sortable !== false)
                    .map((column) => (
                      <option value={column.field} key={column.field}>
                        {column.headerName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Select
                  onChange={handleSortOrderChange}
                  value={!_.isEmpty(sortParams) ? sortParams[0].sort : "asc"}
                  disabled={_.isEmpty(sortParams)}
                >
                  <option value={"asc"}>Ascending</option>
                  <option value={"desc"}>Descending</option>
                </Form.Select>
              </Form.Group>
            </>
          }
        ></LynxDialog>
      )}

      {showFilterModal && (
        <FilterModal
          open={showFilterModal}
          handleClose={() => setShowFilterModal(false)}
          handleSaveFilter={onFilterSave}
          savedFilters={savedFilters}
          isLoadFilterMode={isLoadFilterMode}
          handleLoadFilterSelected={handleLoadFilterSelected}
          handleLoadFilterClose={handleLoadFilterClose}
          selectedFilter={selectedFilter}
          handleClearFilter={handleClearFilter}
        />
      )}
      {showReportsModal && (
        <ReportsModal
          open={showReportsModal}
          handleClose={() => setShowReportsModal(false)}
          reports={reports}
          handleRunReport={handleRunReport}
        />
      )}
      {selectedFilter && (
        <LynxDialog
          open={showDeleteFilter}
          handleClose={() => setShowDeleteFilter(false)}
          handleDelete={handleDeleteFilter}
          title={`Delete Filter - ${selectedFilter.name}?`}
        />
      )}
      {showReportError && (
        <LynxDialog
          open={showReportError}
          handleConfirm={() => setShowReportError(false)}
          title={`Unable to run report.`}
          description="Please filter the data so that it is less than 1000 records."
        />
      )}
      {showResetColumnsDialog && (
        <LynxDialog
          open={showResetColumnsDialog}
          handleClose={() => setShowResetColumnsDialog(false)}
          title={`Reset columns?`}
          handleConfirm={handleResetColumns}
        />
      )}
      {offlineMessageOpen && isOffline && (
        <LynxDialog
          open={offlineMessageOpen && isOffline}
          title={`Unable to create event offline`}
          description={
            "Return online and press the sync button in the top right corner."
          }
          handleClose={(e) => {
            setOfflineMessageOpen(false);
          }}
        />
      )}
      {exportError != null && (
        <LynxDialog
          open={exportError != null}
          title={`Unable to export`}
          description={exportError}
          handleConfirm={(e) => {
            setExportError(null);
          }}
        />
      )}
      {reportDownloading && (
        <LynxDialog
          open={reportDownloading}
          title={`Running Report. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
      {isExportingXlsx && (
        <LynxDialog
          open={isExportingXlsx}
          title={`Exporting data. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
      {addingBulkAttachments && (
        <LynxDialog
          open={addingBulkAttachments}
          title={`Adding attachments to events. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}

      {isExportingAttachments && (
        <LynxDialog
          open={isExportingAttachments}
          title={`Exporting attachments. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}

      {props.tour && dimensions.width > 900 && !loading && !isOffline && (
        <props.tour />
      )}
    </div>
  );
}
