import React, { useState, useEffect } from "react";
import "./map.css";
import CircleIcon from "@mui/icons-material/Circle";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SquareIcon from "@mui/icons-material/Square";
import colors from "../../../modules/lynxColors";
import lynxColors from "../../../modules/lynxColors";
import { hasAnyEventRole, roleMatch } from "../../../actions/auth";
import { UserRoles } from "../../../types/enums";
export function MapLegend(props) {
  const [legend, setLegend] = useState("");
  useEffect(() => {
    if (!hasAnyEventRole()) {
      setLegend("Assets");
    } else {
      setLegend("Events");
    }
  }, []);

  const handleLegendChange = (event) => {
    setLegend(event.target.value);
  };
  const handleEventLegendKeyChange = (event) => {
    props.handleEventLegendKeyChanged(event.target.value);
  };
  return (
    <div className="map-legend-container">
      <div
        className={`map-legend ${
          props.toggleSidebar ? "map-legend-sidebar" : ""
        }`}
      >
        <div className="h-100 d-flex">
          <div className="mt-auto mb-auto d-flex mr-2 ml-2">
            <div>Legend:</div>
          </div>
          <div className="mt-auto mb-auto d-flex mr-2">
            <FormControl size="small">
              <Select
                value={legend}
                onChange={handleLegendChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value="Assets">Assets</MenuItem>
                {hasAnyEventRole() && (
                  <MenuItem value="Events">Events</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="mt-auto mb-auto d-flex mr-3">
            {legend == "Events" && (
              <FormControl size="small">
                <Select
                  value={props.eventLegendKey}
                  onChange={handleEventLegendKeyChange}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <MenuItem value="Status">Status</MenuItem>
                  <MenuItem value="Urgency">Urgency</MenuItem>
                </Select>
              </FormControl>
            )}
            {legend == "Assets" && <div>Status</div>}
          </div>

          {legend == "Assets" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="mr-2"
            >
              <SquareIcon
                fontSize="small"
                sx={{ color: lynxColors.success, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Green</span>{" "}
              <SquareIcon
                fontSize="small"
                sx={{ color: lynxColors.warning, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Yellow</span>{" "}
              <SquareIcon
                fontSize="small"
                sx={{ color: lynxColors.error, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Red</span>{" "}
              <SquareIcon
                fontSize="small"
                sx={{ color: colors.primary, alignSelf: "center" }}
              />
              <span className="ml-1">No Status</span>
            </div>
          )}

          {legend == "Events" && props.eventLegendKey == "Status" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="mr-2"
            >
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.primary, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Complete</span>{" "}
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.harvestOrange, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Pending Review</span>
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.success, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Pending Actions</span>
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.success, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Incomplete</span>{" "}
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.error, alignSelf: "center" }}
              />
              <span className=" ml-1">Incomplete (Overdue)</span>{" "}
            </div>
          )}

          {legend == "Events" && props.eventLegendKey == "Urgency" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="mr-2"
            >
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.error, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">High</span>{" "}
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.warning, alignSelf: "center" }}
              />
              <span className=" ml-1 mr-2">Medium</span>{" "}
              <CircleIcon
                fontSize="small"
                sx={{ color: lynxColors.success, alignSelf: "center" }}
              />
              <span className="mr-2 ml-1">Low</span>{" "}
              <CircleIcon
                fontSize="small"
                sx={{ color: colors.primary, alignSelf: "center" }}
              />
              <span className="ml-1">No urgency set</span>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
