import { Button, Container, Typography } from "@mui/material";
import { loginUser } from "actions/auth";
import _ from "lodash";
import { useAppSelector } from "modules/hooks";
import { AppDispatch } from "modules/store";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { RootState } from "types";
import queryString from "query-string";
export function OrganizationSelection() {
  const auth = useAppSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();

  if (
    !auth.requiresOrganizationSelection ||
    _.isEmpty(auth.organizationUsers)
  ) {
    const qs = queryString.parse(location.search);
    if (qs.redirect) {
      return <Redirect to={qs.redirect as string} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  const handleLoginUser = (id?: number) => {
    const creds = {
      email: auth.user.email?.trim(),
      password: auth.user.password?.trim(),
      url: location.pathname,
      organizationUserId: id,
    };
    dispatch(loginUser(creds));
  };
  return (
    <Container className="mt-5">
      <Typography variant="h4" className="mb-2">
        Select an Organization
      </Typography>
      {auth.organizationUsers.map((a, i) => {
        return (
          <Button
            key={i}
            onClick={() => {
              handleLoginUser(a.id);
            }}
            className="mr-5 mb-2"
            sx={{
              height: "100px",
              width: "400px",
              backgroundColor: "white",
            }}
            variant="outlined"
          >
            {a.organizationName}
          </Button>
        );
      })}
    </Container>
  );
}
