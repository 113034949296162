import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { addAlert } from "reducers/alerts";
import { isErrorWithMessage } from "services/rtkApi/helpers";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const errorMsg = isErrorWithMessage(action.payload)
        ? action.payload.data.message
        : action.error.message;
      store.dispatch(
        addAlert({
          message: errorMsg || "Error has occured.",
          variant: "error",
        })
      );
    }

    return next(action);
  };
