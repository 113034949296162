import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const CardFooter: React.FC<Props> = ({ className, children }) => {
  const classes = cn("card-footer", className);
  return <div className={classes}>{children}</div>;
};

export default CardFooter;
