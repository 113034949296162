import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Grid, Page } from "components/lynx-components";
import { loadAccount, updateAccount } from "../actions/account";

import Button from "@mui/material/Button";
import ResetPassword from "../components/login/reset-password";
import { getLookup } from "../services/lookup";
import useAlert from "hooks/useAlert";

const AccountContainer = () => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const account = useSelector((state) => state.account);

  const [state, setState] = useState({
    id: 0,
    organizationName: "",
    email: "",
    firstName: "",
    lastName: "",
    language: "",
    city: "",
    postalCode: "",
    country: "",
    defaultLatitude: 0,
    defaultLongitude: 0,
    defaultTheme: "Dark",
    defaultRightSidebar: "Newsfeed",
    defaultZoom: 0,
    togglePasswordReset: false,
    coordinateType: "",
    passwordErrorList: [],
    timezones: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(updateAccount(state)).then(() => {
      showAlert("success", "Account updated.");
      dispatch(loadAccount());
    });
  };

  const handleBack = () => {
    setState((prevState) => ({ ...prevState, togglePasswordReset: false }));
  };

  useEffect(() => {
    getLookup("timezone").then((res) => {
      setState((prevState) => ({ ...prevState, timezones: res.data }));
    });
  }, []);

  useEffect(() => {
    if (!account.isLoading) {
      setState((prevState) => ({
        ...prevState,
        organizationName: account.organizationName,
        firstName: account.firstName,
        email: account.email,
        defaultRightSidebar: account.defaultRightSidebar,
        lastName: account.lastName,
        language: account.language,
        city: account.city,
        postalCode: account.postalCode,
        country: account.country,
        defaultLatitude: account.defaultLatitude,
        defaultLongitude: account.defaultLongitude,
        defaultTheme: account.defaultTheme,
        defaultZoom: account.defaultZoom,
        id: account.id,
        coordinateType: account.coordinateType,
        timezoneId: account.timezoneId,
      }));
    }
  }, [account.isLoading]);

  return (
    <Page>
      <Page.Main>
        <Page.Content title="My Account">
          {!account.isLoading && !state.togglePasswordReset && (
            <Form className="card">
              <Card.Body>
                <Card.Title>
                  Edit Profile
                  <Button
                    variant="contained"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        togglePasswordReset: true,
                      }))
                    }
                    color="neutral"
                    className="ml-2 float-right"
                  >
                    Reset Password
                  </Button>
                </Card.Title>

                <Grid.Row>
                  <Grid.Col md={6} width={12}>
                    <Form.Group>
                      <Form.Label>Organization</Form.Label>
                      <Form.Input
                        type="text"
                        disabled
                        placeholder="Company"
                        value={state.organizationName}
                        name="organizationName"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={12} md={6}>
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Input
                        type="email"
                        placeholder="Email"
                        disabled
                        value={state.email}
                        name="email"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} md={4}>
                    <Form.Group>
                      <Form.Label htmlFor="firstName">First Name</Form.Label>
                      <Form.Input
                        type="text"
                        placeholder="First Name"
                        value={state.firstName}
                        name="firstName"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} md={4}>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Input
                        type="text"
                        placeholder="Last Name"
                        value={state.lastName}
                        name="lastName"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={12} md={4}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Input
                        type="text"
                        placeholder="City"
                        value={state.city}
                        name="city"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} md={4}>
                    <Form.Group>
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Input
                        type="number"
                        placeholder="ZIP Code"
                        value={state.postalCode}
                        name="postalCode"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={12} md={4}>
                    <Form.Group>
                      <Form.Label>Timezone</Form.Label>
                      <Form.Select
                        value={state.timezoneId}
                        name="timezoneId"
                        onChange={handleInputChange}
                      >
                        {state.timezones.map((zone) => (
                          <option value={zone.id} key={zone.id}>
                            {zone.displayName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={12} md={12}>
                    <Form.Group>
                      <Card.Title>Dashboard Settings</Card.Title>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col sm={6} md={4}>
                    <Form.Group>
                      <Form.Label>Coordinate Type</Form.Label>
                      <Form.Select
                        value={state.coordinateType}
                        name="coordinateType"
                        onChange={handleInputChange}
                      >
                        <option value="LatLong">Latitude / Longitude</option>
                        <option value="Utm">UTM</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button variant="contained" onClick={handleSubmit}>
                  Update Profile
                </Button>
              </Card.Footer>
            </Form>
          )}
          {!account.isLoading && state.togglePasswordReset && (
            <ResetPassword
              handleBack={handleBack}
              requireCurrentPassword={true}
              email={account.email}
              apiUrl="/account/password"
            />
          )}
        </Page.Content>
      </Page.Main>
    </Page>
  );
};

export default AccountContainer;
