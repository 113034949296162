import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "components/lynx-components";
import { createGuid } from "../../../services/events";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
import {
  analyzeMonitoringLocationFiles,
  downloadImage,
  getPhotoImportConfigurationSupportedLabels,
  uploadTimeseriesPhotos,
} from "../../../services/time-series-service";
import PageNotFound from "../../PageNotFound";
import { LynxDialog } from "../../lynx-dialog";
import { MonitoringLocationUploadStep1 } from "./monitoring-location-upload-step-1";
import useAlert from "hooks/useAlert";

export function MonitoringLocationUploadPhotos(props) {
  var organization = useSelector((state) => state.organization);

  const [activeStep, setActiveStep] = React.useState(0);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [supportedLabels, setSupportedLabels] = useState([]);
  const [selectedMonitoringLocationId, setSelectedMonitoringLocationId] =
    useState(0);
  const [files, setFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [allFilesGrouped, setAllFilesGrouped] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [viewBy, setViewBy] = useState("matches");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showUploadedDialog, setShowUploadedDialog] = useState(false);
  const { showAlert } = useAlert();
  useEffect(() => {
    if (organization.hasPhotoAnalysis === false) {
      setShowNotFound(true);
    }
  }, [organization.hasPhotoAnalysis]);

  useEffect(() => {
    handleRdoViewByChange(viewBy);
  }, [allFiles]);

  useEffect(() => {
    getMonitoringLocations({ isWildlifeCameras: true }).then((res) => {
      setMonitoringLocations(res.data);
    });

    getPhotoImportConfigurationSupportedLabels().then((res) => {
      if (res.data.length === 0) {
        showAlert("error", "No photo import configuration supported labels found.");
        handleNavigateTo(null, "/monitoring-location-time-series");
      } else {
        setSupportedLabels(res.data);
      }
    });
  }, []);

  const history = useHistory();
  const steps = [
    {
      label: "Upload Photos",
    },
  ];

  useEffect(() => {
    handleRdoViewByChange(viewBy);
  }, [allFilesGrouped]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setAllFiles([]);
  };

  useEffect(() => {
    if (allFiles.length > 0) {
      allFiles.sort(function (left, right) {
        return moment.utc(left.fileDate).diff(moment.utc(right.fileDate));
      });
      var groupedFiles = [];
      var currentGroup = { files: [] };
      var prevFileDate = "";
      var seq = 1;
      allFiles.forEach((fi, idx) => {
        fi.seq = seq;
        if (idx === 0) {
          currentGroup.files.push(fi);
          prevFileDate = fi.fileDate;
        } else {
          let timeDiff = moment
            .utc(fi.fileDate)
            .diff(moment.utc(prevFileDate), "seconds");
          prevFileDate = fi.fileDate;
          if (timeDiff <= 2) {
            seq++;
            currentGroup.files.push(fi);
          } else {
            groupedFiles.push(currentGroup);
            currentGroup = { files: [] };
            currentGroup.files.push(fi);
            seq = 1;
          }
        }

        if (idx === allFiles.length - 1) {
          groupedFiles.push(currentGroup);
        }
      });
      setAllFilesGrouped(groupedFiles);
    }
  }, [isUploading]);

  const handleMonitoringLocationChange = (value) => {
    setSelectedMonitoringLocationId(value);
  };

  const handleFileUpload = (e) => {
    const selectedFiles = e.target.files;
    setUploadedFiles([...uploadedFiles, ...selectedFiles]);
  };

  const handleFileDelete = (index) => {
    setUploadedFiles((uploadedFiles) =>
      uploadedFiles.filter((fi, i) => i !== index)
    );
  };

  const handleUploadPhotos = () => {
    setIsUploading(true);
    var maxUploadSize = 1048576 * 40;
    var currentPackageSize = 0;
    var currentPackage = [[]];
    var i = 0;
    uploadedFiles.forEach((file) => {
      currentPackageSize += file.size;
      if (currentPackageSize > maxUploadSize) {
        currentPackage.push([]);
        i++;
        currentPackageSize = 0;
        currentPackageSize += file.size;
      }
      currentPackage[i].push(file);
    });
    var currentUploadIndex = 0;
    let guid = createGuid();
    const uploadNextPackage = async () => {
      uploadTimeseriesPhotos(
        currentPackage[currentUploadIndex],
        selectedMonitoringLocationId,
        currentUploadIndex + 1 == currentPackage.length ? true : false,
        guid
      )
        .then((res) => {
          currentUploadIndex++;
          if (currentUploadIndex < currentPackage.length) {
            uploadNextPackage();
          } else {
            setIsUploading(false);
            setShowUploadedDialog(true);
            handleRdoViewByChange(viewBy);
            handleNext();
          }
        })
        .catch((err) => {
          setIsUploading(false);
          showAlert("error", err.response.data.message);
        });
    };
    uploadNextPackage();

    // uploadedFiles.forEach((fi) => {
    //   var promise = analyzeFile(fi);
    //   promiseList.push(promise);
    // });

    // localStorage.setItem(
    //   LocalStorageKeys.DisableOfflineDateTime,
    //   moment.utc().format()
    // );
    // Promise.all(promiseList).then(() => {
    //   localStorage.removeItem(LocalStorageKeys.DisableOfflineDateTime);
    //   setIsUploading(false);
    //   handleRdoViewByChange(viewBy);
    //   handleNext();
    // });
  };

  const analyzeFile = (fi) => {
    return new Promise(function (resolve) {
      var files = [];
      files.push(fi);
      analyzeMonitoringLocationFiles(files, selectedMonitoringLocationId).then(
        (res) => {
          res.data.forEach((file, index) => {
            if (file.labels[0].hasBoundedBox) {
              downloadImage(file.filePath)
                .then((res2) => {
                  const fileUrl = URL.createObjectURL(res2.data);
                  file.fileLocation = fileUrl;
                  setAllFiles((existing) => {
                    return [file, ...existing];
                  });
                  resolve(true);
                  return () => URL.revokeObjectURL(fileUrl);
                })
                .catch(() => {
                  showAlert("error", 
                    "Error downloading bounded box image for " + file.fileName
                  );
                  resolve(false);
                });
            } else {
              const fileUrl = URL.createObjectURL(fi);
              file.fileLocation = fileUrl;
              setAllFiles((existing) => {
                return [file, ...existing];
              });
              resolve(true);
              return () => URL.revokeObjectURL(fileUrl);
            }
          });
        }
      );
    });
  };

  const handleRdoViewByChange = (value) => {
    setViewBy(value);
    if (value === "matches") {
      let selectedFiles = [];
      allFilesGrouped.forEach((af) => {
        if (_.some(af.files, (fi) => fi.labels[0].hasBoundedBox === true)) {
          selectedFiles.push(af);
        }
      });
      setFiles(selectedFiles);
    } else {
      setFiles(allFilesGrouped);
    }
  };

  const handleConfirm = () => {
    // setShowConfirmationDialog(false);
    // setIsApproving(true);
    // postMonitoringLocationTimeSeries(
    //   allFilesGrouped,
    //   selectedMonitoringLocationId
    // )
    //   .then((res) => {
    //     setIsApproving(false);
    //     handleNavigateTo(null, "/monitoring-location-time-series");
    //   })
    //   .catch(() => {
    //     setIsApproving(false);
    //     showAlert("error", "Error uploading images");
    //   });
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }

    history.push(url);
  };

  if (showNotFound) {
    return <PageNotFound message="Monitoring Location Upload not found" />;
  } else
    return (
      <Grid>
        <Paper>
          <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
            <Grid.Col width={12}>
              <div className="d-flex h-100">
                <Typography
                  className="align-self-center"
                  variant="h3"
                  component="div"
                >
                  Upload Photos
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) =>
                    handleNavigateTo(e, "/monitoring?view=timeseries")
                  }
                >
                  Timeseries
                </Link>

                <Typography color="text.primary">Upload Photos</Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row className="ml-5 mr-0">
          <Paper elevation={3} className="p-2">
            <Box sx={{ width: 1250 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {index == 0 && (
                        <MonitoringLocationUploadStep1
                          monitoringLocations={monitoringLocations}
                          handleMonitoringLocationChange={
                            handleMonitoringLocationChange
                          }
                          selectedMonitoringLocationId={
                            selectedMonitoringLocationId
                          }
                          handleFileUpload={handleFileUpload}
                          handleFileDelete={handleFileDelete}
                          files={uploadedFiles}
                          handleBack={handleBack}
                          handleAnalyze={handleUploadPhotos}
                        />
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Paper>
        </Grid.Row>
        <LynxDialog
          open={showFileSizeError}
          handleClose={() => setShowFileSizeError(false)}
          title={`File size exceeded`}
          description={
            "Attachments are over the required total size limit of 50MB."
          }
          handleConfirm={() => setShowFileSizeError(false)}
        />
        <LynxDialog
          open={showConfirmationDialog}
          handleConfirm={handleConfirm}
          handleClose={() => setShowConfirmationDialog(false)}
          title="Approve Image Upload?"
          description={`Are you sure that you want to approve uploading these ${allFilesGrouped.length} images as time series results? `}
        />
        <LynxDialog
          open={isUploading}
          title={`Uploading images and starting AI model. Do not close the window... `}
          dialogContent={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
        <LynxDialog
          open={showUploadedDialog}
          title={`Preparing for review`}
          description="The images have been uploaded and added to the queue. You will recieve an email when the images are ready to review and you can also check the status on the import history page."
          handleConfirm={() =>
            handleNavigateTo(null, "/monitoring?view=timeseries")
          }
        />
        <LynxDialog
          open={isApproving}
          title={`Saving approved images. Do not close the window.`}
          dialogContent={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      </Grid>
    );
}
