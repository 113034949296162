import * as React from "react";
import cn from "classnames";
import FormGroup from "./form-group";

interface FormStyle {
  className?: string;
  valid?: boolean;
  tick?: boolean;
  invalid?: boolean;
  cross?: boolean;
  feedback?: string;
  error?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  min?: string | number;
  max?: string | number;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  checked?: boolean;
}

interface Props extends FormStyle, React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  type?:
    | "checkbox"
    | "radio"
    | "text"
    | "email"
    | "password"
    | "date"
    | "datetime-local"
    | "number";
  label?: string;
  autoComplete?: "on" | "off";
}

const FormInput: React.FC<Props> = ({
  className,
  name,
  valid,
  tick,
  invalid,
  cross,
  error,
  placeholder,
  value,
  min,
  max,
  minLength,
  maxLength,
  checked,
  onChange,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  onCopy,
  onCut,
  onPaste,
  disabled,
  readOnly,
  autoFocus,
  required,
  label,
  autoComplete,
  type,
  feedback,
}) => {
  type = type || "text";

  const classes = cn(
    {
      "form-control": type !== "checkbox" && type !== "radio",
      "custom-control-input": type === "checkbox" || type === "radio",
      "is-valid": valid,
      "state-valid": tick,
      "is-invalid": invalid || !!error,
      "state-invalid": cross || !!error,
    },
    className
  );

  feedback = error || feedback;

  const allInputProps = {
    name,
    className: classes,
    type,
    placeholder,
    value,
    min,
    max,
    minLength,
    maxLength,
    disabled,
    readOnly,
    autoFocus,
    required,
    onChange,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    onFocus,
    onBlur,
    onKeyUp,
    onKeyDown,
    onCopy,
    onCut,
    onPaste,
    autoComplete,
  };

  const contents = (
    <>
      {type === "checkbox" || type === "radio" ? (
        <input {...allInputProps} checked={checked} />
      ) : (
        <input {...allInputProps} />
      )}
      {feedback && <span className="invalid-feedback">{feedback}</span>}
    </>
  );

  return label ? <FormGroup label={label}>{contents}</FormGroup> : contents;
};

export default FormInput;
