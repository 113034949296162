import { LynxDataGrid } from "components/data-grid/LynxDataGrid";
import React, { useState } from "react";
import { getCustomers, getUsersForCustomer } from "services/customer-service";
import { EntityTypes } from "types/enums";
import { CustomerModal } from "./customer-modal";
import { Button, Dialog, Link } from "@mui/material";
import { CustomerDto, UserDto } from "types";
import { CustomerUserModal } from "./customer-user-modal";

export function CustomerList() {
  const [showAddEditCustomer, setShowAddEditCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(
    null as CustomerDto | null
  );
  const [customerUsers, setCustomerUsers] = useState<UserDto[]>([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const handleClickCustomer = (customer: CustomerDto) => {
    setSelectedCustomer(customer);
    setShowAddEditCustomer(true);
  };
  const handleViewUsers = (customer: CustomerDto) => {
    getUsersForCustomer(customer.id).then((res) => {
      setCustomerUsers(res.data);
      setShowUserModal(true);
    });
    setSelectedCustomer(customer);
  };
  const customerColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      type: "string",
      renderCell: (params: any) => {
        return (
          <Link
            href="#"
            onClick={() => {
              handleClickCustomer(params.row);
            }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "numberOfOrgs",
      headerName: "# of Orgs",
      width: 150,
      type: "number",
    },
    {
      field: "numberOfUsers",
      headerName: "# of Users",
      width: 175,
      type: "number",
      renderCell: (params: any) => {
        return (
          <>
            {params.value}{" "}
            <Button
              className="ml-2"
              onClick={() => handleViewUsers(params.row)}
            >
              View Users
            </Button>
          </>
        );
      },
    },
    {
      field: "userCount",
      headerName: "User Licenses",
      width: 175,
      type: "number",
    },
    {
      field: "eventsLicenseCount",
      headerName: "Event Licenses",
      width: 175,
      type: "number",
    },
    {
      field: "monitoringLicenseCount",
      headerName: "Monitoring Licenses",
      width: 175,
      type: "number",
    },
    {
      field: "incidentsLicenseCount",
      headerName: "Incidents Licenses",
      width: 175,
      type: "number",
    },
    {
      field: "inspectionsLicenseCount",
      headerName: "Inspections Licenses",
      width: 175,
      type: "number",
    },
    {
      field: "energyLogsLicenseCount",
      headerName: "Energy Log Licenses",
      width: 175,
      type: "number",
    },
  ];
  return (
    <>
      {showAddEditCustomer && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showAddEditCustomer}
        >
          <CustomerModal
            selectedCustomer={selectedCustomer}
            handleModalClose={() => {
              setSelectedCustomer(null);
              setShowAddEditCustomer(false);
            }}
          />
        </Dialog>
      )}
      {showUserModal && selectedCustomer && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showUserModal}
        >
          <CustomerUserModal
            selectedCustomer={selectedCustomer as CustomerDto}
            handleModalClose={() => {
              setCustomerUsers([]);
              setShowUserModal(false);
            }}
            customerUsers={customerUsers}
          />
        </Dialog>
      )}
      <LynxDataGrid
        addButtonAction={() => {
          setShowAddEditCustomer(true);
        }}
        addButtonText="Add Customer"
        columns={customerColumns}
        getDataFunction={getCustomers}
        localStorageName="customers"
        entityName={EntityTypes.Customer}
        hasNoLookups
      />
    </>
  );
}
