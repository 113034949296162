import React from "react";
import cn from "classnames";

interface Props extends React.HTMLProps<HTMLTableCellElement> {
  children?: React.ReactNode;
  className?: string;
  colSpan?: number;
  alignContent?: "left" | "center" | "right";
}

const TableCol: React.FC<Props> = ({
  className,
  children,
  alignContent = "",
  colSpan,
}) => {
  const classes = cn({ [`text-${alignContent}`]: alignContent }, className);

  return (
    <td className={classes} colSpan={colSpan}>
      {children}
    </td>
  );
};

export default TableCol;
