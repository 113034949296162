import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Form, Grid, Icon } from "components/lynx-components";
import { getLookups } from "./../../../../services/lookup";
import {
  createCustomField,
  patchCustomField,
  deleteCustomField,
} from "./../../../../services/custom-fields-service";
import { validationService } from "./../../../../services/validation";
import { LynxDialog } from "./../../../lynx-dialog";

import { roleMatch } from "../../../../actions/auth";
import { EntityTypes, UserRoles, LookupTypes } from "../../../../types/enums";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";

const initialForm = {
  fieldLabel: "",
  fieldLabelError: "",
  fieldType: "",
  fieldTypeError: "",
  isRequired: false,
  isEmissionsField: false,
  isDeleted: false,
  fieldDescription: "",
  parentLookupId: "",
  childLookupId: "",
  emissionsScope: "",
};

export function CustomFieldModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const [eventCategories, setEventCategories] = useState([]);
  const [eventSubCategories, setEventSubCategories] = useState([]);
  const [catSubCategories, setCatSubCategories] = useState([]);
  const [typeSubTypes, setTypeSubTypes] = useState([]);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [incidentSubTypes, setIncidentSubTypes] = useState([]);
  const [monitoringEventTypes, setMonitoringEventTypes] = useState([]);
  const [energyLogTypes, setEnergyLogTypes] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const apiRef = useGridApiRef();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.entityType == EntityTypes.Incident) {
      getLookups("incidentType").then((res) => {
        setIncidentTypes(res.data);
      });
      getLookups("incidentSubType").then((res) => {
        setIncidentSubTypes(res.data);
        if (props.customField.parentLookupId > 0) {
          setTypeSubTypes(
            res.data.filter(
              (x) => x.parentLookupId == props.customField.parentLookupId
            )
          );
        }
      });
    }
    if (props.entityType == EntityTypes.Event) {
      getLookups("eventCategory").then((res) => {
        setEventCategories(res.data);
      });
      getLookups("eventSubCategory").then((res) => {
        setEventSubCategories(res.data);
        if (props.customField.parentLookupId > 0) {
          setCatSubCategories(
            res.data.filter(
              (x) => x.parentLookupId == props.customField.parentLookupId
            )
          );
        }
      });
    }
    if (props.entityType == EntityTypes.MonitoringEvent) {
      getLookups("monitoringEventType").then((res) => {
        setMonitoringEventTypes(res.data);
      });
    }
    if (props.entityType == EntityTypes.EnergyLog) {
      getLookups(LookupTypes.EnergyLogType).then((res) => {
        setEnergyLogTypes(res.data);
      });
    }

    if (props.customField.id > 0) {
      setFormStateFromProps();
    }
  }, []);

  const setFormStateFromProps = () => {
    let newState = {
      fieldLabel: props.customField.fieldLabel,
      fieldType: props.customField.fieldType,
      isRequired: props.customField.isRequired,
      isDeleted: props.customField.isDeleted,
      fieldDescription: props.customField.fieldDescription,
      parentLookupId: props.customField.parentLookupId,
      childLookupId: props.customField.childLookupId,
      dropdownValues: props.customField.dropdownValues,
      co2Factor: props.customField.co2Factor,
      isEmissionsField: props.customField.isEmissionsField,
      emissionsScope: props.customField.emissionsScope,
      entityType: props.entityType,
    };
    if (!_.isEmpty(props.customField.dropdownValues)) {
      setDropdownValues(props.customField.dropdownValues.split("|"));
    }
    setFormState(newState);
    setInitialFormState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name } = e.target;
    const value =
      name === "isRequired" || name == "isDeleted" || name == "isEmissionsField"
        ? e.target.checked
        : e.target.value;

    if (name === "parentLookupId") {
      if (_.isEmpty(value)) {
        setCatSubCategories([]);
        setTypeSubTypes([]);
        newState.childLookupId = "";
      } else {
        setCatSubCategories(
          eventSubCategories.filter((x) => x.parentLookupId == value)
        );
        setTypeSubTypes(
          incidentSubTypes.filter((x) => x.parentLookupId == value)
        );
        newState.childLookupId = "";
      }
    }
    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleFormSave = () => {
    if (!validateDataForSave()) {
      return;
    }

    let formToSave = validationService.unsetErrors(
      formState,
      "fieldLabelError",
      "fieldTypeError"
    );
    formToSave = validationService.unsetFields(formState, "dropdownValue");
    if (
      (formState.fieldType == "Dropdown" ||
        formState.fieldType == "MultiSelect") &&
      !_.isEmpty(dropdownValues)
    ) {
      formState.dropdownValues = dropdownValues.join("|");
    }

    if (formState.fieldType == "Checkbox") {
      formState.isRequired = false;
    }
    formState.entityType = props.entityType;
    formState.incidentStage = props.incidentStage;
    if (_.isEmpty(props.customField)) {
      createCustomField(formToSave)
        .then((res) => {
          showAlert("success", "Field created.");
          props.handleModalClose(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    } else {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      patchCustomField(props.customField.id, diff)
        .then((res) => {
          showAlert("success", "Additional field saved.");
          props.handleModalClose(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "fieldLabel",
      "fieldLabelError",
      "Field label"
    );
    validationService.validateRequiredField(
      newState,
      "fieldType",
      "fieldTypeError",
      "Field type"
    );
    let isDropdown = false;
    if (
      formState.fieldType == "Dropdown" ||
      formState.fieldType == "MultiSelect"
    ) {
      if (_.isEmpty(dropdownValues)) {
        isFormValid = false;
        newState.dropdownValuesError =
          "One of more dropdown value is required.";
      } else {
        newState.dropdownValuesError = "";
      }

      isDropdown = true;
    }
    var errorState = ["fieldLabelError", "fieldTypeError"];
    if (isDropdown) {
      errorState = [...errorState, "dropdownValuesError"];
    }
    isFormValid = !validationService.hasError(newState, ...errorState);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleDropdownValueAdd = () => {
    if (formState.dropdownValue == "" || !formState.dropdownValue) return;
    let newValues = [...dropdownValues];
    newValues.push(formState.dropdownValue);
    setDropdownValues(newValues);
    let newState = { ...formState };
    newState.dropdownValue = "";
    setFormState(newState);
  };

  const handleRowOrderChange = (e) => {
    let newValues = [...dropdownValues];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setDropdownValues(newValues);
  };

  const handleDeleteCustomField = () => {
    deleteCustomField(props.customField.id).then((res) => {
      showAlert("success", "Field deleted.");
      setShowDelete(false);
      props.handleModalClose();
    });
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          {props.customField.id > 0 ? "Edit" : "Add"}{" "}
          {props.entityType == EntityTypes.Incident
            ? "Incident Investigation"
            : props.entityType == EntityTypes.MonitoringEvent
            ? "Monitoring Event"
            : props.entityType == EntityTypes.EnergyLog
            ? "Energy Tracking Form"
            : "Event"}{" "}
          Field
          <Icon
            name="x"
            onClick={props.handleModalClose}
            className="float-right pointer"
          ></Icon>
        </Card.Title>

        <Grid.Row>
          <Grid.Col lg={6} width={12}>
            <Form.Group label="Field label" isRequired>
              <Form.Input
                type="text"
                name="fieldLabel"
                onChange={handleInputChange}
                value={formState.fieldLabel}
                error={formState.fieldLabelError}
              ></Form.Input>
            </Form.Group>
          </Grid.Col>
          <Grid.Col lg={6} width={12}>
            <Form.Group label="Field type" isRequired>
              <Form.Select
                name="fieldType"
                onChange={handleInputChange}
                value={formState.fieldType}
                error={formState.fieldTypeError}
                disabled={props.customField.id > 0}
              >
                <option value={""}></option>
                <option value={"Text"}>Text</option>
                <option value={"TextArea"}>Text Area</option>
                <option value={"Number"}>Number</option>
                <option value={"Date"}>Date</option>
                <option value={"DateTime"}>Date and Time</option>
                <option value={"Dropdown"}>Single Select (Dropdown)</option>
                <option value={"MultiSelect"}>
                  Multiple Select (Dropdown)
                </option>
                <option value={"Checkbox"}>Checkbox</option>
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          {(formState.fieldType == "Dropdown" ||
            formState.fieldType == "MultiSelect") && (
            <>
              <Grid.Col lg={6} width={12}>
                <Form.Group label="Add dropdown value:" isRequired>
                  <Form.Input
                    type="text"
                    name="dropdownValue"
                    error={formState.dropdownValuesError}
                    onChange={handleInputChange}
                    value={formState.dropdownValue}
                  ></Form.Input>
                  <Button
                    variant="contained"
                    className="float-right mt-2"
                    onClick={handleDropdownValueAdd}
                  >
                    Add dropdown value
                  </Button>
                </Form.Group>
              </Grid.Col>
              <Grid.Col lg={6} width={12}>
                <Form.Group label="Dropdown values">
                  <div
                    style={{
                      height: "300px",
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      headerHeight={0}
                      columns={[
                        { field: "value", headerName: "Value", width: 200 },
                        {
                          field: "remove",
                          headerName: "Remove",
                          width: 45,
                          type: "actions",
                          sortable: false,
                          renderCell: (params) => (
                            <Tooltip title="Remove">
                              <IconButton
                                onClick={() => {
                                  let newValues = [...dropdownValues];
                                  newValues.splice(params.id, 1);
                                  setDropdownValues(newValues);
                                  let newState = { ...formState };
                                  newState.dropdownValues = newValues.join("|");
                                  setFormState(newState);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          ),
                        },
                      ]}
                      rows={[
                        ...dropdownValues.map((value, index) => ({
                          id: index,
                          value: value,
                        })),
                      ]}
                      rowReordering
                      hideFooter={true}
                      apiRef={apiRef}
                      onRowOrderChange={handleRowOrderChange}
                    />
                  </div>
                </Form.Group>
              </Grid.Col>
            </>
          )}
          {formState.fieldType != "Checkbox" && (
            <Grid.Col md={6} width={12}>
              <Form.Checkbox
                label="Required field?"
                name="isRequired"
                onChange={handleInputChange}
                checked={formState.isRequired}
              />
            </Grid.Col>
          )}
          <Grid.Col md={6} width={12}>
            <Form.Checkbox
              label="Disabled?"
              name="isDeleted"
              onChange={handleInputChange}
              checked={formState.isDeleted}
            />
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Description">
              <LynxTextArea
                name="fieldDescription"
                onChange={handleInputChange}
                value={formState.fieldDescription}
              ></LynxTextArea>
            </Form.Group>
          </Grid.Col>{" "}
          {formState.fieldType == "Number" &&
            props.entityType !== EntityTypes.EnergyLog &&
            props.entityType !== EntityTypes.Permit && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Checkbox
                    label="Emissions field?"
                    name="isEmissionsField"
                    onChange={handleInputChange}
                    checked={formState.isEmissionsField}
                  />
                </Grid.Col>
                {formState.isEmissionsField == true && (
                  <>
                    <Grid.Col lg={6} width={12}>
                      <Form.Group label="CO2 factor">
                        <Form.Input
                          type="number"
                          name={"co2Factor"}
                          onChange={handleInputChange}
                          value={formState.co2Factor}
                        ></Form.Input>
                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col lg={6} width={12}>
                      <Form.Group label="Emissions scope">
                        <Form.Select
                          name="emissionsScope"
                          onChange={handleInputChange}
                          value={formState.emissionsScope}
                        >
                          <option value={""}></option>
                          <option value={"Scope 1"}>Scope 1</option>
                          <option value={"Scope 2"}>Scope 2</option>
                          <option value={"Scope 3"}>Scope 3</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                  </>
                )}
              </>
            )}
          {props.entityType == EntityTypes.Event && (
            <>
              <Grid.Col md={12} width={12}>
                <p>
                  You can choose a specific event category, or event category
                  and sub category to associate the field with. If no category
                  is selected, the field will appear on all event forms.
                </p>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with category:">
                  <Form.Select
                    name="parentLookupId"
                    value={formState.parentLookupId}
                    onChange={handleInputChange}
                    error={formState.parentLookupIdError}
                  >
                    <option value={""}></option>
                    {eventCategories.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with subcategory">
                  <Form.Select
                    name="childLookupId"
                    onChange={handleInputChange}
                    value={formState.childLookupId}
                    error={formState.childLookupIdError}
                  >
                    <option value={""}></option>
                    {catSubCategories.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </>
          )}
          {props.entityType == EntityTypes.Incident && (
            <>
              <Grid.Col md={12} width={12}>
                <p>
                  You can choose a specific incident type, or incident type and
                  subtype to associate the field with. If no type is selected,
                  the field will appear on all inspection forms.
                </p>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with type:">
                  <Form.Select
                    name="parentLookupId"
                    value={formState.parentLookupId}
                    onChange={handleInputChange}
                    error={formState.parentLookupIdError}
                  >
                    <option value={""}></option>
                    {incidentTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with subtype">
                  <Form.Select
                    name="childLookupId"
                    onChange={handleInputChange}
                    value={formState.childLookupId}
                    error={formState.childLookupIdError}
                  >
                    <option value={""}></option>
                    {typeSubTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </>
          )}
          {props.entityType == EntityTypes.MonitoringEvent && (
            <>
              <Grid.Col md={12} width={12}>
                <p>
                  You can choose a specific monitoring event type to associate
                  the field with. If no type is selected, the field will appear
                  on all monitoring event forms.
                </p>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with monitoring event type:">
                  <Form.Select
                    name="parentLookupId"
                    value={formState.parentLookupId}
                    onChange={handleInputChange}
                    error={formState.parentLookupIdError}
                  >
                    <option value={""}></option>
                    {monitoringEventTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </>
          )}
          {props.entityType == EntityTypes.EnergyLog && (
            <>
              <Grid.Col md={12} width={12}>
                <p>
                  You can choose a specific energy log type to associate the
                  field with. If no type is selected, the field will appear on
                  all energy log forms.
                </p>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Associate with energy log type:">
                  <Form.Select
                    name="parentLookupId"
                    value={formState.parentLookupId}
                    onChange={handleInputChange}
                    error={formState.parentLookupIdError}
                  >
                    <option value={""}></option>
                    {energyLogTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </>
          )}
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        {props.customField.id > 0 && roleMatch([UserRoles.InternalUser]) && (
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowDelete(true)}
          >
            Delete
          </Button>
        )}

        <Button
          variant="contained"
          className="float-right"
          onClick={handleFormSave}
        >
          {props.customField.id > 0 ? "Save" : "Add"}
        </Button>
      </Card.Footer>
      {showDelete && (
        <LynxDialog
          open={showDelete}
          handleConfirm={handleDeleteCustomField}
          handleCancel={() => setShowDelete(false)}
          title={`Are you sure?!`}
          description={`Deleting the field will also delete all associated values and cannot be undone.`}
        />
      )}
    </Form>
  );
}
