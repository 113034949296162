import React, { FC } from "react";
import FactoryIcon from "@mui/icons-material/Factory";
import { Form, Grid } from "components/lynx-components";
import { MultiSelect } from "../../form-controls/multi-select";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { CustomFieldState, EventCustomFieldDto } from "types";

export const customFieldPrefix = "customFieldData.";

interface CustomFieldProps {
  customField: EventCustomFieldDto;
  formState: { [key: string]: CustomFieldState };
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  isRequired?: boolean;
  disabled?: boolean;
}

const CustomField: FC<CustomFieldProps> = ({
  customField,
  formState,
  handleInputChange,
  isRequired = false,
  disabled = false,
}) => {
  const getFieldLabel = () => {
    if (customField.fieldType === "Number" && customField.isEmissionsField) {
      return (
        <Form.Label>
          {customField.fieldLabel}
          <FactoryIcon color="action" sx={{ fontSize: 15, marginLeft: "2" }} />
        </Form.Label>
      );
    } else return customField.fieldLabel;
  };

  const fieldName = customField.fieldName as string;
  const field = formState[fieldName];
  const fieldError: any = formState[`${fieldName}Error`];

  return (
    <Grid.Col md={6} width={12}>
      <Form.Group isRequired={isRequired} label={getFieldLabel()}>
        {customField.fieldType === "Dropdown" && (
          <Form.Select
            name={`${fieldName}.textValue`}
            onChange={handleInputChange}
            value={field?.textValue || ""}
            error={fieldError}
            disabled={disabled}
          >
            <option value={""}></option>
            {customField.dropdownValues?.split("|").map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        )}
        {customField.fieldType === "MultiSelect" && (
          <MultiSelect
            disabled={disabled}
            error={fieldError}
            onChange={handleInputChange}
            name={`${fieldName}.textValue`}
            dropdownValues={customField.dropdownValues?.split("|") || []}
            value={field?.textValue ? field.textValue.split("|") : []}
            isString
          />
        )}
        {customField.fieldType === "Text" && (
          <Form.Input
            type="text"
            name={`${fieldName}.textValue`}
            onChange={handleInputChange}
            value={field?.textValue || ""}
            error={fieldError}
            disabled={disabled}
          />
        )}
        {customField.fieldType === "TextArea" && (
          <LynxTextArea
            name={`${fieldName}.textValue`}
            onChange={handleInputChange}
            value={field?.textValue || ""}
            error={fieldError}
            disabled={disabled}
          />
        )}
        {customField.fieldType === "Number" && (
          <Form.Input
            type="number"
            name={`${fieldName}.numericValue`}
            onChange={handleInputChange}
            value={field?.numericValue || ""}
            error={fieldError}
            disabled={disabled}
          />
        )}
        {customField.fieldType === "DateTime" && (
          <Form.Input
            type="datetime-local"
            name={`${fieldName}.dateTimeValue`}
            onChange={handleInputChange}
            value={field?.dateTimeValue || ""}
            error={fieldError}
            disabled={disabled}
          />
        )}
        {customField.fieldType === "Date" && (
          <Form.Input
            type="date"
            name={`${fieldName}.dateValue`}
            onChange={handleInputChange}
            value={field?.dateValue || ""}
            error={fieldError}
            disabled={disabled}
          />
        )}
      </Form.Group>
      {customField.fieldType === "Checkbox" && (
        <Form.Checkbox
          label={" "}
          name={`${fieldName}.booleanValue`}
          onChange={handleInputChange}
          checked={!!field?.booleanValue}
          disabled={disabled}
        />
      )}
    </Grid.Col>
  );
};

export default CustomField;
