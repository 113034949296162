import * as React from "react";
import cn from "classnames";
import FormInput from "./form-input";
import FormLabel from "./form-label";

interface InputProps {
  // Define the props expected by FormInput component
}

interface Props {
  children?: React.ReactNode;
  className?: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  inputProps?: InputProps;
  id?: string;
}

const FormGroup: React.FC<Props> = ({
  className,
  children,
  label,
  isRequired,
  inputProps,
  id
}) => {
  const classes = cn("form-group", className);
  const inputComponent = inputProps
    ? React.createElement(FormInput, inputProps)
    : null;
  return (
    <div className={classes} id={id}>
      {label &&
        (typeof label === "string" ? (
          <FormLabel>
            {label}
            {isRequired && <span className="form-required">*</span>}
          </FormLabel>
        ) : (
          label
        ))}
      {inputComponent || children}
    </div>
  );
};

FormGroup.displayName = "Form.Group";

export default FormGroup;
