import React from "react";
import { Icon, Card, Table } from "components/lynx-components";
import { useHistory } from "react-router-dom";

export const AdminSidebar = (props) => {
  const history = useHistory();
  return (
    <aside className="sidebar" id="mainSidebar">
      <Card className="h-100">
        <Table
          responsive
          highlightRowOnHover
          hasOutline
          verticalAlign="center"
          cards
          className="text-nowrap"
        >
          <Table.Body>
            <Table.Row
              className="pointer"
              onClick={() => history.push("/admin/customers")}
              style={{ borderBottom: "1px solid #ced4da" }}
            >
              <Table.Col>
                <Icon name="users" />
              </Table.Col>
              <Table.Col>Customers</Table.Col>
            </Table.Row>
            <Table.Row
              className="pointer"
              onClick={() => history.push("/admin/organizations")}
              style={{ borderBottom: "1px solid #ced4da" }}
            >
              <Table.Col>
                <Icon name="users" />
              </Table.Col>
              <Table.Col>Organizations</Table.Col>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card>
    </aside>
  );
};
