import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DataGridPro } from "@mui/x-data-grid-pro";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Grid } from "components/lynx-components";
import { dateUtil } from "../../../services/date-util";
import { localStorageService } from "../../../services/local-storage-service";
import { getMonitoringResultsWide } from "../../../services/monitoring-result-service";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
import { getParameters } from "../../../services/parameters";
import { MultiSelect } from "../../form-controls/multi-select";
import useDebounce from "hooks/useDebounce";

export function MonitoringResultListWide(props) {
  const now = new Date();
  const defaultDateFilter = {
    timeStart: dateUtil.convertDateTimeToLocal(
      new Date().setDate(now.getDate() - 14)
    ),
    timeEnd: dateUtil.convertDateTimeToLocal(now),
  };
  const resultPrefix = "p:";
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [selectedMonitoringLocations, setSelectedMonitoringLocations] =
    useState([]);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [viewBy, setViewBy] = useState("parameter");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortParams, setSortParams] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [parametersLoading, setParametersLoading] = useState(true);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [size, setSize] = useState([0, 0]);
  const [monLocOpen, setMonLocOpen] = useState(false);
  const [paramOpen, setParamOpen] = useState(false);
  const resultsWideSelectedMonitoringLocations =
    "resultsWideSelectedMonitoringLocations";
  const resultsWideDateFilter = "resultsWideDateFilter";
  const resultsWideSelectParameters = "resultsWideSelectParameters";
  const targetRef = useRef();
  const debouncedDateFilter = useDebounce(dateFilter, 600);

  const nonValueColumns = [
    "monitoringEventEndDateTimeUtc",
    "monitoringEventStartDateTimeUtc",
    "monitoringEventName",
    "monitoringLocationName",
    "id",
  ];

  React.useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;
      setSize([width, targetRef.current.offsetHeight - 292]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (
      dateFilter.timeStart !== "" &&
      dateFilter.timeEnd !== "" &&
      !_.isEmpty(selectedMonitoringLocations) &&
      !_.isEmpty(selectedParameters) &&
      !monLocOpen &&
      !paramOpen
    ) {
      loadData();
    }
  }, [
    selectedMonitoringLocations,
    debouncedDateFilter,
    pageSize,
    pageNumber,
    sortParams,
    selectedParameters,
    monLocOpen,
    paramOpen,
  ]);

  useEffect(() => {
    getMonitoringLocations({ isResultsWide: true }).then((res) => {
      setMonitoringLocations(res.data);
      setLocationsLoading(false);
    });
    getParameters().then((res) => {
      setParameters(res.data);
      setParametersLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!parametersLoading && !locationsLoading) {
      setValuesFromLocalStorage();
    }
  }, [parametersLoading, locationsLoading]);

  const setValuesFromLocalStorage = () => {
    var lsSelectedMonitoringLocation = localStorageService.getLocalStorage(
      resultsWideSelectedMonitoringLocations
    );
    if (lsSelectedMonitoringLocation) {
      var selectedLocations = monitoringLocations.filter((x) =>
        lsSelectedMonitoringLocation.includes(x.id)
      );
      setSelectedMonitoringLocations(selectedLocations);
    }
    var lsTimeSeriesStartDate = localStorageService.getLocalStorage(
      resultsWideDateFilter
    );
    if (lsTimeSeriesStartDate) {
      setDateFilter(lsTimeSeriesStartDate);
    }
    var lsSelectedParameters = localStorageService.getLocalStorage(
      resultsWideSelectParameters
    );
    if (lsSelectedParameters) {
      var selectedParameters = parameters.filter((x) =>
        lsSelectedParameters.includes(x.id)
      );

      setSelectedParameters(selectedParameters);
    }
  };

  const loadData = () => {
    setIsLoading(true);
    var params = {
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      timeStart: dateFilter.timeStart,
      timeEnd: dateFilter.timeEnd,
      monitoringLocationIds: selectedMonitoringLocations
        .map((x) => x.id)
        .join(","),
      parameterIds: selectedParameters.map((x) => x.id).join(","),
    };

    if (!_.isEmpty(sortParams)) {
      params = {
        sort: sortParams[0].field + " " + sortParams[0].sort,
        ...params,
      };
    }

    getMonitoringResultsWide(params).then((res) => {
      if (res.data.length > 0) {
        const paging = JSON.parse(res.headers.pagination);
        setTotalItems(paging.totalItems);
        convertDataByParameter(res.data);
        convertDataByDate(res.data);
        setIsLoading(false);
      } else {
        setTotalItems(0);
        setDateColumns([]);
        setDateData([]);
        setColumns([]);
        setData([]);
        setIsLoading(false);
      }
    });
  };

  const convertDataByParameter = (data) => {
    let columnsToSet = [
      {
        field: "monitoringEventEndDateTimeUtc",
        headerName: "Result End Date Time",
        width: 175,
        type: "dateTime",
        filterable: false,
        valueGetter: (params) => {
          return dateUtil.convertDateTimeToLocal(params.value);
        },
      },
      {
        field: "monitoringEventName",
        headerName: "Sample ID",
        width: 175,
        type: "string",
        filterable: false,
        sortable: false,
      },
      {
        field: "monitoringLocationName",
        headerName: "Location",
        width: 175,
        type: "string",
        filterable: false,
        sortable: false,
      },
    ];

    var firstRecord = data[0];
    for (const [key, value] of Object.entries(firstRecord)) {
      var columnName = key.replace(resultPrefix, "");
      if (!nonValueColumns.includes(columnName)) {
        columnsToSet.push({
          field: key,
          headerName: columnName,
          width: 175,
          type: "string",
          filterable: false,
          sortable: false,
        });
      }
    }
    setColumns(columnsToSet);
    setData(data);
  };

  const convertDataByDate = (data) => {
    let newColumns = [];
    newColumns.push({
      field: "parameterName",
      headerName: "Parameter Name",
      width: 175,
      type: "string",
      filterable: false,
      sortable: false,
    });
    var columnNames = [];
    for (const [key, value] of Object.entries(data[0])) {
      if (key.includes(resultPrefix)) {
        columnNames.push(key);
      }
    }
    var convertedData = columnNames.map((columnName, index) => {
      var obj = {
        parameterName: columnName.replace(resultPrefix, ""),
        id: index,
      };
      data.forEach((row, i) => {
        obj["time" + i] = row[columnName];
      });
      return obj;
    });
    data.forEach((row, i) => {
      newColumns.push({
        field: "time" + i,
        headerName: dateUtil.convertDateTimeToLocal(
          row.monitoringEventEndDateTimeUtc
        ),
        width: 175,
        type: "string",
        filterable: false,
        sortable: false,
      });
    });
    setDateColumns(newColumns);
    setDateData(convertedData);
  };

  // {
  //   parameterName: "Avbs",
  //   time1 : 12,
  //   time2: 13
  // }

  const handleRdoViewByChange = (value) => {
    setViewBy(value);
    // if (value === "parameter") {
    //   setColumnsAndData(columnsByParameter, dataByParameter);
    // } else {
    //   setColumnsAndData(columnsByDate, dataByDate);
    // }
  };

  const handleMonitoringLocationsChange = (e) => {
    var selectedLocations = e;
    setSelectedMonitoringLocations(selectedLocations);
    localStorageService.setLocalStorage(
      resultsWideSelectedMonitoringLocations,
      selectedLocations.map((a) => a.id)
    );
  };

  const handleParametersChange = (e) => {
    var selectedParameters = e;
    setSelectedParameters(selectedParameters);
    localStorageService.setLocalStorage(
      resultsWideSelectParameters,
      selectedParameters.map((a) => a.id)
    );
  };
  const handleDateFilterChange = (e) => {
    let newDateFilter = { ...dateFilter };
    const name = e.target.name;
    const value = e.target.value;
    _.set(newDateFilter, name, value);

    localStorageService.setLocalStorage(resultsWideDateFilter, newDateFilter);
    setDateFilter(newDateFilter);
  };

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleSortModelChange = (sort, d) => {
    setSortParams(sort);
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      ref={targetRef}
    >
      <Grid>
        <Paper elevation={props.noElevation ? 0 : 1}>
          <Grid.Col width={12}>
            <div className="d-flex h-100 ">
              <props.header />
            </div>
          </Grid.Col>
        </Paper>

        <Grid.Row>
          <Grid.Col md={6} width={12} className="pt-3">
            <Form.Group label="Monitoring Location(s)">
              <MultiSelect
                onOpen={() => setMonLocOpen(true)}
                onClose={() => setMonLocOpen(false)}
                name="selectedMonitoringLocations"
                onChange={(e) => {
                  handleMonitoringLocationsChange(e.target.value);
                }}
                value={selectedMonitoringLocations}
                dropdownValues={monitoringLocations}
                key="id"
                label="name"
                id="selectingMonitoringLocations"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12} className="pt-3">
            <Form.Group label="Parameters">
              <MultiSelect
                onOpen={() => setParamOpen(true)}
                onClose={() => setParamOpen(false)}
                name="selectedParameters"
                onChange={(e) => {
                  handleParametersChange(e.target.value);
                }}
                value={selectedParameters}
                dropdownValues={parameters}
                key="id"
                label="name"
                id="id"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={3} width={12}>
            <Form.Group label="Start Date">
              <Form.Input
                type="datetime-local"
                value={dateFilter.timeStart}
                name="timeStart"
                onChange={handleDateFilterChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={3} width={12}>
            <Form.Group label="End Date">
              <Form.Input
                type="datetime-local"
                value={dateFilter.timeEnd}
                name="timeEnd"
                onChange={handleDateFilterChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        {!_.isEmpty(selectedMonitoringLocations) &&
          !_.isEmpty(selectedParameters) &&
          dateFilter.timeStart !== "" &&
          dateFilter.timeEnd !== "" && (
            <>
              <Grid.Col md={12} width={12}>
                <FormControl className="pl-3">
                  <RadioGroup
                    row
                    name="rdoViewBy"
                    value={viewBy}
                    onChange={(e) => handleRdoViewByChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="parameter"
                      control={<Radio />}
                      label="View By Parameter"
                    />
                    <FormControlLabel
                      value="date"
                      control={<Radio />}
                      label="View By Date"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid.Col>
              <Grid.Row className="ml-0 mr-0">
                <Card className="mb-0">
                  <div
                    style={{
                      height: size[1],
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      rows={viewBy == "parameter" ? data : dateData}
                      columns={viewBy == "parameter" ? columns : dateColumns}
                      disableMultipleSelection={true}
                      loading={isLoading}
                      pagination={true}
                      paginationMode="server"
                      page={pageNumber}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      rowCount={totalItems}
                      sortingMode="server"
                      onSortModelChange={handleSortModelChange}
                      sortModel={sortParams ?? []}
                      disableColumnReorder={viewBy === "date"}
                    />
                  </div>
                </Card>
              </Grid.Row>
            </>
          )}
      </Grid>
    </div>
  );
}
