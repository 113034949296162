import { Info } from "@mui/icons-material";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useState } from "react";
import { Form } from "components/lynx-components";
import { EntityTypes } from "../../../types/enums";
import { LynxDialog } from "./../../lynx-dialog";
export function Step2(props) {
  const [showInfo, setShowInfo] = useState(false);
  const isContinueDisabled = () => {
    if (!props.isCreateNew && !props.selectedSpecId) {
      return true;
    }
    if (
      props.entityType == EntityTypes.Event &&
      (props.formState.assetId == "" || props.formState.parentLookupId == "")
    ) {
      return true;
    }

    if (
      props.entityType == EntityTypes.Event &&
      props.formState.parentLookupId != ""
    ) {
      let eventCat = props.eventCategories.find(
        (x) => x.id == props.formState.parentLookupId
      );
      if (eventCat) {
        if (
          eventCat.requireChildSelection &&
          props.formState.childLookupId == ""
        ) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <>
      <Typography>
        Select an import configuration for the import. A .xls template can be
        downloaded after selecting the configuration. Contact Lynx Support to
        add additonal configurations.
      </Typography>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.isCreateNew}
              onChange={(e) => {
                props.setIsCreateNew(e.target.checked);
              }}
            />
          }
          label="Create new import configuration"
        />
      </FormControl>
      {props.isCreateNew &&
        _.toLower(props.entityType) ==
          _.toLower(EntityTypes.MonitoringResult) && (
          <>
            <Typography>
              Would you like to default the monitoring location or event type
              for this import configuration?
            </Typography>
            <FormControl
              sx={{
                marginBottom: 2,
                marginTop: 1,
                minWidth: 250,
              }}
            >
              <Typography variant="subtitle2">
                Default Monitoring Location
              </Typography>
              <Select
                name="monitoringLocationId"
                value={props.formState.monitoringLocationId}
                onChange={props.handleInputChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {props.monitoringLocations.map((monLoc) => (
                  <MenuItem key={monLoc.id} value={monLoc.id}>
                    {monLoc.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                marginBottom: 2,
                marginTop: 1,
                minWidth: 250,
                marginLeft: 1,
              }}
            >
              <Typography variant="subtitle2">Default Event Type</Typography>
              <Select
                name="parentLookupId"
                value={props.formState.parentLookupId}
                onChange={props.handleInputChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {props.monitoringEventTypes.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      {props.isCreateNew &&
        _.toLower(props.entityType) == _.toLower(EntityTypes.Event) && (
          <>
            <Typography>
              Would you like to default the asset or event category /
              subcategory for this configuration?
            </Typography>

            <FormControl
              sx={{
                marginBottom: 2,
                marginTop: 1,
                minWidth: 250,
              }}
            >
              <Typography variant="subtitle2">
                Default Asset <span className="form-required">*</span>
              </Typography>

              <Select
                name="assetId"
                value={props.formState.assetId}
                onChange={props.handleInputChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {props.assets.map((asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                marginBottom: 2,
                marginTop: 1,
                minWidth: 250,
                marginLeft: 1,
              }}
            >
              <Typography variant="subtitle2">
                Default Event Category <span className="form-required">*</span>
              </Typography>
              <Select
                name="parentLookupId"
                value={props.formState.parentLookupId}
                onChange={props.handleInputChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {props.eventCategories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.formState.parentLookupId &&
              !_.isEmpty(props.availableSubCategories) && (
                <FormControl
                  sx={{
                    marginBottom: 2,
                    marginTop: 1,
                    minWidth: 250,
                    marginLeft: 1,
                  }}
                >
                  <Typography variant="subtitle2">
                    Default Event Subcategory
                  </Typography>
                  <Select
                    name="childLookupId"
                    value={props.formState.childLookupId}
                    onChange={props.handleInputChange}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    <MenuItem value={""}>&nbsp;</MenuItem>
                    {props.availableSubCategories.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </>
        )}

      {props.isCreateNew && (
        <>
          <Divider />
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.isCustomFormat}
                  onChange={(e) => {
                    props.setIsCustomFormat(e.target.checked);
                  }}
                />
              }
              label={
                <span>
                  <IconButton
                    className="mr-2"
                    aria-label="info"
                    onClick={() => {
                      setShowInfo(true);
                    }}
                  >
                    <Info />
                  </IconButton>{" "}
                  Specify custom date time format
                </span>
              }
            />
          </FormControl>
          {props.isCustomFormat && (
            <>
              <Form.Group className="w-50" label="DateTime Format">
                <Form.Input
                  name="dateTimeFormat"
                  onChange={props.handleInputChange}
                  value={props.formState.dateTimeFormat}
                  placeholder="dd/MM/yy HH:mm"
                ></Form.Input>
              </Form.Group>
              {props.entityType != EntityTypes.TimeSeries && (
                <Form.Group className="w-50" label="Date Format">
                  <Form.Input
                    name="dateFormat"
                    onChange={props.handleInputChange}
                    value={props.formState.dateFormat}
                    placeholder="dd-MM-yyyy"
                  ></Form.Input>
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
      {!props.isCreateNew && (
        <>
          <br />
          <FormControl
            sx={{
              marginBottom: 2,
              marginTop: 1,
              minWidth: 250,
            }}
          >
            <Select
              value={props.selectedSpecId}
              onChange={(event) => props.setSelectedSpecId(event.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {props.specs.map((spec) => (
                <MenuItem key={spec.id} value={spec.id}>
                  {spec.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {/* {props.selectedSpecId != "" && !props.isCreateNew && (
        <div>
          <Button
            variant="outlined"
            sx={{ marginBottom: 2 }}
            onClick={() => {
              saveAs(fileToDownload, props.selectedSpecId);
            }}
          >
            Download import template
          </Button>

        </div>
      )} */}
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
            disabled={isContinueDisabled()}
          >
            Continue
          </Button>
          <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
      <LynxDialog
        open={showInfo}
        handleConfirm={() => setShowInfo(false)}
        description={
          <>
            The import will attempt to parse the dates using American date
            formats. If the timezone is not specified, the timezone will default
            to the timezone that is set in the users profile. The following are
            examples of supported date formats:
            <br />
            02/21/2023
            <br />
            05/23/2009 14:57:32:8
            <br />
            2021-02-23T14:57:32:8375298-04:00
            <br />1 May 2013 2:75 PM
            <br />
            Fri, 15 May 2009 20:10:57 GMT
            <br />
            11/28/2023 14:57:32:80 -07:00
            <br />
            <br />
            If you would like the use a different date format, check this box
            and supply the format. The format must confirm to{" "}
            <a href="https://learn.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings">
              .NET date time format strings
            </a>
          </>
        }
      />
    </>
  );
}
