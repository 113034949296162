import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card, Dimmer } from "components/lynx-components";
import {
  ComplianceCategoryButton,
  ComplianceTypeButton,
  EmissionsScopeBoutton,
  TimeFilterButton,
} from "../buttons";
import { chartService } from "./../../../../services/chart-service";

import { saveAs } from "file-saver";
import { useCurrentPng } from "recharts-to-png";
import { getKpiDashboardDataById } from "../../../../services/kpi-service";
import {
  getSystemDashboardWidgetData,
  getWidgetFilterLocalStorage,
  handleWidgetFilterLocalStorageChange,
} from "./../../../../services/dashboard-widget-service";
import { DashboardWidgetHeader } from "./dashboard-widget-header";
import "./donut-chart-widget.css";
export function LineChartWidget(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [widgetProperties, setWidgetProperties] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [lastCalculatedDate, setLastCalculatedDate] = useState("");
  const [opacity, setOpacity] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    if (!_.isEmpty(chartData)) {
      let opacityToSet = {};
      Object.entries(chartData[0])
        .filter((x) => x[0] !== "name")
        .forEach((object) => {
          opacityToSet = { ...opacityToSet, [object[0]]: 1 };
        });
      setOpacity(opacityToSet);
    }
  }, [chartData]);
  const [getPng, { ref }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getPng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      saveAs(png, `${getTitle}.png`);
    }
  }, [getPng]);

  useEffect(() => {
    if (!_.isEmpty(props.widget)) {
      setIsLoading(true);
      var existingOption = getWidgetFilterLocalStorage(props.widget.id);
      setFilterOptions(
        existingOption ?? {
          eventStatusFilter: "All",
          dateFilter: "AllTime",
          emissionsScopeFilter: "All",
          complianceTypeFilter: "All",
          complianceCategoryFilter: "All",
        }
      );
    }
  }, [props.widget]);

  useEffect(() => {
    if (!_.isEmpty(props.systemWidget)) {
      setWidgetProperties(JSON.parse(props.systemWidget.propertiesJson));
      setFilterOptions({
        eventStatusFilter: "All",
        dateFilter: "AllTime",
        emissionsScopeFilter: "All",
        complianceTypeFilter: "All",
        complianceCategoryFilter: "All",
      });
    }
  }, [props.systemWidget]);

  useEffect(() => {
    if (
      !_.isEmpty(props.systemWidget) &&
      !_.isEmpty(props.parameters) &&
      !_.isEmpty(props.monitoringLocations) &&
      !_.isEmpty(props.resultValues)
    ) {
      setFilterOptions({
        ...filterOptions,
        parameters: props.parameters.map((x) => x.id).join("|"),
        monitoringLocations: props.monitoringLocations
          .map((x) => x.id)
          .join("|"),
        resultValues: props.resultValues.join("|"),
      });
    }
  }, [
    props.systemWidget,
    props.parameters,
    props.monitoringLocations,
    props.resultValues,
  ]);

  useEffect(() => {
    if (props.kpiId) {
      setFilterOptions({
        dateFilter: "AllTime",
      });
      loadKpiWidgetData("AllTime", false);
    }
  }, [props.kpiId]);

  useEffect(() => {
    if (
      !_.isEmpty(filterOptions) &&
      (!_.isEmpty(props.systemWidget) || !_.isEmpty(props.widget))
    ) {
      loadSystemWidgetData(true, props.systemWidget ? true : false);
    }
    if (!_.isEmpty(filterOptions) && props.kpiId) {
      loadKpiWidgetData(filterOptions.dateFilter);
    }
    if (!_.isEmpty(props.data)) {
      setChartData(props.data);
      setIsLoading(false);
    }
  }, [filterOptions, props.systemWidget, props.widget]);

  const loadKpiWidgetData = (dateFilter, refreshData = false) => {
    getKpiDashboardDataById(props.kpiId, dateFilter, refreshData).then(
      (res) => {
        setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
        let data = res.data.data;
        data.forEach(function (element, index) {
          for (const [key, value] of Object.entries(element)) {
            if (key !== "name") {
              element[key] = _.toNumber(value);
            }
          }
        });
        setChartData(data);

        setIsLoading(false);
      }
    );
  };

  const loadSystemWidgetData = (skipLoading, refreshData = false) => {
    if (!skipLoading) {
      setIsLoading(true);
    }
    let properties = {};
    if (props.widget) {
      properties = JSON.parse(props.widget.propertiesJson);
    }

    getSystemDashboardWidgetData(
      props.systemWidget
        ? props.systemWidget.id
        : props.widget.dashboardSystemWidgetId,
      filterOptions.eventStatusFilter,
      filterOptions.dateFilter,
      filterOptions.emissionsScopeFilter,
      filterOptions.complianceTypeFilter,
      filterOptions.complianceCategoryFilter,
      props.systemWidget
        ? filterOptions.parameters
        : properties && properties.parameters
        ? properties.parameters
        : null,
      props.systemWidget
        ? filterOptions.monitoringLocations
        : properties && properties.monitoringLocations
        ? properties.monitoringLocations
        : null,
      props.systemWidget
        ? filterOptions.resultValues
        : properties && properties.resultValues
        ? properties.resultValues
        : null,
      refreshData
    )
      .then((res) => {
        setWidgetProperties(JSON.parse(res.data.propertiesJson));
        setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
        let data = res.data.data ?? [];
        data.forEach(function (element, index) {
          for (const [key, value] of Object.entries(element)) {
            if (key !== "name") {
              element[key] = _.toNumber(value);
            }
          }
        });
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setChartData([]);
        setIsLoading(false);
      });
  };

  const handleRefresh = () => {
    if (props.kpiId) {
      loadKpiWidgetData(filterOptions.dateFilter, true);
    } else {
      loadSystemWidgetData(false, true);
    }
  };

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    let oldOpacity = { ...opacity };
    let newOpacity = {};

    Object.entries(oldOpacity).forEach((object) => {
      let key = object[0];
      if (object[0] == dataKey) {
        newOpacity = { ...newOpacity, [o.dataKey]: 1 };
      } else {
        newOpacity = { ...newOpacity, [key]: 0.2 };
      }
    });

    setOpacity(newOpacity);
  };

  const getTitle = () => {
    return props.systemWidgetTitle
      ? props.systemWidgetTitle
      : props.systemWidget
      ? props.systemWidget.name
      : props.title
      ? props.title
      : props.widget
      ? props.widget.title
      : "";
  };
  const handleMouseLeave = (o) => {
    let oldOpacity = { ...opacity };
    let newOpacity = {};

    Object.entries(oldOpacity).forEach((object) => {
      let key = object[0];

      newOpacity = { ...newOpacity, [key]: 1 };
    });
    setOpacity(newOpacity);
  };

  return (
    <Card className={props.className}>
      <DashboardWidgetHeader
        title={getTitle()}
        handleExportWidget={handleDownload}
        handleSortChange={props.handleSortChange}
        handleDelete={props.handleDelete}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        widget={props.widget}
        handleRefresh={handleRefresh}
        lastCalculatedDate={props.systemWidget ? null : lastCalculatedDate}
        kpiId={props.kpiId}
      />

      <Dimmer active={props.loading ?? isLoading} loader>
        <div style={{ width: "100%", height: 400 }}>
          {!isLoading && _.isEmpty(chartData) && (
            <h4 className="pt-4 pl-4">
              {errorMessage || "No data found for criteria"}
            </h4>
          )}
          {!_.isEmpty(chartData) && (
            <ResponsiveContainer>
              <LineChart
                ref={ref}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                height={400}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  }}
                />
                <Legend
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  wrapperStyle={{
                    padding: "0 2rem",
                    maxHeight: "30%",
                    overflow: "auto",
                  }}
                />
                {!_.isEmpty(chartData) &&
                  Object.entries(chartData[0])
                    .filter((x) => x[0] !== "name")
                    .map((object, index) => (
                      <Line
                        type="monotone"
                        key={index}
                        dataKey={object[0]}
                        stroke={chartService.getChartColorByIndex(index)}
                        strokeOpacity={opacity[object[0]]}
                      />
                    ))}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </Dimmer>
      {!props.data && (
        <Card.Footer>
          <div className="float-right">
            {/* {widgetProperties.hasEventStatusFilter && (
            <EventStatusButton
              filterValue={filterOptions.eventStatusFilter}
              handleValueSelected={(option) => {
                let newOptions = { ...filterOptions };
                newOptions.eventStatusFilter = option.value;
                setFilterOptions(newOptions);
              }}
            />
          )} */}

            {(widgetProperties.hasDateFilter ||
              (props.hasDateFilter && filterOptions.dateFilter)) && (
              <TimeFilterButton
                filterValue={filterOptions.dateFilter}
                handleTimeSelected={(option) => {
                  let newOptions = { ...filterOptions };
                  newOptions.dateFilter = option.value;
                  setFilterOptions(newOptions);
                  if (props.widget) {
                    handleWidgetFilterLocalStorageChange(
                      props.widget.id,
                      newOptions
                    );
                  }
                }}
              />
            )}
            {widgetProperties.hasEmissionsScopeFilter && (
              <EmissionsScopeBoutton
                filterValue={filterOptions.emissionsScopeFilter}
                handleEmissionsScopeSelected={(option) => {
                  let newOptions = { ...filterOptions };
                  newOptions.emissionsScopeFilter = option;
                  setFilterOptions(newOptions);
                  if (props.widget) {
                    handleWidgetFilterLocalStorageChange(
                      props.widget.id,
                      newOptions
                    );
                  }
                }}
              />
            )}
            {widgetProperties.hasComplianceFrameworkFilter && (
              <ComplianceTypeButton
                filterValue={filterOptions.complianceTypeFilter}
                handleComplianceTypeSelected={(option) => {
                  let newOptions = { ...filterOptions };
                  newOptions.complianceTypeFilter = option;
                  setFilterOptions(newOptions);
                  if (props.widget) {
                    handleWidgetFilterLocalStorageChange(
                      props.widget.id,
                      newOptions
                    );
                  }
                }}
              />
            )}
            {widgetProperties.hasComplianceCategoryFilter && (
              <ComplianceCategoryButton
                filterValue={filterOptions.complianceCategoryFilter}
                handleComplianceCategorySelected={(option) => {
                  let newOptions = { ...filterOptions };
                  newOptions.complianceCategoryFilter = option;
                  setFilterOptions(newOptions);
                  if (props.widget) {
                    handleWidgetFilterLocalStorageChange(
                      props.widget.id,
                      newOptions
                    );
                  }
                }}
              />
            )}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
}
