import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { EntityTypes } from "../../../types/enums";
import Comments from "./comments";
import { EventDto, UserDto } from "types";

interface EventDetailsCommentsProps {
  handleAddComment: (refresh: boolean) => void;
  users: UserDto[];
  event: EventDto;
}

const EventDetailsComments = (props: EventDetailsCommentsProps) => {
  return (
    <Card
      className="lynx-card"
      variant="outlined"
      sx={{ width: "100%", overflow: "visible" }}
    >
      <CardHeader className="lynx-card-header" title="Comments" />
      <CardContent className="p-2">
        <Comments
          handleAddComment={props.handleAddComment}
          users={props.users}
          entityType={EntityTypes.Event}
          entityId={props.event.id as number}
        />
      </CardContent>
    </Card>
  );
};

export default EventDetailsComments;
